import {
  Container,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";

import CancelIcon from "@mui/icons-material/Cancel";
import DesktopWindowsIcon from "@mui/icons-material/DesktopWindows";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";

import { Box } from "@mui/system";
import { useContext } from "react";
import globalStateContext from "../../globalstate/globalStateContext";
import useCallApi from "../../api/usecallapi";

export default function UserDialog(props) {
  const global = useContext(globalStateContext);
  const result = useCallApi("route", "value", "token");
  console.log(result);
  return (
    <Box>
      <DialogTitle color="primary">User</DialogTitle>

      <DialogContent>
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <DesktopWindowsIcon color="primary" />
            </Grid>
            <Grid item xs={6}>
              <Typography color="primary">
                {global.userInfo.account_name}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <VerifiedUserIcon color="primary" />
            </Grid>
            <Grid item xs={6}>
              <Typography color="primary">
                {global.userInfo.username}
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </DialogContent>
      <DialogActions>
        <IconButton aria-label="cancel" onClick={props.handleClose}>
          <CancelIcon />
        </IconButton>
        <IconButton aria-label="cancel" onClick={props.handleClose}>
          <CancelIcon />
        </IconButton>
      </DialogActions>
    </Box>
  );
}
