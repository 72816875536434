import { CircularProgress, Divider, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";

import TabPanels from "../../components/tabpanel";
import Campaigns from "./insights/campaigns";
import Clicks from "./insights/clicks";
import CustomerBehaviours from "./insights/customerBehaviours";
import Products from "./insights/products";

export default function Insights() {
  const [fetchedData, setFetchedData] = useState(true);
  console.log("insights");

  useEffect(() => {
    console.log("insights from within useEffect");
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        padding: "1rem",
        width: "90%",
      }}
    >
      <Paper sx={{ mb: "2rem", p: "1rem", minHeight: "200px" }}>
        <Typography color="primary" gutterBottom variant="h6">
          Insights
        </Typography>
        <Divider sx={{ marginBottom: "1rem" }} />{" "}
        {!fetchedData && <CircularProgress colour="primary" />}
        <TabPanels>
          <Box title="Campaigns">
            <Campaigns />
          </Box>
          <Box title="Clicks">
            <Clicks />
          </Box>

          <Box title="Products">
            <Products />
          </Box>
        </TabPanels>
      </Paper>
    </Box>
  );
}
/*
<Box title="Customer Behaviours">
            <CustomerBehaviours />
          </Box>
          */
