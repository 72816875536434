import { Button } from "@mui/material";
import { Box } from "@mui/system";
import { useContext, useEffect, useState } from "react";
import { HexColorPicker, HexColorInput } from "react-colorful";
import SendToApi from "../../api/sendtoapi";
import globalStateContext from "../../globalstate/globalStateContext";

import "./styles.css";

export default function ColourPicker(props) {
  const global = useContext(globalStateContext);

  useEffect(() => {
    if (global.userInfo[props.id]) {
      setColor(global.userInfo[props.id]);
    }
    return () => {};
  }, [global.userInfo, props.id]);

  function onSetColour() {
    //console.log(`setting colour ${color}`);
    global.setUserInfo((prevState) => ({
      ...prevState,
      [props.id]: color,
    }));

    const setInfo = async () => {
      var values = {};
      values["data"] = {};
      values["data"]["colour1"] = global.userInfo.colour1;
      values["data"]["colour2"] = global.userInfo.colour2;
      values[props.id] = color;
      const response = await SendToApi("accounts/setinfo", values);
      console.log(`ACTION : ${response}`);
      // TODO check something !
    };
    // check if colours are dirty
    setInfo();
  }

  const [color, setColor] = useState(
    global.userInfo[props.id] ? global.userInfo[props.id] : "#AABBCC"
  );

  return (
    <Box
      id="colour_picker"
      sx={{
        display: "flex",
        flexDirection: "row",
        m: "auto",
        width: "fit-content",
        gap: "1rem",
      }}
    >
      <HexColorPicker color={color} onChange={setColor} />
      <Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "fit-content",
            alignContent: "flex-start",
          }}
        >
          <div id="prefix">#</div>
          <HexColorInput color={color} onChange={setColor} />
        </Box>
        <Button
          sx={{ marginTop: "1rem" }}
          disableRipple
          variant="contained"
          component="label"
          color="primary"
          size="medium"
          onClick={onSetColour}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
}
