import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
  ListItemIcon,
  Divider,
} from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";
import CancelIcon from "@mui/icons-material/Cancel";
import MessageIcon from "@mui/icons-material/Message";
import MarkUnreadChatAltIcon from "@mui/icons-material/MarkUnreadChatAlt";
import { Box } from "@mui/system";
import { useEffect } from "react";

export default function AlertDialog(props) {
  const { notifications, updateNotifications } = props;

  useEffect(() => {
    console.log(notifications);
  }, [notifications]);

  function handleClicked(i) {
    console.log(i);
    if (notifications[i].details.status !== "read") {
      updateNotifications(i, "status", "read");
    } else {
      updateNotifications(i, "status", "unread");
    }
  }
  return (
    <Box sx={{ width: "100%", maxWidth: 700, bgcolor: "background.paper" }}>
      <DialogTitle id="responsive-dialog-title">{"Notifications"}</DialogTitle>
      <Divider sx={{ marginLeft: "1rem", marginRight: "1rem" }} />
      <DialogContent>
        <>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            {notifications.map((notification, i) => {
              return (
                <Box
                  key={i}
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    flexDirection: "row",
                  }}
                >
                  <IconButton onClick={() => handleClicked(i)}>
                    {notification.details.status === "unread" ? (
                      <MarkUnreadChatAltIcon />
                    ) : (
                      <MessageIcon color="success" />
                    )}
                  </IconButton>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography
                      sx={{ textTransform: "capitalize" }}
                      variant="h6"
                    >
                      {notification.details.title}
                    </Typography>
                    <Typography
                      sx={{
                        textTransform: "capitalize",
                        color:
                          notification.details.status !== "unread" &&
                          "lightgrey",
                      }}
                      variant="body1"
                    >
                      {notification.details.message}
                    </Typography>
                  </Box>
                </Box>
              );
            })}
          </Box>
        </>
      </DialogContent>
      <DialogActions>
        <IconButton aria-label="delete" onClick={props.handleClose}>
          <CancelIcon />
        </IconButton>
      </DialogActions>
    </Box>
  );
}
