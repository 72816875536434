//https://codesandbox.io/s/03zxq01okp?file=/index.js
import { Fragment, useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { Box, Paper, Typography } from "@mui/material";

import FormButton from "./types/button";
import FormCheckbox from "./types/checkbox";
import FormRadio from "./types/radio";
import FormDateTimePicker from "./types/datetimepicker";
import FormSelect from "./types/select";
import FormTextField from "./types/textfield";
import RecaptchaField from "./types/recapture";

import FormControl from "@mui/material/FormControl";

import * as Yup from "yup";
import YupPassword from "yup-password";
import FormSlider from "./types/slider";

YupPassword(Yup);



export default function CTForm(props) {
  //const [initialValues, setInitialValues] = useState({});
  const {
    handleSubmit,
    layout,
    submitText,
    initialValuesSupplied = false,
    disabled,
    handleFormValues = () => {},
  } = props;

  const [initialValues, setInitialValues] = useState(initialValuesSupplied);
  const [validationSchema, setValidationSchema] = useState(false);
  const [fields, setFields] = useState([]);
  const [fieldsSet, setFieldsSet] = useState(false);
  const [formValues, setFormValues] = useState({});

  /**
   * set the fields from the layout json
   */

  useEffect(() => {
    console.log(formValues);
    handleFormValues(formValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues]);

  useEffect(() => {
    if (fields.length !== 0) return;
    console.log(layout);

    var _fields = [];
    var _formValues = {};
    layout.forEach((section) => {
      console.log(section);
      section.fields.forEach((field) => {
        console.log(field);
        // check for Selects
        console.log(field);
        _fields.push(field);
        _formValues[field.id] =
          field.type === "Slider"
            ? parseInt(field.initialValue)
            : field.initialValue;
      });
    });
    setFields(_fields);
    console.log(_fields);
    console.log(_formValues);
    setFormValues(_formValues);
    setFieldsSet(true);
  }, [layout, fields.length]);

  function preHandleSubmit(values) {
    // pre run to set initial string

    // loop through childValues and add to values
    if (typeof props.childValues !== "undefined") {
      Object.keys(props.childValues).forEach(function (key) {
        console.log(key);
        console.log(props.childValues[key]);
        values[key] = props.childValues[key];
      });
    }
    var csv_fields = new Map();
    fields.forEach((field) => {
      if (field.group) {
        values[field.group] = "";
        csv_fields.set(field.group, field.group);
      }
    });
    // build the group CSV
    fields.forEach((field) => {
      if (field.group) {
        if (values[field.id]) {
          values[field.group] += `${field.id},`;
        }
      } else {
      }
    });
    // lose the trailing ,
    //console.log(csv_fields);
    csv_fields.forEach((csv_field) => {
      //console.log(csv_field);
      //console.log(values[csv_field]);
      values[csv_field] = values[csv_field].slice(0, -1);
    });
    // strip the radiogroup individual fields

    // strip the group individual fields
    Object.keys(values).forEach((value) => {
      const searchField = fields.find((field) => field.id === value);

      if (searchField) {
        if (searchField.hasOwnProperty("group")) {
          //console.log("remove this one");
          //console.log(value);
          delete values[value];
        }
      }
    });
    console.log(values);
    handleSubmit(values);
  }
  /**
   * when the fileds are set assign yup and initial values
   */
  function createYupSchema(schema, field) {
    console.log(schema);
    console.log(field);
    const { id, validationType, validations = [] } = field;
    if (id === "passwordConfirmation") {
      return schema;
    }
    if (!Yup[validationType]) {
      return schema;
    }
    let validator = Yup[validationType]();
    validations.forEach((validation) => {
      const { params, type } = validation;
      if (!validator[type]) {
        return;
      }
      validator = validator[type](...params);
    });
    schema[id] = validator;
    return schema;
  }

  useEffect(() => {
    if (!fieldsSet) {
      return;
    }
    console.log(fields);
    const yepSchema = fields.reduce(createYupSchema, {});
    console.log(yepSchema);
    fields.forEach((field) => {
      if (field.id === "passwordConfirmation") {
        yepSchema["passwordConfirmation"] = Yup.string()
          .required("password confirmation required")
          .oneOf([Yup.ref("password"), null], "Passwords must match");
      }
      if (field.id === "select") {
        yepSchema["select"] = Yup.string()
          .ensure()
          .required("value is required");
      }
    });
    console.log(yepSchema);
    setValidationSchema(Yup.object().shape(yepSchema));

    if (!initialValuesSupplied) {
      const _initialValues = {};
      fields.forEach((item) => {
        _initialValues[item.id] = item.value || "";
      });
      setInitialValues(_initialValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldsSet]);

  function SortTheFields(props) {
    const { field, index, id } = props;
    if (field.type === "recaptcha") {
      return (
        <RecaptchaField
          key={index}
          name={field.id}
          label={field.label}
          value={field.value}
        />
      );
    }
    if (field.type === "Text") {
      switch (field.id) {
        case "password":
        case "passwordConfirmation":
          return (
            <FormTextField
              key={index}
              name={field.id}
              label={field.label}
              sx={{ marginBottom: "1rem", width: field.width }}
              value={field.value}
              type="password"
            />
          );
        default:
          return (
            <FormTextField
              key={index}
              name={field.id}
              label={field.label}
              value={field.value}
              sx={{ marginBottom: "1rem", width: field.width }}
            />
          );
      }
    }
    if (field.type === "DateTime" || field.type === "Date") {
      return (
        <FormDateTimePicker
          name={field.id}
          label={field.label}
          value={field.value}
        />
      );
    }
    if (field.type === "Checkbox") {
      // TODO - group all checkboxes in groups
      return (
        <FormCheckbox
          group={field.group}
          name={field.id}
          label={field.label}
          value={field.value}
        />
      );
    }
    if (field.type === "Select") {
      console.log(field);
      return (
        <FormSelect
          name={field.id}
          label={field.label}
          options={field.options}
          value={field.value}
          id={id}
          dbField={field.db}
          returnLabel={ field.returnLabel}
        />
      );
    }
    if (field.type === "Slider") {
      // TODO - group all checkboxes in groups

      return (
        <FormSlider
          group={field.field}
          name={field.id}
          label={field.label}
          initialValue={parseFloat(field.initialValue)}
          width={field.width}
          marks={field.marks}
          padding={field.padding}
          min={parseFloat(field.min)}
          max={parseFloat(field.max)}
          step={parseFloat(field.step)}
          value={formValues[field.id]}
          setValue={setFormValues}
        />
      );
    }
    return <></>;
  }

  function NonGroup(props) {
    const { section } = props;
    return (
      <Box
        sx={{
          display: "flex",
          gap: "1rem",
          alignItems: "center",
          padding: "1rem",
          flexDirection: "column",
        }}
      >
        {section.fields.map((field, index) => {
          return <SortTheFields field={field} id={index} key={index} />;
        })}
      </Box>
    );
  }

  function Group(props) {
    const { section, index } = props;
    //console.log(section.type);
    return (
      <Box sx={{ marginBottom: "1rem" }}>
        <Typography variant="h8" sx={{ padding: "1rem" }} color="primary">
          {section.title}
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: "1rem",
            alignItems: "center",
            padding: "1rem",
          }}
        >
          {section.type === "radio" && (
            <FormRadio
              section={section}
              defaultValue={initialValues[section.id]}
            />
          )}
          {section.type !== "radio" &&
            section.fields.map((field, index) => {
              return <SortTheFields field={field} id={index} key={index} />;
            })}
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={{}}>
      <Paper>
        {validationSchema && initialValues !== false && (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              const _obj = Object.assign({}, values);
              console.log(_obj);
              preHandleSubmit(values, props.children);
            }}
          >
            <Form>
              <FormControl component="fieldset">
                <Box
                  sx={{
                    display: "flex",
                    gap: "1rem",
                    alignItems: "center",
                    flexDirection: "column",
                    marginBottom: "1rem",
                    paddingLeft: "1rem",
                    paddingRight: "2rem",
                  }}
                >
                  {layout.map((section, index) => {
                    return (
                      <Fragment key={index}>
                        {!section.group ? (
                          <NonGroup section={section} index={index} />
                        ) : (
                          <Group section={section} index={index} />
                        )}
                      </Fragment>
                    );
                  })}
                  {props.children}
                  <FormButton disabled={disabled}>{submitText}</FormButton>
                </Box>
              </FormControl>
            </Form>
          </Formik>
        )}
      </Paper>
    </Box>
  );
}

/*
const initialState = {
  name: "fred",
  address: "",
  postcode: "",
  email: "",
};

https://codesandbox.io/s/clever-snyder-1u410?fontsize=14&file=/src/form.js:1007-1090

*/
