import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";

export default function EventInfo(props) {
  const { details, time } = props;

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <Typography variant="h6">{details["title"]}</Typography>
    </Box>
  );
}
