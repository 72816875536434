import TreeView from "@mui/lab/TreeView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MuiTreeItem from "@mui/lab/TreeItem";
import {
  CircularProgress,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";

import { Fragment, useEffect, useState } from "react";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import { styled } from "@mui/material";

import findNestedObject from "../../utils/RecursiveSearch";

const side = 300;
const padding = 80;
const margin = 100;

const needsLabelVars = {
  "--color": "#FFA500",
};
// label highlight
const hasLabelVars = {
  "--color": "#00ff00",
};

const nolabelVars = {
  "--color": "#aaaaaa",
};

const ignoreVars = {
  "--color": "#d3d3d3",
};
export default function CTTreeView(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [coordinates, setCoordinates] = useState([0, 0]);
  const [rightNode, setRightNode] = useState(false);
  const [expanded, setExpanded] = useState([]);

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  function StyledMenu(props) {
    return (
      <Menu
        elevation={3}
        anchorReference="anchorPosition"
        anchorEl={anchorEl}
        anchorPosition={{ top: coordinates[1], left: coordinates[0] }}
        anchorOrigin={{
          vertical:
            (coordinates[1] - (margin + padding)) / side <= 0.5
              ? "top"
              : "bottom",
          horizontal:
            (coordinates[0] - (margin + padding)) / side <= 0.5
              ? "left"
              : "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handleClose}
        {...props}
      />
    );
  }
  const TreeItem = styled(MuiTreeItem)(({ theme }) => ({
    color: "var(--color)",
    ".MuiTreeItem-content.Mui-selected": {
      backgroundColor: "rgba(156, 39, 176, 0.5)",
      color: "#ffffff",
    },
  }));

  const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    root: {
      "&:focus": {
        backgroundColor: theme.palette.primary.main,
        "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
          color: theme.palette.common.white,
        },
      },
    },
  }));

  function onHandleSelect(uid) {
    props.handleSelect(uid);
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleRightClick(e, uid) {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
    setCoordinates([e.pageX, e.pageY]);
    //console.log(`right click on ${uid} ${e.pageX} ${e.pageY}`);
    setRightNode(uid);
  }
  function handleRightMenuClick(e) {
    //console.log(rightNode);
    // find the node in data and set .ignore to 1
    var result = findNestedObject(props.data, "uid", rightNode);
    result.ignore = 1;
    //console.log(result);
  }

  function Nodes(props) {
    const { node, i = 0 } = props;
    return Object.keys(node).map((key, index) => {
      if (
        typeof node[key] === "object" &&
        typeof node[key].uid !== "undefined"
      ) {
        if (typeof node[key].label !== "undefined") {
          if (node[key].label[0] === "no label") {
            return (
              <TreeItem
                nodeId={node[key].uid}
                label={key}
                key={index}
                style={nolabelVars}
                onContextMenu={(e) => handleRightClick(e, node[key].uid)}
                onClick={() => onHandleSelect(node[key])}
              >
                {typeof node[key] === "object" && (
                  <Nodes node={node[key]} key={index} />
                )}
              </TreeItem>
            );
          } else {
            return (
              <TreeItem
                nodeId={node[key].uid}
                label={key}
                key={index}
                style={
                  node[key].label
                    ? hasLabelVars
                    : node[key].ignore
                    ? ignoreVars
                    : needsLabelVars
                }
                onContextMenu={(e) => handleRightClick(e, node[key].uid)}
                onClick={() => onHandleSelect(node[key])}
              >
                {typeof node[key] === "object" && (
                  <Nodes node={node[key]} key={index} />
                )}
              </TreeItem>
            );
          }
        } else {
          return (
            <TreeItem
              nodeId={node[key].uid}
              label={key}
              key={index}
              style={
                node[key].label
                  ? hasLabelVars
                  : node[key].ignore
                  ? ignoreVars
                  : needsLabelVars
              }
              onContextMenu={(e) => handleRightClick(e, node[key].uid)}
              onClick={() => onHandleSelect(node[key])}
            >
              {typeof node[key] === "object" && (
                <Nodes node={node[key]} key={index} />
              )}
            </TreeItem>
          );
        }
      } else {
        return <></>;
      }
    });
  }
  //     onClick={() => onHandleSelect(node[key].uid)}
  console.log(props.data);

  return (
    <>
      {!props.data && <CircularProgress colour="primary" />}
      {props.data && (
        <>
          <TreeView
            aria-label="multi-select"
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            onNodeToggle={handleToggle}
            expanded={expanded}
            sx={{
              height: props.height,
              flexGrow: 1,
              maxWidth: props.width,
              overflowY: "auto",
            }}
          >
            <Nodes node={props.data} />
          </TreeView>

          <StyledMenu
            id="customized-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <StyledMenuItem onClick={handleRightMenuClick}>
              <ListItemIcon>
                <DoDisturbIcon fontSize="small" color="primary" />
              </ListItemIcon>
              <ListItemText primary="Ignore" />
            </StyledMenuItem>
          </StyledMenu>
        </>
      )}
    </>
  );
}

export function CTTreeViewDir(props) {
  const { height, width, handleSelect, data, expand } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [coordinates, setCoordinates] = useState([0, 0]);
  const [rightNode, setRightNode] = useState(false);
  const [expanded, setExpanded] = useState([]);

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
    //console.log(nodeIds);
  };

  useEffect(() => {
    //console.log(expand);
    setExpanded(expand);
  }, [expand]);

  useEffect(() => {
    //console.log(data);
  }, [data]);

  function StyledMenu(props) {
    return (
      <Menu
        elevation={3}
        anchorReference="anchorPosition"
        anchorEl={anchorEl}
        anchorPosition={{ top: coordinates[1], left: coordinates[0] }}
        anchorOrigin={{
          vertical:
            (coordinates[1] - (margin + padding)) / side <= 0.5
              ? "top"
              : "bottom",
          horizontal:
            (coordinates[0] - (margin + padding)) / side <= 0.5
              ? "left"
              : "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handleClose}
        {...props}
      />
    );
  }
  const TreeItem = styled(MuiTreeItem)(({ theme }) => ({
    color: "var(--color)",
    ".MuiTreeItem-content.Mui-selected": {
      backgroundColor: "rgba(156, 39, 176, 0.5)",
      color: "#ffffff",
    },
  }));

  const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    root: {
      "&:focus": {
        backgroundColor: theme.palette.primary.main,
        "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
          color: theme.palette.common.white,
        },
      },
    },
  }));

  function onHandleSelect(uid) {
    handleSelect(uid);
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleRightClick(e, uid) {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
    setCoordinates([e.pageX, e.pageY]);
    //console.log(`right click on ${uid} ${e.pageX} ${e.pageY}`);
    setRightNode(uid);
  }
  function handleRightMenuClick(e) {
    //console.log(rightNode);
    // find the node in data and set .ignore to 1
    var result = findNestedObject(data, "uid", rightNode);
    result.ignore = 1;
    //console.log(result);
  }
  function Nodes(props) {
    const { node } = props;
    return (
      <>
        {Object.keys(node).forEach((key, index) => {
          const uid = Math.floor(Math.random() * 10000 + 1);

          if (typeof node[key] === "object") {
            return (
              <TreeItem
                nodeId={uid}
                label={key}
                key={index}
                style={
                  node[key].label
                    ? hasLabelVars
                    : node[key].ignore
                    ? ignoreVars
                    : needsLabelVars
                }
                onContextMenu={(e) => handleRightClick(e, uid)}
                onClick={() => onHandleSelect(uid)}
              >
                {typeof node[key] === "object" && (
                  <Nodes node={node[key]} key={index} />
                )}
              </TreeItem>
            );
          }
        })}
      </>
    );
  }
  //     onClick={() => onHandleSelect(node[key].uid)}
  return (
    <>
      {!data && <CircularProgress colour="primary" />}
      {data && (
        <>
          <TreeView
            aria-label="multi-select"
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            onNodeToggle={handleToggle}
            expanded={expanded}
            sx={{
              height: { height },
              flexGrow: 1,
              maxWidth: { width },
              overflowY: "auto",
            }}
          >
            <Nodes node={data} />
          </TreeView>

          <StyledMenu
            id="customized-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <StyledMenuItem onClick={handleRightMenuClick}>
              <ListItemIcon>
                <DoDisturbIcon fontSize="small" color="primary" />
              </ListItemIcon>
              <ListItemText primary="Ignore" />
            </StyledMenuItem>
          </StyledMenu>
        </>
      )}
    </>
  );
}
/*
.MuiTreeItem-content.Mui-selected {
  background-color: rgba(156, 39, 176, 0.5) !important;
  color: #ffffff;
}
*/
