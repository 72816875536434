import { useContext } from "react";
import { Navigate } from "react-router-dom";
import GlobalStateContext from "../globalstate/globalStateContext";

export default function ProtectedRoute({ children }) {
  const token = sessionStorage.getItem("token");

  if (token) {
    return children;
  }

  return <Navigate replace to="/login" />;
}

export function ProtectedUserRoute({ userType, children }) {
  const global = useContext(GlobalStateContext);
  //console.log(userType);
  if (global.isMember(userType)) return children;
}
