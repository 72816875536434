// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/pie
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { ResponsivePie } from "@nivo/pie";
import { useEffect, useState } from "react";

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

export default function CTResponsivePie(props) {
  const {
    data,
    height,
    width,
    centerText,
    toolTip,
    enableArcLinkLabels,
    legends,
    arcLabel,
  } = props;
  const [total, setTotal] = useState(false);

  useEffect(() => {
    if (data.length === 0) return;
    var _total = 0;
    data.forEach((slice) => (_total += slice.value));
    setTotal(_total);
  }, [data]);

  const CenteredMetric = ({ dataWithArc, centerX, centerY }) => {
    return (
      <text
        x={centerX}
        y={centerY}
        textAnchor="middle"
        dominantBaseline="central"
        style={{
          fontSize: "20px",
          fontWeight: 600,
        }}
      >
        {centerText}
      </text>
    );
  };

  return (
    <>
      {total && (
        <Box
          sx={{
            height: height,
            width: width,
            border: "thin solid lightgray",
          }}
        >
          <ResponsivePie
            tooltip={({ datum: obj }) => toolTip(obj)}
            data={data}
            margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
            innerRadius={0.5}
            padAngle={0.7}
            cornerRadius={3}
            activeOuterRadiusOffset={8}
            borderWidth={1}
            borderColor={{
              from: "color",
              modifiers: [["darker", 0.2]],
            }}
            enableArcLinkLabels={enableArcLinkLabels}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor="#333333"
            arcLinkLabelsThickness={2}
            arcLinkLabelsColor={{ from: "color" }}
            arcLabelsSkipAngle={10}
            arcLabelsTextColor={{
              from: "color",
              modifiers: [["darker", 2]],
            }}
            colors={{ scheme: "set3" }}
            legends={legends}
            arcLabel={arcLabel}
            defs={[
              {
                id: "dots",
                type: "patternDots",
                background: "inherit",
                color: "rgba(255, 255, 255, 0.3)",
                size: 4,
                padding: 1,
                stagger: true,
              },
              {
                id: "lines",
                type: "patternLines",
                background: "inherit",
                color: "rgba(255, 255, 255, 0.3)",
                rotation: -45,
                lineWidth: 6,
                spacing: 10,
              },
            ]}
            fill={[
              {
                match: {
                  id: "Brand",
                },
                id: "dots",
              },
              {
                match: {
                  id: "Electrification",
                },
                id: "lines",
              },
            ]}
          />
        </Box>
      )}
    </>
  );
}
