import { Box } from "@mui/system";
import { useEffect, useState } from "react";

import SendToApi from "../../../api/sendtoapi";
import layout from "./layout.json";

import CTForm from "../../../components/forms/ctform";
import { Divider, Typography } from "@mui/material";

export default function MissionControlCard() {
  const [initialValues, setInitialValues] = useState(false);
  const [settings, setSettings] = useState(false);

  useEffect(() => {
    let cancel = false;
    (async () => {
      var values = {};
      values.key = "predict_mission_control";
      const response = await SendToApi("ninja/getsetting", values);
      if (cancel) return;
      if (response.status !== 200) {
        //console.log(response);
      } else {
        //console.log(response.data);
        setSettings(response.data);
      }
    })();

    return () => {
      cancel = true;
    };
  }, []);

  function handleSaveSettings(formvalues) {
    (async () => {
      var args = {};
      args.key = "predict_mission_control";
      args.value = formvalues;
      const response = await SendToApi("ninja/setsetting", args);
      if (response.status !== 200) {
        //console.log(`@@@ error @@@ ${response}`);
      } else {
        //console.log(response.data);
      }
    })();
  }

  return (
    <>
      <Typography color="primary" gutterBottom variant="h6">
        Account - Mission Control
      </Typography>
      <Divider sx={{ marginBottom: "1rem" }} />{" "}
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        {settings && (
          <CTForm
            submitText="Save changes"
            handleSubmit={handleSaveSettings}
            layout={layout.settings}
            initialValuesSupplied={settings}
          />
        )}
      </Box>
    </>
  );
}

/*

{settings && (
    <CTForm
      submitText="Save changes"
      handleSubmit={handleSaveSettings}
      layout={layout.settings}
      initialValuesSupplied={settings}
    />
  )}

*/
