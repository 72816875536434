import {
  Autocomplete,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
  Slider,
  TextField,
  Typography,
} from "@mui/material";

import SendToApi from "./../../api/sendtoapi";
import { Fragment, useContext, useState } from "react";
import { Box } from "@mui/system";

import { useEffect } from "react";

import options from "./data/options.json";
import StatusStepper from "../../components/stepper/statusStepper";
import { Navigate } from "react-router-dom";
import TabPanels from "../../components/tabpanel";
import GlobalStateContext from "../../globalstate/globalStateContext";
import { NinjaText } from "../../components/ninja";

export default function Selector() {
  const [launchComplete, setLaunchComplete] = useState(false);
  const [uiClientdata, setUiClientdata] = useState();
  const [results, setResults] = useState(false);
  const [launchStatus, setLaunchStatus] = useState(0);
  const [campaigns, setCampaigns] = useState(false);
  const [clicks, setClicks] = useState(false);
  const [campaignsValue, setCampaignsValue] = useState([]);
  const [clicksValue, setClicksValue] = useState([]);
  const [selectorSet, setSelectorSet] = useState([]);
  const [resultsEncoded, setResultsEncoded] = useState(false);
  const [disabledLabels, setDisabledLabels] = useState({});
  const [ninjaRun, setNinjaRun] = useState(false);
  const [selectorName, setSelectorName] = useState("");
  const global = useContext(GlobalStateContext);

  useEffect(() => {
    let isSubscribed = true;
    const fetchUserData = async () => {
      var values = {};

      values.name = "predict";
      const response = await SendToApi("predict/getui", values);

      if (response.status === 200) {
        var obj = JSON.parse(response.data);
        var _results = {};
        setUiClientdata(obj);
        Object.keys(obj).map((column) => {
          const parent = column;
          _results[parent] = {};
          obj[column].map((group, i) => {
            const child = Object.keys(group)[0];
            _results[parent][child] = {};
            if (group[Object.keys(group)].type === "slider") {
              _results[parent][child] = 0;
            } else {
              /*   group[Object.keys(group)].items.map((item, i) => {
                const choice = item.label;
                _results[parent][child][choice] = 0;
              });*/
            }
          });
        });
      }
      //map through options here ..... and assign direct from options
      Object.keys(options).map((column) => {
        const parent = column;
        _results[parent] = {};
        options[column].map((group, i) => {
          const child = Object.keys(group)[0];
          _results[parent][child] = {};
          if (group[Object.keys(group)].type === "slider") {
            _results[parent][child] = 0;
          } else {
            /*group[Object.keys(group)].items.map((item, i) => {
              const choice = item.label;
              _results[parent][child][choice] = 0;
            });*/
          }
        });
      });

      // get the campaigns

      values = {};
      const campaign_result = await SendToApi("predict/getcampaigns", values);
      
      if (campaign_result.data) {
        var _campaigns = [];
        //console.log(campaign_result.data);
        var campaign_array = Object.entries(campaign_result.data);
        //console.log(campaign_array);
        campaign_array.forEach((c) => {
          const obj = { label: c };
          _campaigns.push(obj);
        });
        if (isSubscribed) {
          setCampaigns(_campaigns);
        }
        // add the campaigns to the results
      }
      _results["campaigns"] = {};
      _results["campaigns"]["campaign"] = {};

      /*Object.keys(_campaigns).map((i) => {
            _results[parent][child][_campaigns[i].label] = 0;
          });*/

      _results["campaigns"]["response_variable"] = {};
      _results["campaigns"]["response_variable"] = "open";

      // get complete set of labels
      var values = {};
      values.key = "labels";
      const responseClicks = await SendToApi("accounts/getsettings", values); // this returns the info from the database
      if (responseClicks.status === 200) {
        // get labels that have been used
        var values = {};
        values.key = "label_totals";
        const responseLabelsUsed = await SendToApi(
          "accounts/getsettings",
          values
        ); // this returns the info from the database
        //console.log(responseLabelsUsed.data);
        if (responseLabelsUsed.data) {
          if (responseLabelsUsed.status === 200) {
            var _clicks = [];
            var _disabled = {};
            JSON.parse(responseClicks.data).labels.forEach((c, i) => {
              const obj = { label: c };
              if (c in JSON.parse(responseLabelsUsed.data)) {
                //console.log(c);
              } else {
                _disabled[c] = "1";
              }
              if (c !== "no label") {
                _clicks.push(obj);
              }
            });
            if (isSubscribed) {
              setClicks(_clicks);
              setDisabledLabels(_disabled);
            }
          }
        }
      }

      // add the campaigns to the results

      const parent = "clicks";
      const child = "clicks";
      _results[parent] = {};
      _results[parent][child] = {};

      /*Object.keys(_clicks).map((i) => {
        _results[parent][child][_clicks[i].label] = 0;
      });*/

      _results["details"] = {};
      _results["details"]["job_name"] = "";
      _results["details"]["description"] = "";
      if (isSubscribed) {
        setResults(_results);
      }
      var _selectorSet = [];
      Object.keys(_results).map((parent) => {
        _selectorSet[parent] = "false";
      });
      setSelectorSet({ ..._selectorSet });
    };

    if (!results) fetchUserData();
    return () => {
      isSubscribed = false;
    };
  }, []);

  useEffect(() => {
    if (!selectorSet) return;
    ////console.log(selectorSet);
  }, [selectorSet]);

  useEffect(() => {
    if (!results) return;
    //console.log(results);
    setResultsEncoded(JSON.stringify(results));
  }, [results]);

  function handleCampaignChange(event, values) {
    var _results = results;
    const parent = "campaigns";
    const child = "campaign";
    values.forEach((value) => {
      _results[parent][child][value.label] = 1;
    });
    //console.log(_results);
    setCampaignsValue(values);

    var _selectorSet = selectorSet;
    if (values.length === 0) {
      _selectorSet["campaigns"] = "false";
    } else {
      _selectorSet["campaigns"] = "true";
    }
    setSelectorSet({ ..._selectorSet });
    setResults({ ..._results });
  }

  function handleClicksChange(event, values) {
    var _results = results;
    const parent = "clicks";
    const child = "clicks";
    values.forEach((value) => {
      _results[parent][child][value.label] = 1;
    });
    //console.log(_results);
    setClicksValue(values);
    var _selectorSet = selectorSet;
    if (values.length === 0) {
      _selectorSet["clicks"] = "false";
    } else {
      _selectorSet["clicks"] = "true";
    }
    setSelectorSet({ ..._selectorSet });

    setResults({ ..._results });
  }

  const handleChange = (parent, child, choice, event) => {
    var _results = results;
    if (event.target.name === "slider") {
      _results[parent][child][choice] = event.target.value;
    } else {
      _results[parent][child][choice] = !results[parent][child][choice];
    }
    var _selectorSet = selectorSet;
    _selectorSet[parent] = "true";
    setSelectorSet({ ..._selectorSet });
    setResults({ ..._results });
  };

  const handleRadioChange = (parent, child, event) => {
    var _results = results;
    _results[parent][child] = event.target.value;
    setResults({ ..._results });
  };

  function handleTextChange(e) {
    //console.log(e.target.id);
    //console.log(e.target.value);

    var _results = results;
    _results["details"][e.target.id] = e.target.value;
    setResults({ ..._results });

    var _selectorSet = selectorSet;
    _selectorSet["details"] = "true";
    setSelectorSet({ ..._selectorSet });
  }

  async function Register(output) {
    var values = {};
    values.data_info = JSON.stringify(output);
    values.type = "predict";
    values.job_name = output["details"]["job_name"];
    values.description = output["details"]["description"];
    values.ninja = ninjaRun;

    var response = await SendToApi("jobs/register", values);
    if (response.status === 200) {
      setLaunchStatus(1);
      return response.data;
    }
    return false;
  }

  async function handleRun() {
    var output = {};

    setTimeout(() => {
      setLaunchStatus(2);
    }, 1000);
    setTimeout(() => {
      setLaunchStatus(3);
    }, 2000);

    //console.log(results);

    Object.keys(results).forEach((group) => {
      //console.log(`${group} **********************`);
      output[group] = {};
      Object.keys(results[group]).forEach((item) => {
        output[group][item] = {};

        switch (group) {
          case "details":
            output[group][item] = results[group][item];
            break;

          case "options":
            output[group][item] = results[group][item];
            break;

          case "clicks":
          case "products":
          case "consumer behaviour":
            Object.keys(results[group][item]).forEach((choice) => {
              if (results[group][item][choice]) {
                //console.log(`${choice} is set`);
                output[group][item][choice] = 1;
              }
            });
            break;
          case "campaigns":
            //console.log(item);
            if (item === "response_variable") {
              output[group][item] = results[group][item];
            } else {
              Object.keys(results[group][item]).forEach((choice) => {
                if (results[group][item][choice]) {
                  //console.log(`${choice} is set`);
                  output[group][item][choice] = 1;
                }
              });
            }
            break;
          default:
        }
      });
    });
    //console.log("output");
    //console.log(output);

    const jobId = await Register(output);

    var values = {};
    values.jobid = jobId;

    var response = await SendToApi("scripts/launch", values);
    if (response.status === 200) {
      setLaunchComplete(true);
    } else {
    }
  }

  function Campaigns() {
    return (
      <Box
        sx={{
          display: "flex",
          gap: "1rem",
          flexWrap: "wrap",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        {campaigns && (
          <Autocomplete
            multiple
            disablePortal
            value={campaignsValue}
            onChange={(event, newValue) => {
              handleCampaignChange(event, newValue);
            }}
            id="combo-box-demo"
            options={campaigns}
            sx={{ width: 500 }}
            renderInput={(params) => (
              <TextField {...params} label="Select Campaigns" />
            )}
          />
        )}
        <FormControl sx={{ marginTop: "1rem" }}>
          <FormLabel id="demo-radio-buttons-group-label">
            Response Variable
          </FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            value={results["campaigns"]["response_variable"]}
            name="radio-buttons-group"
            onChange={(e) =>
              handleRadioChange("campaigns", "response_variable", e)
            }
          >
            <FormControlLabel value="open" control={<Radio />} label="Open" />
            <FormControlLabel
              value="clicks"
              control={<Radio />}
              label="Clicks"
            />
          </RadioGroup>
        </FormControl>
      </Box>
    );
  }
  function Clicks() {
    return (
      <Box
        sx={{
          display: "flex",
          gap: "1rem",
          flexWrap: "wrap",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        {clicks !== false && (
          <>
            <Autocomplete
              multiple
              disablePortal
              value={clicksValue}
              onChange={(event, newValue) => {
                handleClicksChange(event, newValue);
              }}
              id="combo-box-demo"
              getOptionDisabled={(option) => option.label in disabledLabels}
              options={clicks}
              sx={{ width: 500 }}
              renderInput={(params) => (
                <TextField {...params} label="Select Clicks" />
              )}
            />
          </>
        )}
      </Box>
    );
  }
  useEffect(() => {
    if (!disabledLabels) return;
    //console.log(disabledLabels);
  }, [disabledLabels]);

  function Form() {
    return <></>;
  }

  function Options() {
    return (
      <>
        <>
          <Typography
            sx={{ textTransform: "capitalize" }}
            color="primary"
            variant="h7"
          >
            Options
          </Typography>
        </>
        <>
          <Sliders />
          <Typography color="secondary" variant="subtitle2">
            Note. Just applies to clicks and campaigns
          </Typography>
        </>
      </>
    );
  }
  function Sliders() {
    return (
      <Box
        sx={{
          //   border: "red solid thick",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          gap: "5rem",
          padding: "1rem",
        }}
      >
        <FormItem title="Recency" group={{ type: "slider" }} />
        <FormItem title="Frequency" group={{ type: "slider" }} />
      </Box>
    );
  }
  function FormItem(props) {
    const { title, group, show = true } = props;
    return (
      <Box sx={{ width: "100%", padding: "1rem" }}>
        {show && <FormLabel>{title}</FormLabel>}
        <Group group={group} title={title} parent="options" />
      </Box>
    );
  }

  function Group(props) {
    const { group, title, parent } = props;
    switch (group.type) {
      case "radio":
        return (
          <GroupRadio
            results={results}
            handleRadioChange={handleRadioChange}
            group={group}
            title={title}
            parent={parent}
          />
        );

      case "checkbox":
        return (
          <GroupCheckbox
            results={results}
            handleChange={handleChange}
            group={group}
            title={title}
            parent={parent}
          />
        );

      case "slider":
        return (
          <GroupSlider
            results={results}
            setResults={setResults}
            title={title}
            parent={parent}
          />
        );

      default:
        return <></>;
    }
  }
  function GroupRadio(props) {
    const { group, title, parent } = props;
    const child = title;

    return (
      <FormControl component="fieldset">
        <RadioGroup
          aria-label={group}
          name="radio-buttons-group"
          value={results[parent][child]}
          onChange={(e) => handleRadioChange(parent, child, e)}
        >
          {group.items.map((item, i) => {
            return (
              <FormControlLabel
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
                labelPlacement="start"
                value={item.label}
                control={<Radio />}
                label={item.label}
                key={i}
                name="radio"
              />
            );
          })}
        </RadioGroup>
      </FormControl>
    );
  }

  function GroupCheckbox(props) {
    const { group, title, parent, handleChange } = props;
    const child = title;

    return (
      <FormControl component="fieldset">
        <FormGroup aria-label={group} name="checkbox-group">
          {group.items.map((item, i) => {
            var choice = item.label;
            ////console.log(String(i + 1));
            return (
              <FormControlLabel
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
                labelPlacement="start"
                value={item.label}
                control={
                  <Checkbox
                    onChange={(e) => handleChange(parent, child, choice, e)}
                    checked={
                      results[parent][child][choice] !== true ? false : true
                    }
                  />
                }
                label={item.label}
                key={i}
                name="checkbox"
              />
            );
          })}
        </FormGroup>
      </FormControl>
    );
  }
  const marksFrequency = [
    {
      value: 0,
      label: "Everything",
    },
    {
      value: 1,
      label: "2+",
    },
  ];
  const marksRecency = [
    {
      value: 0,
      label: "Everything",
    },
    {
      value: 1,
      label: "Last 12m",
    },
  ];

  const handleSliderChange = (parent, child, event, newValue) => {
    var _results = results;
    _results[parent][child] = event.target.value;
    setResults({ ..._results });
    var _selectorSet = selectorSet;
    _selectorSet[parent] = "true";
    //console.log(_selectorSet);
    setSelectorSet({ ..._selectorSet });
  };

  function GroupSlider(props) {
    const { title, parent } = props;
    const child = title;

    return (
      <Slider
        sx={{ width: "100%" }}
        value={results[parent][child]}
        name="slider"
        aria-label="slider"
        onChange={(event, newValue) =>
          handleSliderChange(parent, child, event, newValue)
        }
        min={0}
        max={1}
        marks={title === "Frequency" ? marksFrequency : marksRecency}
      />
    );
  }
  function handleNinjaRun() {
    setNinjaRun(true);
  }
  useEffect(() => {
    if (!ninjaRun) return;
    handleRun();
    setNinjaRun(false);
  }, [ninjaRun]);

  return (
    <Box sx={{ padding: "1rem" }}>
      {launchComplete && <Navigate replace to="/predict/dashboard" />}

      <Paper sx={{ mb: "2rem", p: "1rem", minHeight: "200px" }}>
        <Typography color="primary" gutterBottom variant="h6">
          Selector
        </Typography>
        <Divider sx={{ marginBottom: "1rem" }} />
        {uiClientdata && results && campaigns && clicks && (
          <Box sx={{ display: "flex", gap: "1rem", flexDirection: "column" }}>
            <TabPanels>
              <div title="Campaigns" set={selectorSet["campaigns"]}>
                <Campaigns />
              </div>

              <div title="Clicks" set={selectorSet["clicks"]}>
                <Clicks />
              </div>

              {Object.keys(uiClientdata).map((column, i) => {
                return (
                  <div title={column} key={i} set={selectorSet[column]}>
                    {uiClientdata[column].map((group, i) => {
                      return (
                        <div key={i}>
                          <Typography component="legend" color="primary">
                            {Object.keys(group)}
                          </Typography>
                          <Group
                            results={results}
                            handleRadioChange={handleRadioChange}
                            handleChange={handleChange}
                            group={group[Object.keys(group)]}
                            title={Object.keys(group)[0]}
                            parent={column}
                          />
                        </div>
                      );
                    })}
                  </div>
                );
              })}
              <div title="Options" set={selectorSet["options"]}>
                <Options />
              </div>
              <div title="Details" set={selectorSet["details"]}>
                <>
                  <Typography
                    sx={{ textTransform: "capitalize" }}
                    color="primary"
                    variant="h7"
                  >
                    Details
                  </Typography>
                </>
                <Box
                  sx={{ display: "flex", gap: "1rem", flexDirection: "column" }}
                >
                  <TextField
                    value={results["details"]["job_name"]}
                    sx={{ m: "1rem" }}
                    id="job_name"
                    label="Selector Name"
                    variant="outlined"
                    fullWidth={true}
                    onChange={handleTextChange}
                    inputProps={{
                      style: {
                        padding: "18.5px 14px",
                        border: "0",
                      },
                    }}
                  />
                  <TextField
                    value={results["details"]["description"]}
                    sx={{ m: "1rem" }}
                    id="description"
                    label="Description"
                    variant="outlined"
                    fullWidth={true}
                    multiline
                    onChange={handleTextChange}
                    rows={4}
                    inputProps={{
                      style: {
                        padding: "18.5px 14px",
                        border: "0",
                      },
                    }}
                  />
                </Box>
              </div>
            </TabPanels>
            <StatusStepper
              steps={[
                { label: "Register" },
                { label: "Store" },
                { label: "Launch" },
              ]}
              step={launchStatus}
            />

            <Box
              sx={{
                display: "flex",
                gap: "1rem",
                flexDirection: "column",
                padding: "1rem",
              }}
            >
              <Button
                variant="contained"
                component="label"
                color="primary"
                size="medium"
                onClick={handleRun}
                sx={{}}
              >
                Run
              </Button>
            </Box>
            {global.userIs("ninja") && (
              <Button
                variant="contained"
                component="label"
                color="ninja"
                size="medium"
                onClick={handleNinjaRun}
                sx={{}}
              >
                Ninja boneslight run
              </Button>
            )}
            {global.userIs("ninja") && resultsEncoded && (
              <NinjaText text={resultsEncoded} />
            )}
          </Box>
        )}
        {uiClientdata && results && !campaigns && !clicks && (
          <Box sx={{ display: "flex", gap: "1rem", flexDirection: "column" }}>
            <TabPanels>
              {Object.keys(uiClientdata).map((column, i) => {
                return (
                  <div title={column} key={i} set={selectorSet[column]}>
                    {uiClientdata[column].map((group, i) => {
                      return (
                        <div key={i}>
                          <Typography component="legend" color="primary">
                            {Object.keys(group)}
                          </Typography>
                          <Group
                            results={results}
                            handleRadioChange={handleRadioChange}
                            handleChange={handleChange}
                            group={group[Object.keys(group)]}
                            title={Object.keys(group)[0]}
                            parent={column}
                          />
                        </div>
                      );
                    })}
                  </div>
                );
              })}
              <div title="Options" set={selectorSet["options"]}>
                <Options />
              </div>
              <div title="Details" set={selectorSet["details"]}>
                <>
                  <Typography
                    sx={{ textTransform: "capitalize" }}
                    color="primary"
                    variant="h7"
                  >
                    Details
                  </Typography>
                </>
                <Box
                  sx={{ display: "flex", gap: "1rem", flexDirection: "column" }}
                >
                  <TextField
                    value={results["details"]["job_name"]}
                    sx={{ m: "1rem" }}
                    id="job_name"
                    label="Selector Name"
                    variant="outlined"
                    fullWidth={true}
                    onChange={handleTextChange}
                    inputProps={{
                      style: {
                        padding: "18.5px 14px",
                        border: "0",
                      },
                    }}
                  />
                  <TextField
                    value={results["details"]["description"]}
                    sx={{ m: "1rem" }}
                    id="description"
                    label="Description"
                    variant="outlined"
                    fullWidth={true}
                    multiline
                    onChange={handleTextChange}
                    rows={4}
                    inputProps={{
                      style: {
                        padding: "18.5px 14px",
                        border: "0",
                      },
                    }}
                  />
                </Box>
              </div>
            </TabPanels>
            <StatusStepper
              steps={[
                { label: "Register" },
                { label: "Store" },
                { label: "Launch" },
              ]}
              step={launchStatus}
            />

            <Box
              sx={{
                display: "flex",
                gap: "1rem",
                flexDirection: "column",
                padding: "1rem",
              }}
            >
              <Button
                variant="contained"
                component="label"
                color="primary"
                size="medium"
                onClick={handleRun}
                sx={{}}
              >
                Run
              </Button>
            </Box>
            {global.userIs("ninja") && (
              <Button
                variant="contained"
                component="label"
                color="ninja"
                size="medium"
                onClick={handleNinjaRun}
                sx={{}}
              >
                Ninja boneslight run
              </Button>
            )}
            {global.userIs("ninja") && resultsEncoded && (
              <NinjaText text={resultsEncoded} />
            )}
          </Box>
        )}
      </Paper>
    </Box>
  );
}
