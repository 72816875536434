// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/bar
import { Box, Typography } from "@mui/material";
import { ResponsiveBar } from "@nivo/bar";

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

export default function CTResponsiveBar(props) {
  const {
    margin,
    label,
    data,
    height,
    width,
    index,
    keys,
    axisLeft,
    axisBottom,
    groupMode,
    layout,
  } = props;
  return (
    <Box
      sx={{
        height: height,
        width: width,
        border: "lightgrey solid thin",
        padding: "1rem",
      }}
    >
      <ResponsiveBar
        data={data}
        keys={keys}
        label={label}
        groupMode={groupMode}
        layout={layout}
        indexBy={index}
        margin={margin}
        padding={0.3}
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: true }}
        colors={{ scheme: "nivo" }}
        borderColor={{
          from: "color",
          modifiers: [["darker", 1.6]],
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={axisBottom}
        axisLeft={axisLeft}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{
          from: "color",
          modifiers: [["darker", 1.6]],
        }}
        tooltip={({ id, value, color }) => (
          <div
            style={{
              padding: 12,
              background: "#ffffff",
              border: `${color} thin solid`,
              borderRadius: 5,
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography>
                <strong>
                  {id}: {value}%
                  
                </strong>
              </Typography>
            </Box>
          </div>
        )}
      />
    </Box>
  );
}
