import {
  Alert,
  AlertTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";

import CancelIcon from "@mui/icons-material/Cancel";
import { Box } from "@mui/system";
import CTForm from "../../components/forms/ctform";
import layout from "./layout.json";
import SendToApi from "../../api/sendtoapi";
import { useState } from "react";

export default function AddUser(props) {
  const { handleClose } = props;
  const [error, setError] = useState(false);

  async function handleSubmit(values) {
    console.log("add new user");

    const response = await SendToApi("accounts/adduser", values);

    if (response.status === 200) {
      handleClose();
    }

    if (response.status === 400) {
      setError(response.statusText);
    }
  }

  return (
    <Box>
      <DialogTitle id="responsive-dialog-title">Add new user</DialogTitle>
      {error && (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {error}
        </Alert>
      )}
      <DialogContent>
        <CTForm
          submitText="New User"
          handleSubmit={handleSubmit}
          layout={layout.newuser}
        />
      </DialogContent>
      <DialogActions>
        <IconButton aria-label="delete" onClick={handleClose}>
          <CancelIcon />
        </IconButton>
      </DialogActions>
    </Box>
  );
}
