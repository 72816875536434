import { DialogActions, DialogContent, IconButton } from "@mui/material";

import CancelIcon from "@mui/icons-material/Cancel";
import layout from "./layout.json";
import { Box } from "@mui/system";

import SendToApi from "../../api/sendtoapi";

import CTForm from "../../components/forms/ctform";
import { useEffect, useState } from "react";
import JsonEdit from "../../components/jsonedit/jsonedit";

async function handleTestEmail(values) {
  //console.log(values.testemail);
  const response = await SendToApi("actions/testemail", values); // this tells the server to make the click tables - it does not return anything
  if (response.status === 200) {
    //console.log("email sent sucessfully");
    return;
  }
  //console.log("no email sent");
}

export default function NinjaDialog(props) {
  const [settings, setSettings] = useState(false);

  useEffect(() => {
    //console.log("in effect");
    let cancel = false;
    (async () => {
      var values = {};
      //values.item = "predictscript";
      const response = await SendToApi("ninja/getapienvsettings", values);
      if (cancel) return;
      if (response.status !== 200) {
        //console.log(response);
      } else {
        //console.log(JSON.stringify(response.data));
        setSettings(JSON.stringify(response.data));
      }
    })();

    return () => {
      cancel = true;
    };
  }, []);

  async function onSave(index) {
    const args = {};
    args.value = settings.rows[index].value;
    args.key = settings.rows[index].key;
    const response = await SendToApi("ninja/setapienvsettings", args);
    if (response.status !== 200) {
      //console.log(response);
    } else {
      //console.log("all good");
    }
  }

  function onEdit(edit, i) {
    //console.log(edit);
    //console.log(i);
    var _settings = settings;
    _settings.rows[i].value = edit;
    setSettings({ ..._settings });
  }
  return (
    <div>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "1rem",
            marginTop: "50px",
            gap: "1rem",
          }}
        >
          <CTForm
            submitText="Send Test Email"
            handleSubmit={handleTestEmail}
            layout={layout.testemail}
          />
          {settings && (
            <JsonEdit
              key="1"
              title="api env settings"
              data={settings}
              index="1"
              onEdit={onEdit}
              onSave={onSave}
            />
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <IconButton aria-label="delete" onClick={props.handleClose}>
          <CancelIcon />
        </IconButton>
      </DialogActions>
    </div>
  );
}
