import {
  Autocomplete,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import FileUpload from "../../components/fileupload/fileupload";
import SendToApi from "../../api/sendtoapi";
import clsx from "clsx";
import { Box } from "@mui/system";

import RealStateContext from "../../wss/realState/realStateContext";
import { FileUploadToAPI } from "../../api/sendtoapi";
import StatusStepper from "../../components/stepper/statusStepper";
import { Navigate } from "react-router-dom";
import { ConnectingAirportsOutlined } from "@mui/icons-material";

const chunk_size = 1024 * 1024;

export default function NewJobAuto() {
  // State
  const [selectedFiles, setSelectedFiles] = useState({});
  const [selectedFileLabels, setSelectedFileLabels] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [launchStatus, setLaunchStatus] = useState(0);
  const [launchComplete, setLaunchComplete] = useState(false);
  const [metricValue, setMetricValue] = useState("opens");
  const [campaigns, setCampaigns] = useState(false);
  const [launch, setLaunch] = useState(false);
  const [campaignA, setCampaignA] = useState([]);
  const [campaignB, setCampaignB] = useState([]);
  const [campaignAJobs, setCampaignAJobs] = useState([]);
  const [campaignBJobs, setCampaignBJobs] = useState([]);
  const [campaignAJobValues, setCampaignAJobValues] = useState([]);
  const [campaignBJobValues, setCampaignBJobValues] = useState([]);

  const [jobInfo, setJobInfo] = useState({});

  const jobIdRef = useRef();

  useEffect(() => {
    //console.log(stateLocal);
    if (campaignA && campaignB) {
      setLaunch(true);
    }
  }, [campaignA, campaignB]);

  useEffect(() => {
    const fetchUserData = async () => {
      // get the campaigns
      var values = {};
      values.key = "settings";
      values.item = "jobidtable";
      const settings = await SendToApi("accounts/getsettings", values);
      if (settings.status === 200) {
        values = {};
        values.table = settings.data;
        //console.log(settings);
        const campaign_result = await SendToApi("predict/getcampaigns", values);
        //console.log(campaign_result);
        if (campaign_result.data) {
          var _campaigns = [];
          campaign_result.data.forEach((c, i) => {
            const obj = { label: c, id: i };
            _campaigns.push(obj);
          });
          //console.log(_campaigns);
          setCampaigns(_campaigns);
        }
        // add the campaigns to the results
      }
    };
    fetchUserData();
  }, []);
  // Context
  const rt = useContext(RealStateContext);

  const selectedFilesRef = useRef();
  selectedFilesRef.current = selectedFiles;

  // Async Functions

  async function onLaunch() {
    //console.log("onlaunch");
    setSubmitting(true);
    // get job id

    // get the job id
    const newjobId = await onRegister();
    jobIdRef.current = newjobId;

    Launch();
  }

  async function CampaignChanged(version, value) {
    var values = {};
    values.campaigns = value;
    //console.log(settings);
    const result = await SendToApi("predict/getcampaignjobs", values);
    console.log(result.data);

    switch (version) {
      case "A":
        setCampaignA(value);
        console.log(result.data);
        setCampaignAJobs(result.data);
        Object.keys(result.data).forEach((job) => {
          // if the job object does not already exist ...
          if (typeof campaignAJobValues[job] === "undefined") {
            setCampaignAJobValues((prevState) => ({
              ...prevState,
              [job]: [],
            }));
          }
        });
        break;
      case "B":
        setCampaignB(value);
        setCampaignBJobs(result.data);
        Object.keys(result.data).forEach((job) => {
          // if the job object does not already exist ...
          if (typeof campaignBJobValues[job] === "undefined") {
            setCampaignBJobValues((prevState) => ({
              ...prevState,
              [job]: [],
            }));
          }
        });
        break;
      default:
    }
  }
  async function Launch() {
    //console.log("launch");
    var values = {};
    values.jobid = jobIdRef.current;
    var response = await SendToApi("scripts/launch", values);
    if (response.status === 200) {
      setLaunchStatus(4);
      setLaunchComplete(true);
    } else {
    }

    setSubmitting(false);
    return false;
  }

  async function onRegister() {
    // TODO get a job id from the api
    //console.log("register");
    var values = {};

    var _values = {};
    _values.campaignA = campaignA;
    _values.campaignB = campaignB;
    _values.metric = metricValue;
    _values.campaignAJobs = campaignAJobValues;
    _values.campaignBJobs = campaignBJobValues;
    values.data_info = JSON.stringify(_values);
    values.type = "upliftauto";
    values.job_name = jobInfo.job_name;
    values.description = jobInfo.description;

    var response = await SendToApi("jobs/register", values);
    if (response.status === 200) {
      var initialState = { state: "launched" };
      rt.setRealTime({
        ...rt.realTime,
        [response.data]: initialState,
      });

      setLaunchStatus(launchStatus + 1);
      return response.data;
    }
    return false;
  }
  // Functions
  function handleChange(e) {
    //console.log(e.target);
    setJobInfo((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  }

  const handleRadioChange = (event) => {
    //console.log(event);
    setMetricValue(event.target.value);
  };
  // return
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        padding: "1rem",
        width: "90%",
      }}
    >
      {launchComplete && <Navigate replace to="/uplift/dashboard" />}
      <Typography color="primary" gutterBottom variant="h6">
        Launch new automatic model
      </Typography>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        {campaigns && (
          <>
            <Divider sx={{ marginBottom: "1rem" }} />
            <Autocomplete
              multiple
              disablePortal
              isOptionEqualToValue={(option, value) => option.id === value.id}
              value={campaignA}
              onChange={(e, newValue) => {
                console.log(newValue);
                CampaignChanged("A", newValue);
              }}
              options={campaigns}
              sx={{ width: 500 }}
              renderInput={(params) => (
                <TextField {...params} label="Select Campaign A" />
              )}
            />
            {campaignAJobs &&
              Object.keys(campaignAJobs).map((job, index) => {
                return (
                  <Box key={index}>
                    <Autocomplete
                      multiple
                      disablePortal
                      value={campaignAJobValues[job]}
                      onChange={(e, newValue) => {
                        console.log(newValue);
                        setCampaignAJobValues((prevState) => ({
                          ...prevState,
                          [job]: newValue,
                        }));
                      }}
                      options={campaignAJobs[job]}
                      sx={{ width: 500 }}
                      renderInput={(params) => (
                        <TextField {...params} label={`${job} job ids`} />
                      )}
                    />
                  </Box>
                );
              })}
            <Divider sx={{ marginBottom: "1rem" }} />

            <Autocomplete
              multiple
              disablePortal
              isOptionEqualToValue={(option, value) => option.id === value.id}
              value={campaignB}
              onChange={(e, newValue) => {
                CampaignChanged("B", newValue);
              }}
              options={campaigns}
              sx={{ width: 500 }}
              renderInput={(params) => (
                <TextField {...params} label="Select Campaign B" />
              )}
            />
          </>
        )}
        {campaignBJobs &&
          Object.keys(campaignBJobs).map((job, index) => {
            return (
              <Box key={index}>
                <Autocomplete
                  multiple
                  disablePortal
                  value={campaignBJobValues[job]}
                  onChange={(e, newValue) => {
                    setCampaignBJobValues((prevState) => ({
                      ...prevState,
                      [job]: newValue,
                    }));
                  }}
                  options={campaignBJobs[job]}
                  sx={{ width: 500 }}
                  renderInput={(params) => (
                    <TextField {...params} label={`${job} job ids`} />
                  )}
                />
              </Box>
            );
          })}

        <Divider />
        <FormControl>
          <FormLabel id="demo-controlled-radio-buttons-group">
            Metrics
          </FormLabel>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={metricValue}
            onChange={handleRadioChange}
          >
            <FormControlLabel value="opens" control={<Radio />} label="Opens" />
            <FormControlLabel
              value="clicks"
              control={<Radio />}
              label="Clicks"
            />
            <FormControlLabel value="sales" control={<Radio />} label="Sales" />
          </RadioGroup>
        </FormControl>
        <TextField
          value={jobInfo.job_name}
          id="job_name"
          label="Model Name"
          variant="outlined"
          onChange={handleChange}
          inputProps={{
            style: {
              padding: "18.5px 14px",
              border: "0",
            },
          }}
        />

        <TextField
          value={jobInfo.description}
          id="description"
          label="Description"
          variant="outlined"
          multiline
          onChange={handleChange}
          rows={4}
          inputProps={{
            style: {
              padding: "18.5px 14px",
              border: "0",
            },
          }}
        />

        <Button
          disabled={!launch}
          className={clsx(
            submitting && "waiting-border"
            //submitting && classes.submitting
          )}
          size="medium"
          variant="contained"
          color="primary"
          onClick={onLaunch}
        >
          Launch 'under development'
        </Button>
        {selectedFiles["t1"] && selectedFiles["t2"] && (
          <StatusStepper
            steps={[
              { label: "Register" },
              { label: "Upload A", progress: selectedFiles["t1"].progress },
              { label: "Upload B", progress: selectedFiles["t2"].progress },
              { label: "Launch" },
            ]}
            step={launchStatus}
          />
        )}
      </Box>
    </Box>
  );
}
