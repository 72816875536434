import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useEffect, useState } from "react";
import { FormLabel } from "@mui/material";

export default function DaysPicker(props) {
  const [numSelected, setNumSelected] = useState(0);

  const [days, setDays] = useState({
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  });

  const { handleChildChange } = props;

  const handleChange = (event) => {
    if (!event.target.checked) {
      setDays({
        ...days,
        [event.target.name]: event.target.checked,
      });
      setNumSelected(numSelected - 1);
    } else {
      if (numSelected < props.max) {
        setDays({
          ...days,
          [event.target.name]: event.target.checked,
        });
        setNumSelected(numSelected + 1);
      }
    }
  };

  useEffect(() => {
    if (typeof props.keyName !== "undefined") {
      handleChildChange(props.keyName, days, numSelected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [days, numSelected]);

  return (
    <Box sx={{ display: "flex" }}>
      <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
        <FormLabel component="legend">
          Please select {props.max} {props.max === 1 ? "day" : "days"}
        </FormLabel>
        <FormGroup>
          <Box sx={{ display: "flex" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={days.monday}
                  onChange={handleChange}
                  name="monday"
                />
              }
              label="Monday"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={days.tuesday}
                  onChange={handleChange}
                  name="tuesday"
                />
              }
              label="Tuesday"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={days.wednesday}
                  onChange={handleChange}
                  name="wednesday"
                />
              }
              label="Wednesday"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={days.thursday}
                  onChange={handleChange}
                  name="thursday"
                />
              }
              label="Thursday"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={days.friday}
                  onChange={handleChange}
                  name="friday"
                />
              }
              label="Friday"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={days.saturday}
                  onChange={handleChange}
                  name="saturday"
                />
              }
              label="Saturday"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={days.sunday}
                  onChange={handleChange}
                  name="sunday"
                />
              }
              label="Sunday"
            />
          </Box>
        </FormGroup>
      </FormControl>
    </Box>
  );
}
