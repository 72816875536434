import { Button, Divider, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useRef, useState } from "react";
import SendToApi from "../../api/sendtoapi";
import Calendar from "../calendar/calendar";
import { useSearchParams } from "react-router-dom";
import { TimeField } from "@mui/x-date-pickers";

import dayjs from "dayjs";

export default function Scheduler() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [idEvent, setIdEvent] = useState(false);
  const [daySelected, setDaySelected] = useState(false);
  const timeSelected = useRef(null);
  const [refresh, setRefresh] = useState(false);

  const id = searchParams.get("id");

  timeSelected.current = dayjs("2023-01-01T00:00");

  useEffect(() => {
    if (!id) {
      setIdEvent(false);
      return;
    }
    setIdEvent(id);
  }, [id]);

  function handleOnClick() {
    console.log(idEvent);
    (async () => {
      var values = {};
      values["job"] = idEvent; // job_id would force job token
      var response = await SendToApi("jobs/info", values); // this returns reduced info just for UI
      if (response.status === 200) {
        if (response.data) {
          console.log(response.data.type);
          const details = {
            title: `${response.data.type}`,
            type: `${response.data.type}`,
            parent_job_id: `${idEvent}`,
          };
          values = {};
          values["date"] = daySelected;
          values["details"] = details;
          values["id"] = idEvent;
          values[
            "time"
          ] = `${timeSelected.current.$H}:${timeSelected.current.$m}`;
          response = await SendToApi("events/add", values); // this returns reduced info just for UI
          if (response.status === 200) {
            console.log("all good");
            setIdEvent(false);
            setRefresh(true);
          }
        } else {
        }
      }
    })();
  }

  function IdEvent() {
    return (
      <>
        <Paper>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-between",
              flexDirection: "row",
              alignItems: "center",
              padding: "1rem",
              gap: "1rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                borderWidth: "thin",
                borderStyle: "solid",
                borderColor: "lightgray",
                borderRadius: "5px",
                height: "53px",
                width: "150px",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  position: "relative",
                  fontSize: "12px",
                  color: "gray",
                  fontWeight: "400",
                  top: "-10px",
                  zIndex: "20000",
                  backgroundColor: "white",
                  paddingLeft: "5px",
                  paddingRight: "5px",
                }}
              >
                Event Day
              </Typography>
              <Typography
                sx={{ color: !daySelected ? "lightgray" : "primary" }}
              >
                {!daySelected ? "Click on a day" : daySelected}
              </Typography>
            </Box>

            <TimeField
              label="Event Time"
              value={timeSelected.current}
              onChange={(newValue) => (timeSelected.current = newValue)}
              ampm={false}
            />

            <Button
              disabled={!daySelected}
              onClick={handleOnClick}
              variant="contained"
            >
              Add to Schedule
            </Button>
          </Box>
        </Paper>
      </>
    );
  }

  function handleSelectedDay(day) {
    setDaySelected(day);
    console.log(day);
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        padding: "1rem",
        width: "90%",
      }}
    >
      <Typography color="primary" gutterBottom variant="h6">
        Scheduler
      </Typography>
      <Divider sx={{ marginBottom: "1rem" }} />
      <>
        {idEvent && <IdEvent />}
        <Calendar SelectedDay={handleSelectedDay} refresh={refresh} />
      </>
    </Box>
  );
}
