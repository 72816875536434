import { Box } from "@mui/system";
import { useContext, useEffect, useState } from "react";
import JsonEdit from "../../../components/jsonedit/jsonedit";

import SendToApi from "../../../api/sendtoapi";
import { Divider, Typography } from "@mui/material";
import GlobalStateContext from "../../../globalstate/globalStateContext";

export default function SettingsCard() {
  const [settings, setSettings] = useState(false);
  const global = useContext(GlobalStateContext);

  useEffect(() => {
    //console.log("in effect");
    let cancel = false;
    (async () => {
      var values = {};
      //values.item = "predictscript";
      const response = await SendToApi("ninja/getallsettings", values);
      if (cancel) return;
      if (response.status !== 200) {
        //console.log(response);
      } else {
        //console.log(response.data);
        setSettings(response.data);
      }
    })();

    return () => {
      cancel = true;
    };
  }, []);

  async function onSave(index) {
    const args = {};
    args.value = settings.rows[index].value;
    args.key = settings.rows[index].key;
    const obj = JSON.parse(args.value);
    console.log(obj);
    if (obj._password === "*******") {
      console.log("delete this object");
      delete obj["_password"];
      args.value = JSON.stringify(obj);
    }
    console.log(args);
    const response = await SendToApi("ninja/setsetting", args);
    if (response.status !== 200) {
      //console.log(response);
    } else {
      //console.log("all good");
    }
  }

  function onEdit(edit, i) {
    //console.log(edit);
    //console.log(i);
    var _settings = settings;
    _settings.rows[i].value = edit;
    setSettings({ ..._settings });
  }

  return global.isMember("ninja") ? (
    <>
      <Typography color="primary" gutterBottom variant="h6">
        Account - Settings
      </Typography>
      <Divider sx={{ marginBottom: "1rem" }} />{" "}
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        {settings && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "column",
              alignItems: "left",
              gap: "1rem",
            }}
          >
            {settings.rows.map((row, i) => {
              //console.log(row.value);
              return (
                <JsonEdit
                  key={i}
                  title={row.key}
                  data={row.value}
                  index={i}
                  onEdit={onEdit}
                  onSave={onSave}
                />
              );
            })}
          </Box>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "row",
          alignItems: "center",
          gap: "1rem",
        }}
      ></Box>
    </>
  ) : (
    <></>
  );
}

/*

{settings && (
    <CTForm
      submitText="Save changes"
      handleSubmit={handleSaveSettings}
      layout={layout.settings}
      initialValuesSupplied={settings}
    />
  )}

*/
