import {
  Autocomplete,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useField } from "formik";
import { useEffect, useState } from "react";
import { FetchAllValues } from "../../database/database";
import { getWidthOfText, displayTextWidth } from "../../../utils/utils";

const FormSelect = ({ returnLabel, dbField, name, label, id, ...props }) => {
  const [field, meta, helpers] = useField(name); // can pass 'props' into useField also, if 'props' contains a name attribute
  const { setTouched, setError, setValue } = helpers; // setValue was here
  const [options, setOptions] = useState(false);
  const [autoValue, setAutoValue] = useState(false);
  const [width, setWidth] = useState(0);

  const handleChange = (option) => {
    console.log(option);
    if (option) {
      setValue(option[returnLabel]);
    } else {
      setValue(options[0]);
    }
    setAutoValue(option);
    setTouched(true);
    setError("");
  };

  useEffect(() => {
    Fetch();
  }, []);

  useEffect(() => {
    if (!options) return;
    setAutoValue(options[0]);
  }, [options]);

  async function Fetch() {
    console.log(dbField);
    const data = await FetchAllValues(dbField);
    var selectData = [];
    if (data) {
      var longest = "";
      data.forEach((d, index) => {
        var obj = {};
        obj.value = d.id;
        obj.label = d.label;
        obj.count = d.count;
        selectData[index] = obj;
        if (d.label.length > longest.length) {
          longest = d.label;
        }
      });

      console.log(longest);
      setWidth(displayTextWidth(longest, "normal 16pt Roboto"));
      selectData.unshift({ value: "0", label: "Please Select a " + label });
      setOptions(selectData);
    }
  }

  return (
    <>
      <Box sx={{ marginBottom: "2rem" }}>
        {options && (
          <>
            <Autocomplete
              getOptionLabel={(option) => option.label || ""}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              value={autoValue}
              onChange={(event, newValue) => {
                handleChange(newValue);
              }}
              id="controllable-states-demo"
              options={options}
              sx={{ width: width }}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {option.count && `[#${option.count}] ${option.label}`}
                </Box>
              )}
              renderInput={(params) => {
                return (
                  <>
                    <TextField {...params} label={label} />
                  </>
                );
              }}
            />
            <FormHelperText sx={{ color: "red" }}>
              {meta.touched && !meta.value && meta.error}
            </FormHelperText>
          </>
        )}
      </Box>
    </>
  );
};

export default FormSelect;
/*
<pre>{JSON.stringify(props, null, 2)}</pre>
      <pre>{meta.value}</pre>
      <pre>{field.value}</pre>
      */
// ref https://codesandbox.io/s/6x9405rlqk?file=/example.js // for styles and placeholder
