import { Typography } from "@mui/material";
import { useContext } from "react";
import GlobalStateContext from "../globalstate/globalStateContext";

export function NinjaText(props) {
  const { text } = props;

  const global = useContext(GlobalStateContext);

  return global.userIs("ninja") ? (
    <Typography
      sx={{
        backgroundColor: "#000000",
        color: "#ffffff",
        padding: "0.2rem",
        borderRadius: "3px",
      }}
    >
      {text}
    </Typography>
  ) : (
    <></>
  );
}
