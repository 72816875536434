import MenuItem from "./menuitem.js";
import { Divider, List } from "@mui/material";
import { Box } from "@mui/system";
import { Fragment, useEffect, useState } from "react";
import { commonMenuItems } from "./common.js";
import CTDLogo from "../components/logo/logo.js";

export default function Menu(props) {
  const { menuItems } = props;

  const [selected, setSelected] = useState(0);
  const [selectedCommon, setSelectedCommon] = useState(0);

  var pathname = window.location.pathname;
  pathname = pathname.slice(pathname.lastIndexOf("/") + 1);

  useEffect(() => {
    Object.keys(menuItems).forEach((item) => {
      if (pathname === menuItems[item].link) {
        setSelected(parseInt(item));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleClick(index) {
    setSelected(index);
    setSelectedCommon(-1);
  }
  function handleClickCommon(index) {
    setSelectedCommon(index);
    setSelected(-1);
  }

  return (
    <Box
      sx={{
        paddingTop: "64px",
        position: "fixed",
        width: "240px",
        height: "100%",
        borderRight: "thin lightgray solid",
      }}
    >
      <CTDLogo />
      <Divider />
      <List component="div">
        {menuItems.map((item, index) => {
          return (
            <Fragment key={index + 1}>
              {item.name !== "divider" ? (
                <MenuItem
                  {...item}
                  key={index + 1}
                  keyValue={index + 1}
                  onSelect={handleClick}
                  selected={selected}
                />
              ) : (
                <Divider key={index + 1} />
              )}
            </Fragment>
          );
        })}
        {props.common &&
          commonMenuItems.map((item, i) => {
            return (
              <Fragment key={i + 1}>
                {item.name !== "divider" ? (
                  <MenuItem
                    {...item}
                    key={i + 1}
                    keyValue={i + 1}
                    onSelect={handleClickCommon}
                    selected={selectedCommon}
                  />
                ) : (
                  <Divider key={i + 1} />
                )}
              </Fragment>
            );
          })}
      </List>
    </Box>
  );
}
