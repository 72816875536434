import globalStateContext from "../../globalstate/globalStateContext";
import React, { useContext, useEffect, useState } from "react";

import Typography from "@mui/material/Typography";
import ColourPicker from "../../components/colourpicker/colourpicker";
import FileUpload from "../../components/fileupload/fileupload";
import SendToApi from "../../api/sendtoapi";
import { FileUploadToAPI } from "../../api/sendtoapi";
import CTForm from "../../components/forms/ctform";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  useMediaQuery,
  Dialog,
  Alert,
  AlertTitle,
} from "@mui/material";

import { ExpandMore, ChevronRight } from "@mui/icons-material";
import ApiDataGrid from "../../components/apidatagrid/apidatagrid";
import { useTheme } from "@emotion/react";
import AddUserDialog from "./adduserdialog";
import layout from "./layout.json";
import CTTreeView from "../../components/cttreeview/cttreeview";
import SettingsCard from "./settings/settings";
import MissionControlCard from "./missoncontrol/missioncontrol";
import ActionsCard from "./actions/actions";
import { Route, Routes } from "react-router-dom";
import { ProtectedUserRoute } from "../../auth/protectedroute";

import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";

export default function ManageAccount() {
  const global = useContext(globalStateContext);
  const theme = useTheme();

  const [addUser, setAddUser] = useState(false);
  const [logoUrl, setLogoUrl] = useState(false);
  const [pathname, setPathName] = useState(false);

  useEffect(() => {
    setPathName(window.location.pathname.split("/").pop());
  }, []);

  const labelId = `checkbox-list-label-1`;

  const fullScreen = useMediaQuery(theme.breakpoints.down("sd"));

  const handleDialog = (setValue, value) => {
    setValue(value);
  };

  const CurrentUserCard = () => {
    const [tfa, settfa] = useState(false);
    const [tfaSuccess, setTFASuccess] = useState(false);

    useEffect(() => {
      settfa(global.userInfo.two_factor === 1 ? true : false);
    }, []);

    function handle2faToggle() {
      setTFASuccess(false);
      settfa(!tfa);
    }

    async function handleUpdate() {
      var values = {};
      values["data"] = {};
      values["data"]["two_factor"] = tfa;
      const response = await SendToApi("users/setinfo", values);
      setTFASuccess(true);
    }

    return (
      <>
        <Typography color="primary" gutterBottom variant="h6">
          Account - User
        </Typography>
        <Divider sx={{ marginBottom: "1rem" }} />{" "}
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Button
            sx={{ marginTop: "1rem" }}
            disableRipple
            variant="contained"
            component="label"
            color="primary"
            size="medium"
            onClick={handleUpdate}
          >
            Save
          </Button>
        </Box>
        <Divider sx={{ marginTop: "1rem", marginBottom: "1rem" }} />
        <List
          sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
        >
          <ListItem key="1" disablePadding>
            <ListItemButton role={undefined} onClick={handle2faToggle} dense>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={tfa}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ "aria-labelledby": labelId }}
                />
              </ListItemIcon>
              <ListItemText
                id={labelId}
                primary="Two Factor Authentication Enabled"
              />
            </ListItemButton>
          </ListItem>
          {tfa && (
            <ListItem key="2" disablePadding>
              <img
                src={global.userInfo.qr_url}
                style={{
                  width: "150px",
                  height: "100%",
                  objectFit: "contain",
                }}
                alt="logo"
              />
            </ListItem>
          )}
        </List>
        {tfaSuccess && (
          <Alert severity="success">
            <AlertTitle>Success</AlertTitle>You have set your tfa.
          </Alert>
        )}
        <Divider sx={{ marginTop: "1rem", marginBottom: "1rem" }} />
      </>
    );
  };

  function fileStatus(status, type) {
    switch (status.data.state) {
      case "uploaded":
        switch (type) {
          case "logo":
            //console.log(status);
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
  }

  function handleSelect(file, type, size) {
    FileUploadToAPI(fileStatus, file, "logo", 0, "accountfile");
    // TODO check if its
    //console.log(URL.createObjectURL(file));
    setLogoUrl(URL.createObjectURL(file));

    global.setUserInfo((prevState) => ({
      ...prevState,
      logoUrl: URL.createObjectURL(file),
      logoName: file["name"],
    }));
  }

  const BrandingCard = () => {
    return (
      <>
        <Typography color="primary" gutterBottom variant="h6">
          Account - Branding
        </Typography>
        <Divider sx={{ marginBottom: "1rem" }} />{" "}
        <FileUpload
          title="Logo upload"
          options="accountfile"
          type="logo"
          show="true"
          jobId="0"
          avatar={false}
          onSelect={handleSelect}
        />
        {global.userInfo.logoUrl && (
          <Box sx={{}}>
            <img
              alt="logo"
              width="100px"
              height="100px"
              style={{
                marginTop: "2rem",
                objectFit: "contain",
                border: "solid thin purple",
              }}
              src={global.userInfo.logoUrl}
            />

            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              {global.userInfo.logoName}
            </Typography>
          </Box>
        )}
        <Divider sx={{ marginTop: "1rem", marginBottom: "1rem" }} />
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Primary Colour
        </Typography>
        <ColourPicker id="colour1" />
        <Divider sx={{ marginTop: "1rem", marginBottom: "1rem" }} />
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Secondary Colour
        </Typography>
        <ColourPicker id="colour2" />
      </>
    );
  };

  function UsersCard(props) {
    const { handleDialog, setAddUser } = props;
    const columns = [
      {
        field: "id",
        table: "users",
      },
      {
        field: "username",
        headerName: "Username",
        width: 200,
        table: "users",
        editable: false,
      },
      {
        field: "permissions",
        headerName: "Permissions",
        width: 200,
        table: "users",
        editable: true,
      },
      {
        field: "_registered",
        headerName: "Registered",
        width: 100,
        table: "users",
        renderCell: (params) => {
          return (
            <>{params.value === "1" ? <DoneIcon color="primary" /> : "no"}</>
          );
        },
        editable: false,
      },
    ];

    return (
      <>
        <Typography color="primary" gutterBottom variant="h6">
          Account - Users
        </Typography>
        <Divider sx={{ marginBottom: "1rem" }} />{" "}
        <Box
          sx={{
            display: "flex",
            gap: "1rem",
            flexDirection: "column",
          }}
        >
          <Button
            variant="contained"
            component="label"
            color="primary"
            size="medium"
            onClick={() => handleDialog(setAddUser, true)}
            sx={{ width: "200px" }}
          >
            Add
          </Button>

          <ApiDataGrid
            initialValues={{ table: "_accounts" }}
            fetchURL="accounts/users"
            saveURL="users/setinfo"
            deleteURL="accounts/deleteuser"
            entity="user"
            columns={columns}
          />

          <Divider />
        </Box>
      </>
    );
  }

  return (
    
      <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      gap:"1rem",
      padding: "1rem",
      width:"90%"
    }}
  >   
          <Routes>
            <Route
              path="user"
              element={<CurrentUserCard title={global.userInfo.username} />}
            />
            <Route
              path="branding"
              element={
                <ProtectedUserRoute userType="admin">
                  <BrandingCard />
                </ProtectedUserRoute>
              }
            />
            <Route
              path="users"
              element={
                <ProtectedUserRoute userType="admin">
                  <UsersCard
                    title="Users"
                    handleDialog={handleDialog}
                    setAddUser={setAddUser}
                  />
                </ProtectedUserRoute>
              }
            />
            <Route
              path="actions"
              element={
                <ProtectedUserRoute userType="admin">
                  <ActionsCard />
                </ProtectedUserRoute>
              }
            />
            <Route
              path="settings"
              element={
                <ProtectedUserRoute userType="admin">
                  <SettingsCard />
                </ProtectedUserRoute>
              }
            />
            <Route
              path="missioncontrol"
              element={
                <ProtectedUserRoute userType="admin">
                  <MissionControlCard />
                </ProtectedUserRoute>
              }
            />
          </Routes>
          <Dialog
        fullScreen={fullScreen}
        open={addUser}
        onClose={() => handleDialog(setAddUser, false)}
        aria-labelledby="responsive-dialog-title"
      >
        <AddUserDialog handleClose={() => handleDialog(setAddUser, false)} />
      </Dialog>
    
      </Box>
    
  );
}
