import { FormControl, FormLabel, Slider } from "@mui/material";

import { useFormikContext } from "formik";
import { useEffect } from "react";

export default function FormSlider(props) {
  const { width, name, label, marks, min, max, step } = props;
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    setFieldValue(name, props.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleChange = (evt) => {
    props.setValue({ ...props.value, [name]: evt.target.value });
    setFieldValue(name, evt.target.value);
  };

  return (
    <FormControl>
      <FormLabel id="demo-radio-buttons-group-label">{label}</FormLabel>
      <Slider
        sx={{
          width: { width },
        }}
        value={props.value}
        name="slider"
        aria-label="slider"
        onChange={(event) => handleChange(event)}
        min={min}
        max={max}
        step={step}
        marks={marks}
      />
    </FormControl>
  );
}
