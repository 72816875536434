import { Button } from "@mui/material";
import { Box } from "@mui/system";

import SendToApi from "../../../api/sendtoapi";

export default function ActionsCard() {
  async function handleBuildClickTables() {
    var values = {};

    const response = await SendToApi("predict/buildclicktables", values); // this tells the server to make the click tables - it does not return anything
    if (response.status === 200) {
      //console.log("new data retrieved");
    }
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: "row",
        alignItems: "center",
        gap: "1rem",
      }}
    >
      <Button
        variant="contained"
        color="primary"
        onClick={handleBuildClickTables}
      >
        Build click tables
      </Button>
    </Box>
  );
}
