import { Box, Divider, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { Navigate } from "react-router-dom";
import CTForm from "../../components/forms/ctform";
import RealStateContext from "../../wss/realState/realStateContext";

import layout from "./layout.json";
import SendToApi from "../../api/sendtoapi";

export default function SubjectHeroLaunch() {
  const [launchStatus, setLaunchStatus] = useState(false);
  const [formValues, setFormValues] = useState({});

  const rt = useContext(RealStateContext);

  async function onRegister() {
    // TODO get a job id from the api
    //console.log("register");
    var values = {};

    values.data_info = JSON.stringify(formValues);
    values.type = "subjecthero";

    var response = await SendToApi("jobs/register", values);
    console.log(response);
    if (response.status === 200) {
      var initialState = { state: "launched" };
      rt.setRealTime({
        ...rt.realTime,
        [response.data]: initialState,
      });
      return response.data;
    }
    return false;
  }

  async function handleSubmit(_values) {
    //console.log(_values);
    var values = {};
    values.data_info = JSON.stringify(_values);
    console.log(values);
    const job_id = await onRegister();
    if (job_id) {
      values.jobid = job_id;
      var response = await SendToApi("scripts/launch", values);
      if (response.status === 200) {
        setLaunchStatus(true);
      } else {
      }
    }
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        padding: "1rem",
        width: "90%",
      }}
    >
      {launchStatus && <Navigate replace to="/subjecthero/dashboard" />}
      <Typography color="primary" gutterBottom variant="h6">
        Activate Tribes
      </Typography>
      <Divider sx={{ marginBottom: "1rem" }} />
      <Box
        sx={{
          display: "flex",
          gap: "4rem",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "4rem",
            flexDirection: "row",
          }}
        >
          {" "}
          <CTForm
            submitText="Launch"
            handleSubmit={(values) => {
              handleSubmit(values);
            }}
            layout={layout.subjecthero}
            handleFormValues={(values) => {
              setFormValues(values);
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}
