import { useContext, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import CTDAppBar from "../../appbar/appbar";
import CTDLogo from "../../components/logo/logo";
import Drawer from "@mui/material/Drawer";
import Menu from "../../menu/menu";
import { Box } from "@mui/system";
import { useTheme } from "@emotion/react";
import GlobalStateContext from "../../globalstate/globalStateContext";

// content
import Dashboard from "../../components/dashboard/dashboard";
import ProtectedRoute from "../../auth/protectedroute";
import Selector from "./selector";
import Labeller from "./labeller";
import Insights from "./insights";
// Icons
// Icons

import LocalOfferIcon from "@mui/icons-material/LocalOffer";

import BallotIcon from "@mui/icons-material/Ballot";

import InsightsIcon from "@mui/icons-material/Insights";
import { CommonRoutes } from "../../menu/common";

const menuItems = [
  {
    name: "Selector",
    Icon: BallotIcon,
    link: "selector",
  },
  {
    name: "Labeller",
    link: "labeller",
    Icon: LocalOfferIcon,
  },
  {
    name: "Insights",
    link: "insights",
    Icon: InsightsIcon,
  },
];

export default function Predict() {
  const theme = useTheme();
  const global = useContext(GlobalStateContext);

  useEffect(() => {
    global.setState((prevState) => ({
      ...prevState,
      application: "predict",
    }));
  }, []);

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <CTDAppBar />
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Box
          sx={{
            width: "256px",
          }}
        >
          <Menu menuItems={menuItems} common />
        </Box>

        <Box
          sx={{
            marginTop: "64px",
            display: "flex",
            width: "80%",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          {CommonRoutes}
          <Routes>
            <Route
              path="/"
              exact
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="selector"
              exact
              element={
                <ProtectedRoute>
                  <Selector />
                </ProtectedRoute>
              }
            />
            <Route
              path="labeller"
              exact
              element={
                <ProtectedRoute>
                  <Labeller />
                </ProtectedRoute>
              }
            />
            <Route
              path="insights"
              exact
              element={
                <ProtectedRoute>
                  <Insights />
                </ProtectedRoute>
              }
            />
          </Routes>
        </Box>
      </Box>
    </Box>
  );
}
