import {
  Button,
  Divider,
  IconButton,
  Modal,
  Paper,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";

import { useContext, useEffect, useState } from "react";
import SendToApi, { SendFileRequestToApi } from "../../api/sendtoapi";

import { DataGrid } from "@mui/x-data-grid";
import DownloadIcon from "@mui/icons-material/Download";
import ScheduleIcon from "@mui/icons-material/Schedule";
import ListAltIcon from "@mui/icons-material/ListAlt";
import VisibilityIcon from "@mui/icons-material/Visibility";

// reports
import UpliftReport from "../reports/uplift";
import PredictReport from "../reports/predict";
import ScoreReport from "../reports/score";
import TribesReport from "../reports/tribes";
import CTModalSimple from "../modal/ctmodalsimple";
import { useNavigate } from "react-router-dom";
import GlobalStateContext from "../../globalstate/globalStateContext";

function getDate(params) {
  const d = new Date(params.row.status.time);
  return d;
  //return d.toUTCString().slice(0, -4); // lose the GMT
}

function getName(params) {
  ////console.log(params.row);
  if (params.row.type === "tribesdemo" || params.row.type === "tribes") {
    const obj = params.row.update;
    if (obj) {
      return `${obj.job_name}`;
    }
  }
  if (params.row.job_name === null) {
    return `(${params.row.id})`;
  } else {
    return params.row.job_name;
  }
}

export default function Outputs() {
  const [open, setOpen] = useState(false);
  const [row, setRow] = useState(false);
  const [rows, setRows] = useState();
  const [selectJob, setSelectJob] = useState(false);
  const [rowsSorted, setRowsSorted] = useState(false);

  const global = useContext(GlobalStateContext);

  const navigate = useNavigate();

  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "job_name",
      headerName: "Name",
      flex: 1,
      minWidth: 170,
      editable: false,
      valueGetter: getName,
    },
    {
      field: "type",
      headerName: "Type",
      flex: 0.5,
      minWidth: 70,
      editable: false,
    },
    {
      field: "view",
      headerName: "View",
      minWidth: 70,
      editable: false,
      renderCell: (params) => {
        return (
          <IconButton
            onClick={() => handleView(params)}
            color="primary"
            aria-label="view report"
          >
            <VisibilityIcon />
          </IconButton>
        );
      },
    },

    {
      field: "report",
      headerName: "Report",
      minWidth: 70,
      editable: false,
      renderCell: (params) => {
        const status = params.row.status;
        return (
          <IconButton
            disabled={typeof status.report !== "string"}
            onClick={() => onClick("report", params)}
            color="primary"
            aria-label="download report"
          >
            <DownloadIcon />
          </IconButton>
        );
      },
    },
    {
      field: "score",
      headerName: "Audience",
      minWidth: 70,
      editable: false,
      renderCell: (params) => {
        const status = params.row.status;
        return (
          <IconButton
            disabled={typeof status.audience !== "string"}
            onClick={() => onClick("score", params)}
            color="primary"
            aria-label="download audience"
          >
            <DownloadIcon />
          </IconButton>
        );
      },
    },
    {
      field: "crm",
      headerName: "CRM",
      minWidth: 70,
      editable: false,
      renderCell: (params) => {
        const status = params.row.status;
        return (
          <IconButton
            disabled={typeof status.audience !== "string"}
            onClick={() => onClick("crm", params)}
            color="primary"
            aria-label="crm"
          >
            <ListAltIcon />
          </IconButton>
        );
      },
    },
    {
      field: "schedule",
      headerName: "Schedule",
      minWidth: 70,
      flex: 1,
      editable: false,
      renderCell: (params) => {
        //console.log(params.row.type);
        return (
          <>
            {params.row.type === "predict" || params.row.type === "dummy" ? (
              <IconButton
                onClick={() => onClick("schedule", params)}
                color="primary"
                aria-label="schedule"
              >
                <ScheduleIcon />
              </IconButton>
            ) : (
              <></>
            )}
          </>
        );
      },
    },
    {
      field: "date",
      headerName: "Date completed",

      minWidth: 200,
      editable: false,
      type: "dateTime",
      valueGetter: getDate,
    },
  ];

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    if (params.get("jobid")) {
      setSelectJob(params.get("jobid"));
    }
  }, []);

  useEffect(() => {
    if (!selectJob) return;
    if (!rows) return;
    if (rowsSorted) return;
    var _rows = rows.slice();
    _rows.sort(function (x, y) {
      return x.id === selectJob ? -1 : y.id === selectJob ? 1 : 0;
    });
    setRows(_rows);
    setRowsSorted(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectJob, rows]);

  useEffect(() => {
    const fetchCompleteData = async () => {
      const values = {};
      values.filterName = "status->>'state'";
      values.filterValue = "completed";
      values.orderby = "status->>'time'";

      const response = await SendToApi("jobs/getjobs", values);

      //console.log(response);
      if (response.status !== 200) {
        //console.log("no 200");
        //console.log(response.data);
        return;
      }
      if (!response.data.rows) {
        //console.log("no rows");
        return;
      }
      console.log(response.data.rows);
      setRows(response.data.rows);
    };
    fetchCompleteData();
  }, []);

  function handleView(params) {
    //console.log(params);
    setOpen(true);
    setRow(params.row);
  }
  function handleClose() {
    setOpen(false);
    setRow(false);
  }
  async function onClick(type, params) {
    //console.log(params.row);

    const path = process.env.REACT_APP_USERFILES;
    var filename;
    switch (type) {
      case "report":
        filename = process.env.REACT_APP_REPORT_FILE;
        break;
      case "score":
        filename = process.env.REACT_APP_SCORE_FILE;
        break;
      case "crm":
        filename = process.env.REACT_APP_SCORE_FILE;
        const account_id = sessionStorage.getItem("account_id");
        const user_id = sessionStorage.getItem("user_id");
        const fullFileName = `${path}${account_id}/${user_id}/${params.row.id}/${filename}`;

        const values = {};
        values.fullfilename = fullFileName;
        values.jobid = params.row.id;
        const response = await SendToApi("sftp/sendfile", values);
        console.log(response);

        break;
      case "schedule":
        console.log(params.id);
        console.log(global.state.application);
        navigate(`/${global.state.application}/schedule?id=${params.id}`);
        // reroute to
        break;
      default:
        break;
    }
    switch (type) {
      case "view":
        console.log(`view ${params.row.id} ${params.row.type} in modal`);
        break;
      case "report":
      case "score":
        const account_id = sessionStorage.getItem("account_id");
        const user_id = sessionStorage.getItem("user_id");
        const fullFileName = `${path}${account_id}/${user_id}/${params.row.id}/${filename}`;

        console.log(fullFileName);

        var values = {};
        values["filename"] = fullFileName;

        const result = await SendFileRequestToApi("files/content", values);

        if (!result.blob) {
          console.log("file has not been returned");
          return;
        }

        var url = URL.createObjectURL(result.blob);
        console.log(url);

        const link = document.createElement("a");
        // create a blobURI pointing to our Blob
        link.href = url;
        link.download = `${params.row.id}-${filename}`;
        // some browser needs the anchor to be in the doc
        document.body.append(link);
        link.click();
        link.remove();
        // in case the Blob uses a lot of memory
        setTimeout(() => URL.revokeObjectURL(link.href), 7000);
        break;

      default:
        break;
    }
  }
  const SelectReport = ({ type }) => {
    switch (type) {
      case "uplift":
        return <UpliftReport data={row} />;

      case "score":
        return <ScoreReport data={row} />;

      case "predict":
        return <PredictReport data={row} />;

      case "tribesdemo":
      case "tribes":
        return <TribesReport data={row} />;

      default:
        break;
    }
    return (
      <>
        <h1>unknown {type}</h1>
      </>
    );
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        padding: "1rem",
        width: "90%",
      }}
    >
      <Typography color="primary" gutterBottom variant="h6">
        Outputs
      </Typography>
      <Divider sx={{ marginBottom: "1rem" }} />
      <Box>
        {rows && (
          <DataGrid
            columnVisibilityModel={{
              // Hide id colum
              id: false,
            }}
            autoHeight
            rows={rows}
            columns={columns}
            //checkboxSelection={selectJob ? true : false}
            disableSelectionOnClick
            selectionModel={selectJob ? selectJob : 1}
          />
        )}
      </Box>
      <Divider sx={{ marginBottom: "1rem" }} />
      <CTModalSimple open={open} handleClose={handleClose}>
        {row && (
          <>
            <SelectReport type={row.type} />
          </>
        )}
      </CTModalSimple>
    </Box>
  );
}
