import { Routes, Route } from "react-router-dom";

import CTDAppBar from "../../appbar/appbar";

import Menu from "../../menu/menu";
import { Box } from "@mui/system";

import GlobalStateContext from "../../globalstate/globalStateContext";

// content
import Dashboard from "../../components/dashboard/dashboard";
import NewJobManual from "./newjob-manual";
import NewJobAuto from "./newjob-auto";
import Scores from "./scores";
import ProtectedRoute from "../../auth/protectedroute";
import { useContext, useEffect } from "react";
// Icons
// Icons

import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";

import FilePresentIcon from "@mui/icons-material/FilePresent";

import { CommonRoutes } from "../../menu/common";

const menuItems = [
  {
    Icon: FilePresentIcon,
    name: "Models",
    items: [
      {
        link: "newjob_manual",
        name: "Manual",
      },
      {
        link: "newjob_auto",
        name: "Automatic",
      },
    ],
  },
  {
    name: "Scores",
    link: "scores",
    Icon: FormatListNumberedIcon,
  },
];

export default function Uplift() {
  const global = useContext(GlobalStateContext);

  useEffect(() => {
    global.setState((prevState) => ({
      ...prevState,
      application: "uplift",
    }));
  }, []);

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <CTDAppBar />
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Box
          sx={{
            width: "250px",
          }}
        >
          <Menu menuItems={menuItems} common />
        </Box>

        <Box
          sx={{
            marginTop: "64px",
            display: "flex",
            width: "80%",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          {CommonRoutes}
          <Routes>
            <Route
              path="/"
              exact
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="newjob_manual"
              exact
              element={
                <ProtectedRoute>
                  <NewJobManual />
                </ProtectedRoute>
              }
            />
            <Route
              path="newjob_auto"
              exact
              element={
                <ProtectedRoute>
                  <NewJobAuto />
                </ProtectedRoute>
              }
            />
            <Route
              path="scores"
              exact
              element={
                <ProtectedRoute>
                  <Scores />
                </ProtectedRoute>
              }
            />
          </Routes>
        </Box>
      </Box>
    </Box>
  );
}
