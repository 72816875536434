import {
  Container,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";

import CancelIcon from "@mui/icons-material/Cancel";
import ApiIcon from '@mui/icons-material/Api';
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import TimelineIcon from "@mui/icons-material/Timeline";
import MonitorIcon from '@mui/icons-material/Monitor';
import { Divider } from "@mui/material";

import { Box } from "@mui/system";
import { useContext, useEffect, useState } from "react";
import SendToApi from "../../api/sendtoapi";
import globalStateContext from "../../globalstate/globalStateContext";

export default function SettingsDialog(props) {
  const [apiConnected, setApiConnected] = useState(false);
  const [apiVersion, setAPIVersion] = useState(false);
  const global = useContext(globalStateContext);

  useEffect(() => {
    (async () => {
      var values = {};

      const response = await SendToApi("admin/ping", values);
      if (response.status !== 200) {
        setApiConnected(false);
      } else {
        setAPIVersion(response.data.version);
        setApiConnected(true);
      }
    })();

    return () => {};
  }, []);

  return (
    <Box sx={{ padding: "1rem" }}>
      <DialogTitle color="primary" id="responsive-dialog-title">
        Settings
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <ApiIcon color={apiConnected ? "success" : "error"} />
            </Grid>
            <Grid item xs={6}>
              <Typography color={apiConnected ? "primary" : "error"}>
                API v {apiVersion && apiVersion}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <VerifiedUserIcon
                color={global.userInfo.loggedIn ? "success" : "error"}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography
                color={global.userInfo.loggedIn ? "primary" : "error"}
              >
                Verified User
              </Typography>
              <Typography
                color={global.userInfo.loggedIn ? "primary" : "error"}
              >
                {global.userInfo.username}
              </Typography>
              <Typography
                color={global.userInfo.loggedIn ? "primary" : "error"}
              >
                {global.userInfo.account_name}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <TimelineIcon
                color={global.state.wssConnected ? "success" : "error"}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography
                color={global.state.wssConnected ? "primary" : "error"}
              >
                WSS v {global.state.wssVersion && global.state.wssVersion}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <MonitorIcon color="success" />
            </Grid>
            <Grid item xs={6}>
              <Typography color="primary">Front End v 0.81</Typography>
            </Grid>
          </Grid>
        </Container>
      </DialogContent>
      <DialogActions>
        <IconButton aria-label="delete" onClick={props.handleClose}>
          <CancelIcon />
        </IconButton>
      </DialogActions>
    </Box>
  );
}

<Typography
                style={{ fontSize: "10px" }}
                component="div"
                color={
                  process.env.REACT_APP_CUSTOM_NODE_ENV === "production"
                    ? "primary"
                    : "secondary"
                }
              >
                v0.75
              </Typography>