import StorageIcon from "@mui/icons-material/Storage";
import IconDashboard from "@mui/icons-material/Dashboard";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Route, Routes } from "react-router-dom";
import ProtectedRoute from "../auth/protectedroute";
import Dashboard from "../components/dashboard/dashboard";
import Outputs from "../components/outputs/outputs";
import Scheduler from "../components/scheduler/scheduler";
import ManageAccount from "../pages/manageaccounts/manageaccount";

export const commonMenuItems = [
  {
    name: "divider",
  },

  {
    name: "Dashboard",
    link: "dashboard",
    Icon: IconDashboard,
  },
  {
    name: "Outputs",
    link: "outputs",
    Icon: StorageIcon,
  },
  {
    name: "Schedule",
    link: "schedule",
    Icon: CalendarMonthIcon,
  },
  {
    name: "divider",
  },
  {
    name: "Account",
    //link: "account",
    Icon: ManageAccountsIcon,
    items: [
      {
        name: "User",
        link: "account/user",
      },
      {
        name: "Branding",
        link: "account/branding",
        userProtected: "admin",
      },
      {
        name: "Actions",
        link: "account/actions",
        userProtected: "admin",
      },
      {
        name: "Account Users",
        link: "account/users",
        userProtected: "admin",
      },
      {
        name: "Settings",
        link: "account/settings",
        userProtected: "ninja",
      },
      {
        name: "Mission Control",
        link: "account/missioncontrol",
        userProtected: "admin",
      }
    ],
  },
];

export const CommonRoutes = (
  <Routes>
    <Route
      path="dashboard"
      element={
        <ProtectedRoute>
          <Dashboard />
        </ProtectedRoute>
      }
    />
    <Route
      path="outputs"
      exact
      element={
        <ProtectedRoute>
          <Outputs />
        </ProtectedRoute>
      }
    />
    <Route
      path="schedule"
      exact
      element={
        <ProtectedRoute>
          <Scheduler />
        </ProtectedRoute>
      }
    />
    <Route
      path="account/*"
      exact
      element={
        <ProtectedRoute>
          <ManageAccount />
        </ProtectedRoute>
      }
    />
  </Routes>
);
