import { DialogActions, DialogContent, IconButton } from "@mui/material";

import CancelIcon from "@mui/icons-material/Cancel";

import { Box } from "@mui/system";

import SendToApi from "../../api/sendtoapi";

import { useEffect, useState } from "react";

export default function TaskDialog(props) {
  useEffect(() => {
    //console.log("in effect");
    return;
    let cancel = false;
    (async () => {
      var values = {};
      //values.item = "predictscript";
      const response = await SendToApi("ninja/getapienvsettings", values);
      if (cancel) return;
      if (response.status !== 200) {
        //console.log(response);
      } else {
        //console.log(JSON.stringify(response.data));
      }
    })();

    return () => {
      cancel = true;
    };
  }, []);

  return (
    <div>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "1rem",
            marginTop: "50px",
            gap: "1rem",
          }}
        ></Box>
      </DialogContent>
      <DialogActions>
        <IconButton aria-label="delete" onClick={props.handleClose}>
          <CancelIcon />
        </IconButton>
      </DialogActions>
    </div>
  );
}
