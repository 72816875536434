import { Routes, Route } from "react-router-dom";
import ProtectedRoute from "./auth/protectedroute";
import { BrowserRouter as Router } from "react-router-dom";
// pages
import Uplift from "./pages/uplift/uplift";
import Predict from "./pages/predict/predict";
import Login from "./pages/login/login";
import Register from "./pages/register/register";
import Landing from "./pages/landing/landing";
import Forgot from "./pages/forgot/forgot";
import PWReset from "./pages/pwreset/pwreset";
import Tribes from "./pages/tribes/tribes";
import SubjectHero from "./pages/subjecthero/subjecthero";

export default function App() {
  const entryURL = window.location.pathname + window.location.search;

  window.sessionStorage.setItem("entryURL", entryURL);
  return (
    <>
      <Router>
        <>
          <Routes>
            <Route
              path="/uplift/*"
              element={
                <ProtectedRoute>
                  <Uplift />
                </ProtectedRoute>
              }
            />
            <Route
              path="/predict/*"
              element={
                <ProtectedRoute>
                  <Predict />
                </ProtectedRoute>
              }
            />
            <Route
              path="/tribes/*"
              element={
                <ProtectedRoute>
                  <Tribes />
                </ProtectedRoute>
              }
            />
            <Route
              path="/subjecthero/*"
              element={
                <ProtectedRoute>
                  <SubjectHero />
                </ProtectedRoute>
              }
            />
            <Route
              path="/tribesdemo/*"
              element={
                <ProtectedRoute>
                  <Tribes />
                </ProtectedRoute>
              }
            />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/forgot" element={<Forgot />} />
            <Route path="/passwordreset" element={<PWReset />} />
            <Route
              path="/landing"
              element={
                <ProtectedRoute>
                  <Landing />
                </ProtectedRoute>
              }
            />
            <Route path="/" element={<Login />} />
          </Routes>
        </>
      </Router>
    </>
  );
}
//https://ui.dev/react-router-nested-routes/
/*
 */
