import React from "react";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import StateProvider from "./globalstate/globalStateProvider";
import RealStateProvider from "./wss/realState/realStateProvider";
import { createTheme } from "@mui/material";
import { purple, blue, green, blueGrey } from "@mui/material/colors";
import { ThemeProvider } from "@mui/system";
import { createRoot } from "react-dom/client";
import { ConfirmationServiceProvider } from "./providers/ConfirmationService";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const theme = createTheme({
  palette: {
    primary: purple,
    secondary: blue,
    success: green,
    ninja: blueGrey[900],
  },
  typography: {
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
});
const container = document.getElementById("app");
const root = createRoot(container);

root.render(
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <StateProvider>
      <RealStateProvider>
        <ThemeProvider theme={theme}>
          <ConfirmationServiceProvider>
            <App />
          </ConfirmationServiceProvider>
        </ThemeProvider>
      </RealStateProvider>
    </StateProvider>
  </LocalizationProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
