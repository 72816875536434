import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import CancelIcon from "@mui/icons-material/Cancel";
import { IconButton } from "@mui/material";

export default function CTModal({
  xOffset,
  yOffset,
  open,
  setOpen,
  handleClose,
  handleSubmit,
  children,
  position,
  info,
}) {
  const [modalXY, setModalXY] = useState(false);
  const [mousePos, setMousePos] = useState({});
  const [windowDimensions, setWindowDimensions] = useState({});

  // set window dimensions for screen size if not resized
  useEffect(() => {
    setWindowDimensions(getWindowDimensions());
  }, []);

  // mouse
  useEffect(() => {
    if (!mousePos) return;
    if (!windowDimensions) return;
    const handleMouseMove = (event) => {
      setMousePos({ x: event.clientX, y: event.clientY });
    };

    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("resize", handleResize);
    };
  }, [mousePos, windowDimensions]);

  useEffect(() => {
    if (!mousePos) return;
    if (!windowDimensions) return;
    if (!modalXY && open) {
      var _x;
      var _y;

      if (mousePos.x < windowDimensions.width - xOffset) {
        _x = mousePos.x;
      } else {
        _x = windowDimensions.width - xOffset;
      }

      if (mousePos.y < windowDimensions.height - yOffset) {
        _y = mousePos.y;
      } else {
        _y = windowDimensions.height - yOffset;
      }

      setModalXY({ x: _x, y: _y });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mousePos, windowDimensions, open]);
  function handleLocalClose(e, reason) {
    setModalXY(false);
    handleClose(e, reason);
  }
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {modalXY ? (
          <Box
            sx={{
              position: "absolute",
              top: position === "mouse" ? `${modalXY.y}px` : "50%",
              left: position === "mouse" ? `${modalXY.x}px` : "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              borderRadius: "12px",
              boxShadow: 24,
              p: 4,
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
            component="form"
            onSubmit={(e) => {
              e.preventDefault();
              console.log("submit");
              handleSubmit();
            }}
          >
            {children}
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              {info ? (
                <IconButton
                  color="primary"
                  aria-label="close"
                  onClick={handleClose}
                >
                  <CancelIcon />
                </IconButton>
              ) : (
                <>
                  <Button onClick={handleLocalClose}>cancel</Button>
                  <Button type="submit">ok</Button>
                </>
              )}
            </Box>
          </Box>
        ) : (
          <></>
        )}
      </Modal>
    </div>
  );

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }
}
