import { Divider, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import CTResponsiveBar from "../nivo/barchart";
import CTResponsivePie from "../nivo/pie";

import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import GradeIcon from "@mui/icons-material/Grade";
import { useEffect, useState } from "react";
import SendToApi from "../../api/sendtoapi";

function getPercent(params) {
  const d = params.value * 100;
  return d.toPrecision(4);
}

export default function UpliftReport(props) {
  const { data } = props;

  const [rowsOverviewTable, setRowsOverviewTable] = useState(false);
  const [rowsSra, setRowsSra] = useState(false);
  const [rowsSrb, setRowsSrb] = useState(false);
  const [rowsInd, setRowsInd] = useState(false);

  const [dataChart, setDataChart] = useState(false);
  const [upLift, setUplift] = useState(false);
  const [pieData, setPieData] = useState(false);
  const [pieSum, setPieSum] = useState(false);

  const [dataChartSra, setDataChartSra] = useState(false);
  const [dataChartSrb, setDataChartSrb] = useState(false);
  const [dataChartInd, setDataChartInd] = useState(false);

  const [dataReady, setDataReady] = useState(false);

  //const rowsSuperResponderA = [
  //  { id: 1, col1: "B", col2: "21,307", col3: "3,894", col4: "18.28%" },
  //  { id: 2, col1: "A", col2: "21,387", col3: "3,238", col4: "15.14%" },
  //];

  useEffect(() => {
    (async () => {
      var values = {};
      values.id = data.id;
      const response = await SendToApi("uplift/getreportdata", values);
      if (response.status !== 200) {
        return;
      } else {
        setRowsOverviewTable(response.data.agg);
        setRowsSra(response.data.sra_agg);
        setRowsSrb(response.data.srb_agg);
        setRowsInd(response.data.ind_agg);

        var _dataChart = [];
        var _dataChartSra = [];
        var _dataChartSrb = [];
        var _dataChartInd = [];
        var A;
        var B;

        response.data.agg.forEach((element, i) => {
          const _obj = {
            id: i,
            treatment: element.Tr === "1" ? "A" : "B",
            [element.Tr === "1" ? "A" : "B"]: parseFloat(
              (element.Conv * 100).toPrecision(4)
            ),
          };
          _dataChart[i] = _obj;
          if ("A" in _obj) {
            A = _obj.A;
          } else if ("B" in _obj) {
            B = _obj.B;
          }
        });

        response.data.sra_agg.forEach((element, i) => {
          const _obj = {
            id: i,
            treatment: element.Tr === "1" ? "A" : "B",
            [element.Tr === "1" ? "A" : "B"]: parseFloat(
              (element.Conv * 100).toPrecision(4)
            ),
          };
          _dataChartSra[i] = _obj;
        });

        response.data.srb_agg.forEach((element, i) => {
          const _obj = {
            id: i,
            treatment: element.Tr === "1" ? "A" : "B",
            [element.Tr === "1" ? "A" : "B"]: parseFloat(
              (element.Conv * 100).toPrecision(4)
            ),
          };
          _dataChartSrb[i] = _obj;
        });

        response.data.ind_agg.forEach((element, i) => {
          const _obj = {
            id: i,
            treatment: element.Tr === "1" ? "A" : "B",
            [element.Tr === "1" ? "A" : "B"]: parseFloat(
              (element.Conv * 100).toPrecision(4)
            ),
          };
          _dataChartInd[i] = _obj;
        });

        const _upLift = ((A / B - 1) * 100).toPrecision(4);
        setUplift(_upLift);
        setDataChart(_dataChart);
        setDataChartSra(_dataChartSra);
        setDataChartSrb(_dataChartSrb);
        setDataChartInd(_dataChartInd);
        setPieData(response.data.uplift);
        const sum = response.data.uplift.reduce((accumulator, object) => {
          return accumulator + parseFloat(object.value);
        }, 0);
        //console.log(sum);
        setPieSum(sum);
      }
    })();

    return () => {};
  }, []);

  useEffect(() => {
    if (
      !upLift ||
      !dataChart ||
      !dataChartSra ||
      !dataChartSrb ||
      !dataChartInd ||
      !pieData
    )
      return;
    setDataReady(true);
  }, [upLift, dataChart, dataChartSra, dataChartSrb, dataChartInd, pieData]);

  function getCommaSeperatedValue(params) {
    return parseInt(params.value).toLocaleString();
  }

  function getTr(params) {
    return params.value === "1" ? "A" : "B";
  }
  const columnsTable = [
    { field: "id", hide: true, sortable: false },
    {
      field: "Tr",
      headerName: "Tr",
      flex: 0.5,
      valueGetter: getTr,
      sortable: false,
    },
    {
      field: "Custs",
      headerName: "Custs",
      flex: 1,
      valueGetter: getCommaSeperatedValue,
      sortable: false,
    },
    {
      field: "Outcome",
      headerName: "Outcome",
      flex: 1,
      sortable: false,
      valueGetter: getCommaSeperatedValue,
    },
    {
      field: "Conv",
      headerName: "Rate %",
      flex: 1,
      sortable: false,
      valueGetter: getPercent,
    },
  ];
  function ToolTip(obj) {
    return (
      <div
        style={{
          padding: 12,
          background: "#ffffff",
          border: `${obj.color} thin solid`,
          borderRadius: 5,
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography>
            <strong>
              {obj.id}: {obj.value}
            </strong>
          </Typography>
        </Box>
      </div>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        gap: "1rem",
        flexDirection: "column",
        width: "1200px",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
        <Typography variant="h4" color="primary">
          Overview
        </Typography>
        {dataReady ? (
          <Box
            className="uplift-report"
            sx={{
              display: "flex",
              gap: "1rem",
              flexDirection: "row",
              border: "thin lightgray solid",
              padding: "1rem",
              flexGrow: 1,
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
              {rowsOverviewTable && (
                <>
                  <Typography variant="h7" color="primary">
                    Overall Results
                  </Typography>

                  <DataGrid
                    rows={rowsOverviewTable}
                    columns={columnsTable}
                    loading={rowsOverviewTable.length === 0}
                    hideFooter // lose the page count
                    autoHeight
                    disableColumnMenu
                    disableColumnFilter
                    disableColumnResize
                  />
                </>
              )}
              {dataChart && (
                <>
                  <Typography variant="h7" color="primary">
                    Overall Results
                  </Typography>

                  <CTResponsiveBar
                    data={dataChart}
                    height="200px"
                    width="300px"
                    index="treatment"
                    keys={["A", "B"]}
                    axisLeft={{
                      format: (value) => `${value}%`,
                      legend: "Outcome",
                      legendPosition: "middle",
                      legendOffset: -40,
                    }}
                  />
                </>
              )}
              {upLift && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                    justifyContent: "center",
                    border: "thin lightgray solid",
                    padding: "1rem",
                  }}
                >
                  <Typography variant="h5" color="primary">
                    <TrendingUpIcon
                      sx={{ fontSize: "2rem", marginRight: "1rem" }}
                    />
                    Uplift {upLift}%
                  </Typography>
                  <Typography variant="h5" color="primary">
                    <GradeIcon sx={{ fontSize: "2rem", marginRight: "1rem" }} />
                    Significance XX
                  </Typography>
                </Box>
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
              }}
            >
              <Typography variant="h7" color="primary">
                Super-responder Analysis
              </Typography>
              {upLift && (
                <CTResponsivePie
                  data={pieData}
                  height="560px"
                  width="500px"
                  toolTip={ToolTip}
                  enableArcLinkLabels={false}
                  legends={[
                    {
                      anchor: "bottom-left",
                      direction: "column",
                      justify: false,
                      translateX: 0,
                      translateY: 0,
                      itemWidth: 100,
                      itemHeight: 20,
                      itemsSpacing: 0,
                      symbolSize: 20,
                      itemDirection: "left-to-right",
                      translateX: -74,
                      translateY: 55,
                    },
                  ]}
                  arcLabel={function (e) {
                    return (
                      ((parseFloat(e.value) / pieSum) * 100).toPrecision(3) +
                      "%"
                    );
                  }}
                />
              )}
            </Box>
          </Box>
        ) : (
          <>
            <Typography variant="h7" color="primary">
              No data found - please contact iota-ML support
            </Typography>
          </>
        )}
      </Box>
      {dataReady && (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          <Typography variant="h4" color="primary">
            Analysis
          </Typography>
          <Box
            className="uplift-report"
            sx={{
              display: "flex",
              gap: "1rem",
              flexDirection: "column",
              border: "thin lightgray solid",
              padding: "1rem",
              flexGrow: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "1rem",
                flexWrap: "wrap",
              }}
            >
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
              >
                <Typography variant="h7" color="primary">
                  Super-responders (B)
                </Typography>
                {rowsSrb && (
                  <DataGrid
                    rows={rowsSrb}
                    columns={columnsTable}
                    hideFooter // lose the page count
                    autoHeight
                    disableColumnMenu
                    disableColumnFilter
                    disableColumnResize
                  />
                )}
                {dataChartSrb && (
                  <>
                    <Typography variant="h7" color="primary">
                      Super-responders (B)
                    </Typography>

                    <CTResponsiveBar
                      data={dataChartSrb}
                      height="200px"
                      width="300px"
                      index="treatment"
                      keys={["A", "B"]}
                      axisLeft={{
                        format: (value) => `${value}%`,
                        legend: "Outcome",
                        legendPosition: "middle",
                        legendOffset: -40,
                      }}
                    />
                  </>
                )}
              </Box>
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
              >
                <Typography variant="h7" color="primary">
                  Indifferent
                </Typography>
                {rowsInd && (
                  <DataGrid
                    rows={rowsInd}
                    columns={columnsTable}
                    hideFooter // lose the page count
                    autoHeight
                    disableColumnMenu
                    disableColumnFilter
                    disableColumnResize
                  />
                )}
                {dataChartInd && (
                  <>
                    <Typography variant="h7" color="primary">
                      Indifferent
                    </Typography>

                    <CTResponsiveBar
                      data={dataChartInd}
                      height="200px"
                      width="300px"
                      index="treatment"
                      keys={["A", "B"]}
                      axisLeft={{
                        format: (value) => `${value}%`,
                        legend: "Outcome",
                        legendPosition: "middle",
                        legendOffset: -40,
                      }}
                    />
                  </>
                )}
              </Box>
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
              >
                <Typography variant="h7" color="primary">
                  Super-responders (A)
                </Typography>
                {rowsSra && (
                  <DataGrid
                    rows={rowsSra}
                    columns={columnsTable}
                    hideFooter // lose the page count
                    autoHeight
                    disableColumnMenu
                    disableColumnFilter
                    disableColumnResize
                  />
                )}
                {dataChartSra && (
                  <>
                    <Typography variant="h7" color="primary">
                      Super-responders (A)
                    </Typography>
                    <CTResponsiveBar
                      data={dataChartSra}
                      height="200px"
                      width="300px"
                      index="treatment"
                      keys={["A", "B"]}
                      axisLeft={{
                        format: (value) => `${value}%`,
                        legend: "Outcome",
                        legendPosition: "middle",
                        legendOffset: -40,
                      }}
                    />
                  </>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      <Divider />
    </Box>
  );
}
