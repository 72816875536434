import {
  Autocomplete,
  Box,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import SendToApi, { SendFileRequestToApi } from "../../../api/sendtoapi";
import CTResponsiveHeatMap from "../../../components/nivo/heatmap";
import DownloadIcon from "@mui/icons-material/Download";
import { DataGrid } from "@mui/x-data-grid";

async function MakeCSV(params) {
  const target = params.row.targets;
  const type = params.field;
  //console.log(target);
  //console.log(type);
  var values = {};
  values["target"] = target;
  values["type"] = type;
  var response = await SendFileRequestToApi("predict/makeaoacsv", values); // this returns the info from the database
  //console.log(response);

  if (!response.blob) {
    //console.log("file has not been returned");
    return;
  }

  var url = URL.createObjectURL(response.blob);
  //console.log(url);

  const link = document.createElement("a");
  // create a blobURI pointing to our Blob
  link.href = url;
  link.download = `${target}-${type}-aoa.csv`;
  // some browser needs the anchor to be in the doc
  document.body.append(link);
  link.click();
  link.remove();
  // in case the Blob uses a lot of memory
  setTimeout(() => URL.revokeObjectURL(link.href), 7000);
}
const columns = [
  {
    field: "targets",
    headerName: "Targets",
    flex: 1,
    minWidth: 170,
    editable: false,
  },
  {
    field: "super_responders",
    headerName: "1. Super-Responders",
    flex: 1,
    minWidth: 70,
    renderCell: (params) => {
      return (
        <>
          <Typography>{parseInt(params.value).toLocaleString()}</Typography>
          <IconButton
            onClick={() => MakeCSV(params)}
            color="primary"
            aria-label="download"
          >
            <DownloadIcon />
          </IconButton>
        </>
      );
    },
  },
  {
    field: "responders",
    headerName: "2. Responders",
    flex: 1,
    minWidth: 70,
    renderCell: (params) => {
      return (
        <>
          <Typography>{parseInt(params.value).toLocaleString()}</Typography>
          <IconButton
            onClick={() => MakeCSV(params)}
            color="primary"
            aria-label="download"
          >
            <DownloadIcon />
          </IconButton>
        </>
      );
    },
  },

  {
    field: "look_a_like",
    headerName: "3. Look-a-like",
    flex: 1,
    minWidth: 70,
    renderCell: (params) => {
      return (
        <>
          <Typography>{parseInt(params.value).toLocaleString()}</Typography>
          <IconButton
            onClick={() => MakeCSV(params)}
            color="primary"
            aria-label="download"
          >
            <DownloadIcon />
          </IconButton>
        </>
      );
    },
  },
  {
    field: "the_rest",
    headerName: "4. The rest",
    flex: 1,
    minWidth: 70,
    renderCell: (params) => {
      return (
        <>
          <Typography>{parseInt(params.value).toLocaleString()}</Typography>
          <IconButton
            onClick={() => MakeCSV(params)}
            color="primary"
            aria-label="download"
          >
            <DownloadIcon />
          </IconButton>
        </>
      );
    },
  },
];
export default function Products(props) {
  const [aoaData, setAOAData] = useState(false);
  const [options1, setOptions1] = useState(false);
  const [options2, setOptions2] = useState(false);
  const [value1, setValue1] = useState(false);
  const [value2, setValue2] = useState(false);
  const [heatmapData, setHeatmapData] = useState(false);

  useEffect(() => {
    (async () => {
      var values = {};
      var response = await SendToApi("predict/getaoa", values); // this returns the info from the database
      //console.log(response);
      if (response.status === 200) {
        setAOAData(response.data["rows"]);
        // build options
        var selectData = [];
        response.data["rows"].forEach((d, index) => {
          var obj = {};
          obj.value = d.targets;
          obj.label = d.targets;
          selectData[index] = obj;
        });
        setOptions1(selectData);
        setOptions2(selectData);
      }
    })();
  }, []);

  useEffect(() => {
    if (!options1) return;
    if (!value1) return;
    //console.log(value1);
    const label = value1.label;
    var _options2 = options1.filter(function (obj) {
      return obj.label !== label;
    });
    //console.log(_options2);
    setOptions2(_options2);
  }, [value1, value2, options1]);

  useEffect(() => {
    if (!value1) return;
    if (!value2) return;
    //console.log(value1.label);
    //console.log(value2.label);
    var _heatmap1 = aoaData.filter(function (obj) {
      return obj.targets === value1.label;
    });
    var _heatmap2 = aoaData.filter(function (obj) {
      return obj.targets === value2.label;
    });

    //console.log(_heatmap1);
    //console.log(_heatmap2);
  }, [value1, value2]);

  return (
    <Box
      sx={{
        display: "flex",
        gap: "1rem",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          flexGrow: "1",
        }}
      >
        <Typography variant="h5" color="primary">
          AOA
        </Typography>
        <Box
          className="uplift-report"
          sx={{
            display: "flex",
            gap: "1rem",
            flexDirection: "row",
            border: "thin lightgray solid",
            padding: "1rem",
            justifyContent: "center",
          }}
        >
          {aoaData && (
            <DataGrid
              autoHeight
              rows={aoaData}
              columns={columns}
              // checkboxSelection
              disableSelectionOnClick
              disableMultipleSelection={true}
              /* onSelectionModelChange={(ids) => {
                 const selectedIDs = new Set(ids);
                 const selectedRowData = rows.filter((row) =>
                   selectedIDs.has(row.id.toString())
                 );
                 //console.log(ids);
                 //console.log(selectedIDs);
                 //console.log(selectedRowData);
              }} */
            />
          )}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          flexGrow: "1",
        }}
      >
        <Typography variant="h5" color="primary">
          Audience Heatmap
        </Typography>
        <Box
          className="uplift-report"
          sx={{
            display: "flex",
            gap: "1rem",
            flexDirection: "row",
            border: "thin lightgray solid",
            padding: "1rem",
            justifyContent: "center",
          }}
        >
          {options1 && options2 && (
            <Box
              sx={{
                display: "flex",
                gap: "1rem",
                flexDirection: "column",
                justifyContent: "left",
              }}
            >
              <Autocomplete
                getOptionLabel={(option) => option.label || ""}
                value={value1}
                onChange={(event, newValue) => {
                  setValue1(newValue);
                }}
                id="controllable-states-demo"
                options={options1}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="1st product" />
                )}
              />
              <Autocomplete
                getOptionLabel={(option) => option.label || ""}
                value={value2}
                onChange={(event, newValue) => {
                  setValue2(newValue);
                }}
                id="controllable-states-demo"
                options={options2}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="2nd product" />
                )}
              />
            </Box>
          )}
          {heatmapData && (
            <Box
              sx={{
                display: "flex",
                gap: "1rem",
                flexDirection: "column",
                justifyContent: "left",
              }}
            >
              <CTResponsiveHeatMap
                data={heatmapData}
                height="400px"
                width="700px"
                legend=" "
              />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}
