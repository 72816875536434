import { useContext, useEffect, useState, useRef } from "react";

import RealStateContext from "../../wss/realState/realStateContext";
import GlobalStateContext from "../../globalstate/globalStateContext";

import { Alert, Avatar, Divider, Typography } from "@mui/material";

import SendToApi from "../../api/sendtoapi";
import { Box } from "@mui/system";

import SnitzyStepper from "../stepper/SnitzyStepper";

import CTButton from "../ctbutton";

//icons
import FilePresentIcon from "@mui/icons-material/FilePresent";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import BallotIcon from "@mui/icons-material/Ballot";
import GroupsIcon from "@mui/icons-material/Groups";

import { purple } from "@mui/material/colors";

import { NinjaText } from "../ninja";

export default function Dashboard() {
  const [statusRows, setStatusRows] = useState();
  const [heartbeat, setHeartbeat] = useState({});
  const [queuedJobs, setQueuedJobs] = useState();
  const stateRef = useRef(); // used to store the heartbeat

  stateRef.current = heartbeat;

  const rt = useContext(RealStateContext);
  const global = useContext(GlobalStateContext);

  useEffect(() => {
    console.log(statusRows);
  }, [statusRows]);

  async function setJobState(job, state) {
    var values = {};
    values.job_id = job.job_id;
    values.token = job._token;
    //    values.token = job_token;
    values.status = {};
    values.status.state = state;
    const response = await SendToApi("scripts/status", values, false); // false means dont send user token
    var _jobs = false
    switch (response.status) {
      case 200:
        _jobs = statusRows;
        _jobs = _jobs.filter((value, i) => {
          return value.id != job.job_id;
        });
        setStatusRows(_jobs);
        break;
      case 408:
        _jobs = statusRows;
        const _jobIndex = _jobs.findIndex((value, i) => {
          return value.id === job.job_id;
        });
        _jobs[_jobIndex]["error"] =
          "can not remove - incorrect token - please contact iota-ML";
        //console.log("after error");
        //console.log(_jobs);
        setStatusRows([..._jobs]);
        break;
      default:
    }

    //console.log("after filter");
    //console.log(_jobs);
  }

  // monitor realtime state
  useEffect(() => {
    //console.log("realtime has changed");
    //console.log(rt.realTime);
    if (!rt.realTime) {
      //console.log("realtime is empty");
      return;
    }

    Object.keys(rt.realTime).map(async (keyName, i) => {
      switch (rt.realTime[keyName].state) {
        case "completed":
          //console.log("job completed");
          break;
        case "elegant-fail":
          console.log("job elegant fail");
          break;
        default:
      }

      if (rt.realTime[keyName].alive === false) {
        //rt.removeWssJobChannel(rt.realTime[keyName]);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rt.realTime]);

  // Fetch the jobs from the database - any job that is launched and has a token
  useEffect(() => {
    if (!global.state.wssConnected) {
      return;
    }

    const fetchStatusData = async () => {
      const values = {};
      values.filterName = "_token,status->>'state',status->>'steps'";
      values.filterValue = "NOT NULL,launched,NOT NULL";

      const response = await SendToApi("jobs/getjobs", values);
      if (response.status === 200) {
        // add to realtime and status
        console.log(response.data.rows);
        rt.addWssJobChannels(response.data.rows);
        setStatusRows(response.data.rows);
      } else {
        console.log(response);
      }
    };

    fetchStatusData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [global.state.wssConnected]);

  function JobTitle(props) {
    const { job } = props;

    var jobname =
      job.update != null
        ? job.update.job_name
        : job.job_name
        ? job.job_name
        : job.job_id;

    var update =
      rt.realTime[job.job_id].update != null
        ? rt.realTime[job.job_id].update.job_name
        : false;

    if (update) {
      jobname = update;
    }

    var icon;
    switch (job.type) {
      case "uplift":
        icon = <FilePresentIcon />;
        break;
      case "score":
        icon = <FormatListNumberedIcon />;
        break;
      case "predict":
        icon = <BallotIcon />;
        break;
      case "tribesdemo":
        icon = <GroupsIcon />;
        break;
      default:
    }
    return (
      <Box
        sx={{
          display: "flex",
          direction: "row",
          gap: "1rem",
          alignItems: "center",
          paddingBottom: "1rem",
        }}
      >
        <Avatar sx={{ bgcolor: purple[500] }}>{icon}</Avatar>
        <Typography color={purple[500]}>{job.type}</Typography>
        <Typography color={purple[500]}>{jobname}</Typography>

        <NinjaText text={job.job_id} />
      </Box>
    );
  }

  async function getLog(id) {
    const userId = global.userInfo.userId;
    const accountId = global.userInfo.accountId;
    console.log(`fetch me the log for ${userId} ${accountId} ${id}`);
    const values = {};
    values.jobId = id;
    const response = await SendToApi("ninja/getpythonlog", values);
    if (response.status === 200) {
      console.log(response.data);
    }
  }

  function RealTimeLive(props) {
    const { job, i } = props;
    //console.log(typeof rt.realTime[job.id].alive);
    if (
      rt.realTime[job.job_id] &&
      rt.realTime[job.job_id].state !== "refused"
    ) {
      return (
        <Box key={i}>
          <JobTitle job={rt.realTime[job.job_id]} />
          <Box
            sx={{
              display: "flex",
              direction: "row",
              gap: "1rem",
              alignItems: "flex-start",
              paddingBottom: "1rem",
            }}
          >
            <SnitzyStepper job={rt.realTime[job.job_id]} />
            {rt.realTime[job.job_id].alive === false && (
              <CTButton
                title="remove"
                onClick={() => {
                  rt.removeWssJobChannel(job);
                  setJobState(job, "remove");
                }}
              />
            )}
            {global.userIs("ninja") && (
              <>
                <CTButton
                  title="log"
                  onClick={() => getLog(job.job_id)}
                  ninja
                />
              </>
            )}
          </Box>
          {global.userIs("ninja") &&
            typeof rt.realTime[job.id].alive === "object" && (
              <Box sx={{ border: "thin black solid", padding: "1rem" }}>
                <Typography>PID: {rt.realTime[job.id].alive[0]}</Typography>
                <Typography>CPU: {rt.realTime[job.id].alive[1]}</Typography>
                <Typography>MEM: {rt.realTime[job.id].alive[2]}</Typography>
              </Box>
            )}
          <Divider />
        </Box>
      );
    } else {
      // set the job to corrupt
      return (
        <>
          <JobTitle job={job} />
          <Box key={i} sx={{ display: "flex", direction: "row", gap: "1rem" }}>
            <Typography>
              This job does not have any processes running
            </Typography>
            <CTButton title="remove" onClick={() => setJobState(job, "remove")}>
              Remove
            </CTButton>
            {global.userIs("ninja") && (
              <CTButton title="log" onClick={() => getLog(job.job_id)} ninja />
            )}
            <Typography>
              {job.error && <Alert severity="error">{job.error}</Alert>}
            </Typography>
          </Box>
        </>
      );
    }
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        padding: "1rem",
        width: "90%",
      }}
    >
      {global.state.wssConnected ? (
        <>
          <Typography color="primary" gutterBottom variant="h6">
            Live Status
            {Object.keys(rt.realTime).length === 0 && (
              <>
                <Typography color="#777">
                  No real time models currently being processed
                </Typography>
              </>
            )}
          </Typography>
          <Divider sx={{ marginBottom: "1rem" }} />
          {statusRows && // we only return launched jobs
            statusRows.map((job, i) => (
              <RealTimeLive job={job} i={i} key={i} />
            ))}
        </>
      ) : (
        <Box>
          <Typography>
            No real time service found - please refresh your browser and if
            there is still no green cog on your screen please contact iota-ML.
          </Typography>
        </Box>
      )}
    </Box>
  );
}
