import { useEffect, useState } from "react";
import moment from "moment";

import { Button, IconButton, Tooltip, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { sliceIntoChunks, FindIdInArray } from "../../utils/utils";

import { makeStyles, styled } from "@mui/material/styles";
import SendToApi from "../../api/sendtoapi";
import EventModal from "./eventmodal";
import CalendarEvent from "./calendarevent";

export default function Calendar(props) {
  const [todaysDate, setTodaysDate] = useState(false);
  const [calendar, setCalendar] = useState(false); // month and year
  // eslint-disable-next-line no-unused-vars
  const [SundayMonday, setSundayMonday] = useState(false);
  const [monthYearChanged, setMonthYearChanged] = useState(false);
  const [weeks, setWeeks] = useState(false);
  const [events, setEvents] = useState(false);
  const [monthReadyToDisplay, setMonthReadyToDisplay] = useState(false);

  const [event, setEvent] = useState(false);
  const [day, setDay] = useState(false);
  const [open, setOpen] = useState(false);

  const StyledTableCell = styled(TableCell)((props) => ({
    color: props.prepost && "lightgrey",
    borderRight: "solid lightgrey thin",
    borderLeft: "solid lightgrey thin",
    verticalAlign: "top",
    width: "200px",
  }));

  // month year has changed
  useEffect(() => {
    if (!monthYearChanged) return;
    setEvents(false);

    const d = new Date(`${monthYearChanged.year}/${monthYearChanged.month}/01`);
    let dateObject = moment(d);
    let firstDayOfMonth = parseInt(dateObject.format("d"));
    if (!SundayMonday) {
      if (firstDayOfMonth === 0) {
        firstDayOfMonth = 6;
      } else {
        firstDayOfMonth--;
      }
    }
    const daysInMonth = moment(dateObject).daysInMonth();
    const monthFull = dateObject.format("MMMM");
    GetEvents();
    setCalendar({
      month: monthYearChanged.month,
      monthFull: monthFull,
      year: monthYearChanged.year,
      dateObject: dateObject,
      firstDayOfMonth: firstDayOfMonth,
      daysInMonth: daysInMonth,
    });

    // get events
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monthYearChanged, SundayMonday]);

  useEffect(() => {
    if (props.refresh) GetEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.refresh]);

  function GetEvents() {
    (async () => {
      var values = {};
      values["month"] = monthYearChanged.month;
      values["year"] = monthYearChanged.year;
      var response = await SendToApi("events/check", values); // this returns the info from the database
      if (response.status === 200) {
        if (response.data) {
          response.data.forEach((event) => {
            const obj = JSON.parse(event.details);
            event.details = obj;
          });
          setEvents(response.data);
        } else {
          setEvents("non found");
        }
      }
    })();
  }
  // set todays date
  useEffect(() => {
    moment.updateLocale("en", {
      week: {
        dow: SundayMonday ? 0 : 1,
      },
    });

    const dateObject = moment();
    const day = dateObject.format("D");
    const month = dateObject.format("M");
    const monthFull = dateObject.format("MMMM");
    const year = dateObject.format("Y");
    setMonthYearChanged({
      month: month,
      year: year,
    });
    setTodaysDate({
      day: day,
      month: month,
      monthFull: monthFull,
      year: year,
      dateObject: dateObject,
    });

    var firstDayOfMonth = moment(dateObject).startOf("month").format("d"); // Day of week 0...1..5...6

    if (!SundayMonday) {
      if (firstDayOfMonth === "0") {
        firstDayOfMonth = 6;
      } else {
        firstDayOfMonth--;
      }
    }
  }, [SundayMonday]);

  function handleClickEvent(e, id, day) {
    e.stopPropagation();
    const _id = id.split("-")[0];
    const event = FindIdInArray(events, _id);
    console.log(event);
    setEvent(event);
    setOpen(true);
    setDay(day);
  }
  // calendar
  useEffect(() => {
    function isToday(day) {
      return (
        todaysDate.month === calendar.month &&
        todaysDate.year === calendar.year &&
        parseInt(todaysDate.day) === day
      );
    }

    if (!calendar) return;

    let prevMonthDays = [];

    var firstDayOfMonth = moment(calendar.dateObject)
      .startOf("month")
      .format("d"); // Day of week 0...1..5...6

    if (!SundayMonday) {
      if (firstDayOfMonth === "0") {
        firstDayOfMonth = 6;
      } else {
        firstDayOfMonth--;
      }
    }

    // get prevmonth
    let dateObject = Object.assign({}, calendar.dateObject);
    const prevMonthDateObject = moment(dateObject).subtract(1, "month");
    const daysInPrevMonth = moment(prevMonthDateObject).daysInMonth();
    for (let i = 0; i < firstDayOfMonth; i++) {
      prevMonthDays.push(
        <StyledTableCell
          align="center"
          prepost="true"
          onClick={(e) => {
            onDayClick(e, daysInPrevMonth + 1 - (firstDayOfMonth - i));
          }}
          sx={{}}
          key={`prev${i}`}
        >
          {daysInPrevMonth + 1 - (firstDayOfMonth - i)}
        </StyledTableCell>
      );
    }

    let daysInMonth = [];

    // main days
    const numberOfDaysInMonth = moment(calendar.dateObject).daysInMonth();
    for (let d = 1; d <= numberOfDaysInMonth; d++) {
      // let selectedClass = (d == this.state.selectedDay ? " selected-day " : "")

      let foundEvents = [];

      if (events && events !== "non found") {
        foundEvents = events.filter((element) => {
          return moment(element.start_date).format("D") === String(d);
        });
        if (foundEvents.length === 0) {
        } else {
          // sort by time
          foundEvents = foundEvents.sort(
            (
              a,
              b // return 1 to move it up, -1 to move it down, 0 to leave
            ) => {
              let atime = moment(a.start_date).format("hh:mm").replace(":", "");
              let btime = moment(b.start_date).format("hh:mm").replace(":", "");
              return atime - btime;
            }
          );
        }
      }

      // todays styling
      var todayStyle;
      if (isToday(d)) {
        todayStyle = {
          color: "white",
          border: "thin solid",
          borderRadius: "5px",
          backgroundColor: "primary.main",
        };
      } else {
        todayStyle = { color: "gray" };
      }

      daysInMonth.push(
        <StyledTableCell
          className="daycell"
          align="center"
          onClick={(e) => {
            onDayClick(e, d);
          }}
          key={`month${d}`}
        >
          <Typography sx={todayStyle}>{d}</Typography>
          {foundEvents.map((event, i) => {
            return (
              <CalendarEvent
                key={event.id}
                id={`${event.id}-${i}`}
                day={d}
                event={event}
                handleClickEvent={handleClickEvent}
              ></CalendarEvent>
            );
          })}
        </StyledTableCell>
      );
    }

    // next month
    var totalDays = [...prevMonthDays, ...daysInMonth];
    const nextMonthsDayCount = 7 - (totalDays.length % 7);
    var nextMonthDays = [];
    for (let i = 1; i < nextMonthsDayCount + 1; i++) {
      nextMonthDays.push(
        <StyledTableCell
          align="center"
          prepost="true"
          onClick={(e) => {
            onDayClick(e, i);
          }}
          key={`next${i}`}
        >
          {i}
        </StyledTableCell>
      );
    }

    // build the calendar weeks
    totalDays = [...totalDays, ...nextMonthDays];
    setWeeks(sliceIntoChunks(totalDays, 7));
  }, [calendar, events, SundayMonday, todaysDate]);

  useEffect(() => {
    if (!weeks) return;
    setMonthReadyToDisplay(true);
  }, [weeks]);

  //-------------------------------------------------------------------
  // functions
  //-------------------------------------------------------------------
  function SetCalendarToToday() {
    setMonthYearChanged({
      month: todaysDate.month,
      year: todaysDate.year,
    });
  }

  // dateObject functions
  function MonthView() {
    return (
      <>
        {weeks &&
          weeks.map((week, i) => {
            return (
              <TableRow key={i}>
                {week.map((day, i) => {
                  return day;
                })}
              </TableRow>
            );
          })}
      </>
    );
  }

  function onPrev() {
    let dateObject = Object.assign({}, calendar.dateObject);
    const newDateObject = moment(dateObject).subtract(1, "month");
    const month = newDateObject.format("M");
    const year = newDateObject.format("Y");

    setMonthYearChanged({
      month: month,
      year: year,
    });
  }

  function onNext() {
    let dateObject = Object.assign({}, calendar.dateObject);
    const newDateObject = moment(dateObject).add(1, "month");

    const month = newDateObject.format("M");
    const year = newDateObject.format("Y");

    setMonthYearChanged({
      month: month,
      year: year,
    });
  }

  function onDayClick(e, d) {
    console.log(d);
    setCalendar((prev) => ({ ...prev, day: d }));
    setDay(d);
    const _day = `${calendar.year}-${calendar.month}-${d}`;
    props.SelectedDay(_day);
  }

  //-------------------------------------------------------------------
  // consts
  //-------------------------------------------------------------------
  const DaysOfTheWeek = () => {
    const weekdayshort = moment.weekdaysShort(true); // true sets the weekdsy to locale
    let _weekdayshortname = weekdayshort.map((day, i) => {
      return <StyledTableCell key={i}>{day}</StyledTableCell>;
    });

    return <>{_weekdayshortname}</>;
  };

  function handleClose() {
    setOpen(false);
    setEvent(false);
  }
  //-------------------------------------------------------------------
  // main return
  //-------------------------------------------------------------------
  function SelectedDay() {
    if (!day) return <></>;
    const _day = moment({
      year: calendar.year,
      month: calendar.month - 1, // moment is 0 month based - calender is in date format
      day: day,
    }).format("dddd Do");

    return <Typography color="primary">{_day}</Typography>;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "2px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            paddingLeft: "1rem",
            paddingRight: "1rem",
            paddingTop: "1rem",
            alignItems: "center",
          }}
        >
          <Button onClick={SetCalendarToToday} variant="outlined">
            Today
          </Button>
          <Tooltip title="Prev Month">
            <IconButton onClick={onPrev} color="primary">
              <ChevronLeftIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Next Month">
            <IconButton onClick={onNext} color="primary">
              <ChevronRightIcon />
            </IconButton>
          </Tooltip>
          <Typography variant="h5">
            {calendar.monthFull} {calendar.year}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            paddingRight: "1rem",
            alignItems: "center",
          }}
        >
          <SelectedDay />
        </Box>
      </Box>
      <Box sx={{ display: "flex", padding: "1rem" }}>
        <TableContainer>
          <Table sx={{ border: "thin solid lightgrey" }} aria-label="table">
            <TableHead sx={{}}>
              <TableRow
                sx={{
                  "th:last-child": { borderRight: 0 },
                }}
              >
                <DaysOfTheWeek />
              </TableRow>
            </TableHead>
            <TableBody>{monthReadyToDisplay && <MonthView />}</TableBody>
          </Table>
        </TableContainer>
      </Box>
      <EventModal
        handleClose={handleClose}
        open={open}
        setOpen={setOpen}
        month={calendar.month}
        year={calendar.year}
        //mousePos={mousePos}
        //windowDimensions={windowDimensions}
        event={event}
        events={events}
        setEvents={setEvents}
        day={day}
      />
    </Box>
  );
}
