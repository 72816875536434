import IconExpandLess from "@mui/icons-material/ExpandLess";
import IconExpandMore from "@mui/icons-material/ExpandMore";

import MenuItemComponent from "./menuitemcomponent";

import {
  Collapse,
  Divider,
  List,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import { useContext, useEffect, useState } from "react";
import GlobalStateContext from "../globalstate/globalStateContext";

export default function MenuItem(props) {
  const {
    userProtected,
    name,
    link,
    Icon,
    items = [],
    onSelect,
    keyValue,
    selected,
  } = props;
  const isExpandable = items && items.length > 0;
  const [open, setOpen] = useState(false);


  function handleClick() {
    setOpen(!open);
    if (!isExpandable) {
      onSelect(keyValue);
    }
  }

  const MenuItemRoot = (
    <MenuItemComponent link={link} onClick={handleClick}>
      {/* Display an icon if any */}
      {Icon && (
        <ListItemIcon>
          <Icon color={selected === keyValue ? "primary" : ""} />
        </ListItemIcon>
      )}
      <ListItemText
        primary={name}
        inset={!Icon}
        primaryTypographyProps={
          selected === keyValue
            ? {
                color: "primary",
              }
            : {
                color: "",
              }
        }
      />
      {/* Display the expand menu if the item has children */}
      {isExpandable && !open && <IconExpandMore />}
      {isExpandable && open && <IconExpandLess />}
    </MenuItemComponent>
  );

  const MenuItemChildren = isExpandable ? (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <List component="div">
        {items.map((item, index) => (
          <MenuItem
            {...item}
            key={keyValue * 10 + (index + 1)}
            keyValue={keyValue * 10 + (index + 1)}
            onSelect={onSelect}
            selected={selected}
          />
        ))}
      </List>
      <Divider/>
    </Collapse>
  ) : null;

  function UserCheck() {
    const global = useContext(GlobalStateContext);

    if (
      typeof userProtected === "undefined" ||
      global.isMember(userProtected)
    ) {
      return (
        <>
          {name === "divider" && <Divider />}
          {name !== "divider" && (
            <div>
              {MenuItemRoot}
              {MenuItemChildren}
            </div>
          )}
        </>
      );
    }
  }
  return <UserCheck />;
}
