import { useCallback, useContext, useState } from "react";

export default function useCallApi(route, values, addToken = true) {
  const [result, setResult] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const fetchResult = useCallback(async (route, values, addToken) => {
    console.log(route);
    console.log(values);
    console.log(addToken);
  }, []);
  return { loading };
}
