import {
  Radio,
  FormControl,
  FormControlLabel,
  FormGroup,
  RadioGroup,
  FormLabel,
} from "@mui/material";

import { useField, useFormikContext } from "formik";
import { FormHelperText } from "@mui/material";
import { useState } from "react";

export default function FormRadio(props) {
  const { section, defaultValue } = props;
  const { setFieldValue } = useFormikContext();
  const [value, setValue] = useState(section.default);

  const handleChange = (evt) => {
    setFieldValue(section.id, evt.target.value);
    setValue(evt.target.value);
  };

  return (
    <FormControl>
      <FormLabel id="demo-radio-buttons-group-label">{section.name} </FormLabel>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue={defaultValue}
        name="radio-buttons-group"
        value={value}
        onChange={handleChange}
      >
        {section.fields.map((field, index) => {
          return (
            <FormControlLabel
              key={index}
              value={field.label}
              control={<Radio id={field.id} />}
              label={field.label}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
}
