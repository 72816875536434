import React, { useContext, useEffect } from "react";
import {
  Alert,
  AlertTitle,
  Avatar,
  Box,
  Paper,
  Typography,
} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import GlobalStateContext from "../../globalstate/globalStateContext";
import { Copyright } from "../../components/copyright";
import SendToApi from "../../api/sendtoapi";
import CTForm from "../../components/forms/ctform";
import layout from "./layout.json";
import { purple } from "@mui/material/colors";
import { useState } from "react";
import jwt_decode from "jwt-decode";

export default function Register() {
  const [token, setToken] = useState(false);
  const [emailFromToken, setEmailFromToken] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    // get the user token and username from the url
    const search = window.location.search;
    const params = new URLSearchParams(search);
    if (params.get("token")) {
      const token = params.get("token");

      try {
        var decoded = jwt_decode(token);
        setToken(params.get("token"));

        setEmailFromToken(decoded.username);
      } catch (err) {}
    }
  }, []);

  const global = useContext(GlobalStateContext);

  useEffect(() => {}, []);

  function handleChange(values) {}

  async function handleSignUp(values) {
    values.passwordConfirmation = "";
    values.username = values.email;
    if (values.email !== emailFromToken) {
      setError("Please use the email that you received this link from");
      return;
    }
    values.token = token;
    const response = await SendToApi("users/register", values, false);

    if (!response) {
      global.setStateUplift((prevState) => ({
        ...prevState,
        error: "network error",
      }));
      return false;
    }

    if (response.status === 200) {
      window.location.assign("/login");
      return true;
    } else {
      if (response.status === 409) {
        global.setStateUplift((prevState) => ({
          ...global.state,
          error: response.statusText,
        }));
        return false;
      }
    }
  }

  return (
    <Box
      sx={{
        position: "absolute",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        backgroundColor: "#ccc",
      }}
    >
      <Box
        mt={5}
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Paper>
          <Box
            p={5}
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ bgcolor: purple[500] }}>
              <LockIcon />
            </Avatar>

            <Typography component="h1" variant="h5">
              Register
            </Typography>

            {emailFromToken && (
              <CTForm
                submitText="Register"
                handleSubmit={handleSignUp}
                layout={layout.form}
                onChange={handleChange}
              />
            )}
            {error && (
              <Box mt={5}>
                <Alert severity="error">
                  <AlertTitle>Error</AlertTitle>
                  {error}.
                </Alert>
              </Box>
            )}

            {!token && (
              <Box mt={5}>
                <Alert severity="error">
                  <AlertTitle>Error</AlertTitle>A valid token is required to
                  register. Please contact IOTA-ML for details.
                </Alert>
              </Box>
            )}

            <Box mt={5}>
              <img
                src="iota-logo.png"
                style={{ width: "150px", height: "100%", objectFit: "contain" }}
                alt="logo"
              />
            </Box>
            <Box>
              <Copyright />
            </Box>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
}
