import { whoCalledThisFunction } from "../utils/utils";

const axios = require("axios").default;

// api Functions

function isset(value) {
  if (typeof value !== "undefined") {
    return true;
  }
  return false;
}

export default async function SendToApi(route, values, addToken = true) {
  // add token and username
  // CANT USE GLOBAL HOOK IN HERE

  if (route !== "log/message") {
    console.log(`${whoCalledThisFunction()} -> ${route}`);
    console.log(values);
  }
  axios.interceptors.response.use(
    (res) => res,
    (err) => {
      return err.response;
    }
  );

  values.today = sessionStorage.getItem("today");
  values.account_id = sessionStorage.getItem("account_id");

  if (addToken) {
    values.token = sessionStorage.getItem("token");
    values.username = sessionStorage.getItem("username");
  }

  if (isset(values.email) && isset(values.password)) {
    const url = process.env.REACT_APP_API_URL + route;

    const data = JSON.stringify(values);
    var basicAuth =
      "Basic " + window.btoa(values.email + ":" + values.password);
    try {
      const response = await axios({
        method: "post",
        url: url,
        data: data,
        headers: { Authorization: basicAuth },
      });
      return response;
    } catch (err) {
      console.log("*********** api error *************");
      console.log(err);
      global.setState((prevState) => ({
        ...prevState,
        apiConnection: "error",
      }));
      return false;
    }
  } else {
    // no email password to worry about
    const url = process.env.REACT_APP_API_URL + route;
    const data = JSON.stringify({ ...values }); // spread incase we are sent an array .. CTform stylee
    //console.log(`sending to ${url}`);
    //console.log(values);

    ////console.log(data.length);
    if (data.length === 2) {
      // just sends []
      //console.log(`************ failed to create data ***********`);
    }
    try {
      const response = await axios({
        method: "post",
        url: url,
        data: data,
      });
      return response;
    } catch (err) {
      //console.log("*********** api error *************");
      //console.log(err);

      return false;
    }
  }
}
export async function SendFileRequestToApi(route, values) {
  /*let _headers = {
    "Content-Type": "application/json",
  };*/

  // add token and username
  values.token = sessionStorage.getItem("token");
  values.username = sessionStorage.getItem("username");
  values.account_id = sessionStorage.getItem("account_id");

  var requestOptions = {
    method: "POST",
    //headers: _headers,
    body: JSON.stringify(values),
  };

  try {
    const response = await fetch(
      process.env.REACT_APP_API_URL + route,
      requestOptions
    );
    if (response.status === 200) {
      const blob = await response.blob();
      return { blob };
    } else {
      console.log(response);
      return false;
    }
  } catch (err) {
    console.log(err);
    return false;
  }
}

export async function SendFormToApi(route, formData) {
  // add token and username - must be in formdata

  const token = sessionStorage.getItem("token");
  const username = sessionStorage.getItem("username");
  const account_id = sessionStorage.getItem("account_id");

  formData.append("token", token);
  formData.append("username", username);
  formData.append("account_id", account_id);

  const url = process.env.REACT_APP_API_URL + route;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  const data = formData;

  try {
    //console.log("sending form");

    const response = await axios.post(url, data, config);

    return response;
  } catch (err) {
    //console.log(err);
    return false;
  }
}

export async function FileUploadToAPI(
  filestatus,
  selectedFile,
  type,
  jobId,
  options
) {
  return new Promise(async (resolve) => {
    var reader = {};
    var file = selectedFile;
    var slice_size = 1000 * 1024;
    var ref = 0;
    var response;

    //console.log("reading file");
    reader = new FileReader();
    //console.log(selectedFile);
    const result = await upload_file(0);
    //console.log("read file");
    //console.log(result);
    if (result !== false) {
      //console.log("file upload complete");
      const reply = await result;
      //console.log(reply);
      resolve(reply);
      return;
    }
    //console.log("*** FATAL ERROR *** file upload not complete");
    resolve(false);
    // slice the file
    async function sendChunk(chunk, ref, percent_done) {
      var formData = new FormData();
      ////console.log(`sending ${ref} to api`);

      // username and job token are allready in selectedFile
      const account_id = sessionStorage.getItem("account_id");
      const token = sessionStorage.getItem("token");
      const username = sessionStorage.getItem("username");

      formData.append("token", token);
      formData.append("username", username); // see job_id comment below

      formData.append("account_id", account_id);
      formData.append("chunk", chunk);
      formData.append("ref", ref);
      formData.append("type", type);
      formData.append("jobid", jobId); // send jobid not job_id as this is used by scripts and is a seperate token - this gets checked against user token instead
      formData.append("options", options);
      formData.append("filename", selectedFile.name);

      const url = process.env.REACT_APP_API_URL + "files/upload";

      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };

      try {
        //console.log(`sending data ${ref}`);
        response = await axios.post(url, formData, config);
        response.data.percent_done = percent_done;
        //console.log(response.data);
        filestatus(response, type);
      } catch (err) {
        //console.log(err);
        return false;
      }
    }

    async function upload_file(start) {
      return new Promise((resolve) => {
        var next_slice = start + slice_size + 1;
        var blob = file.slice(start, next_slice);
        //console.log("upload file");
        reader.onloadend = async function (event) {
          if (event.target.readyState !== FileReader.DONE) {
            //console.log("readyState not done ");
            resolve(false);
          }

          var size_done = start + slice_size;
          var percent_done = Math.floor((size_done / file.size) * 100); // assuming it gets to api
          await sendChunk(event.target.result, ref++, percent_done);

          //console.log(`percent_done ${percent_done}`);

          if (next_slice < file.size) {
            const result = await upload_file(next_slice);
            //console.log(result);
            if (result) {
              resolve(response.data);
            }
          } else {
            await sendChunk("eof", 0, 100);
            resolve(response.data);
          }
        };
        reader.readAsDataURL(blob);
      });
    }
  });
}

export function write_log(message) {
  var values = {};
  values["message"] = message;
  SendToApi("log/message", values);
}
