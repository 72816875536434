// https://dev.to/atosh502/how-to-access-formik-props-with-useformikcontext-hook-7kp
import React from "react";
import { Button } from "@mui/material";
import { useFormikContext } from "formik";

const FormButton = ({ disabled, children }) => {
  const { submitForm } = useFormikContext();

  return (
    <Button
      sx={{ width: "200px" }}
      variant="contained"
      color="primary"
      onClick={submitForm}
      disabled={disabled}
    >
      {children}
    </Button>
  );
};

export default FormButton;
