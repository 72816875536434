import { Button } from "@mui/material";
import { blueGrey } from "@mui/material/colors";
import { red } from "@mui/material/colors";

export default function CTButton(props) {
  const { title, onClick, ninja } = props;
  return (
    <Button
      variant="contained"
      color={ninja ? "ninja" : "primary"}
      onClick={onClick}
    >
      {title}
    </Button>
  );
}
