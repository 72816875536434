import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";

export default function CalendarEvent({ id, day, event, handleClickEvent }) {
  const eventColor = () => {
    switch (event.details.type) {
      case "cron":
        return "darkblue";
      default:
        return "darkgreen";
    }
  };

  return (
    <Box sx={{ display: "flex", gap: "1rem", justifyContent: "center" }}>
      {event.time !== null && (
        <Box sx={{ padding: "3px" }}>
          <Typography variant="h8" color="primary" sx={{}}>
            {event.time}
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          width: "100%",
          height: "1rem",
          borderRadius: "1rem",
          backgroundColor: eventColor,
          padding: "3px",
          cursor: "pointer",
          marginBottom: "1rem",
        }}
        onClick={(e) => handleClickEvent(e, id, day)}
      >
        <Typography
          variant="h8"
          sx={{ color: "white", textTransform: "uppercase" }}
        >
          {event.details.title}
        </Typography>
      </Box>
    </Box>
  );
}
