export function FindIdInArray(array, id) {
  const item = array.find((obj) => {
    return obj.id === id;
  });
  return item;
}

export function FindObjInArray(array, key, value) {
  const item = array.find((obj) => {
    return obj[key] === value;
  });
  return item;
}

export function RemoveObjectWithId(arr, id) {
  if (Array.isArray(arr)) {
    return arr.filter((obj) => obj.id !== id);
  }
  if (typeof arr === "object") {
    delete arr[id];
    return arr;
  }
}

export function sliceIntoChunks(arr, chunkSize) {
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
}

export function whoCalledThisFunction() {
  var caller = new Error().stack;
  caller = caller.split("\n")[2];
  caller = caller.slice(0, caller.indexOf("/"));
  return caller;
}

export function displayTextWidth(text, font) {
  let canvas =
    displayTextWidth.canvas ||
    (displayTextWidth.canvas = document.createElement("canvas"));
  let context = canvas.getContext("2d");
  context.font = font;
  let metrics = context.measureText(text);
  return parseInt(metrics.width);
}
