import { useEffect, useState } from "react";
import SendToApi from "../../api/sendtoapi";
import CTModal from "../modal/ctmodal";
import EventForm from "./eventform";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Divider, IconButton } from "@mui/material";
import { useConfirmation } from "../../providers/ConfirmationService";
import { RemoveObjectWithId } from "../../utils/utils";
import EventInfo from "./eventinfo";
import dayjs from "dayjs";

export default function EventModal(props) {
  const {
    open,
    setOpen,
    month,
    year,
    day,
    event,
    events,
    handleClose,
    newEvent,
    setEvents,
  } = props;
  const [eventType, setEventType] = useState("");
  const [eventTitle, setEventTitle] = useState("");
  const [time, setTime] = useState(false);
  const [infoMode, setInfoMode] = useState(event ? false : true);
  const [details, setDetails] = useState(null);

  useEffect(() => {
    console.log(event);
    if (!event) {
      setInfoMode(false);
      setTime(false);
      setEventTitle("");
      setEventType("");
    } else {
      console.log(event);
      console.log(day);
      setInfoMode(true);
      const _details = event.details;
      setDetails(_details);
      if (event.time) {
        setTime(dayjs(`${year}-${month}-${day}T${event.time}`));
      }
      setEventTitle(_details.title);
      setEventType(_details.type);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event]);

  useEffect(() => {
    if (!time) return;
    console.log(String(time.$H));
    console.log(String(time.$H).padStart(2, 0));
  }, [time]);

  async function handleSubmit() {
    var values = {};
    var _month = ("0" + month).slice(-2);
    var _day = ("0" + day).slice(-2);
    values["date"] = `${year}-${_month}-${_day}`;

    if (typeof event.id !== "undefined") {
      values["id"] = event.id;
    }
    values["details"] = {
      title: eventTitle,
      type: eventType,
    };
    console.log(time);
    const h = String(time.$H).padStart(2, 0);
    const m = String(time.$m).padStart(2, 0);

    values["time"] = time ? `${h}:${m}` : null;
    console.log(values);
    var response = await SendToApi("events/add", values); // this returns the info from the database

    if (response.status === 200) {
      console.log(response.data.id);
      const newEvent = {
        id: response.data.id,
        details: {
          title: eventTitle,
        },
        time: values["time"],
        start_date: values["date"],
      };

      if (events.length !== 0) {
        let index = events.findIndex((x) => x.id === response.data.id);
        if (index !== -1) {
          let _events = events.slice();
          console.log(_events[index]);
          _events[index].details.title = eventTitle;
          _events[index][time] = values["time"];
          console.log(_events[index]);
          setEvents(_events);
        } else {
          setEvents((prev) => [...prev, newEvent]);
        }
      } else {
        setEvents(newEvent);
      }
    }
    setOpen(false);
    setEventType("");
    setEventTitle("");
    setTime(false);
  }

  const handleChange = (event) => {
    console.log(event);
    switch (event.target.name) {
      case "eventTitle":
        setEventTitle(event.target.value);
        break;
      case "eventType":
        setEventType(event.target.value);
        break;
      default:
    }

    //setEventType(event.target.value);
  };

  async function DeleteEvent() {
    var values = {};
    values["id"] = event.id;
    var response = await SendToApi("events/delete", values); // this returns the info from the database
    if (response.status === 200) {
      setEvents(RemoveObjectWithId(events, event.id));
      setOpen(false);
    }
  }
  const confirm = useConfirmation();
  const tryToDelete = () => {
    confirm({
      variant: "danger",
      catchOnCancel: true,
      title: `Are you sure you want to remove ${details.title}`,
      description: `This will permanently remove the event from the database`,
    })
      .then(DeleteEvent)
      .catch(() => {}); // cancel
  };

  function handleEdit() {
    setInfoMode(false);
  }
  return (
    <div>
      <CTModal
        open={open}
        setOpen={setOpen}
        handleSubmit={handleSubmit}
        handleClose={handleClose}
        xOffset="250"
        yOffset="250"
        position="mouse"
      >
        {infoMode ? (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "1rem",
                justifyContent: "flex-end",
              }}
            >
              <IconButton onClick={handleEdit}>
                <EditIcon color="primary" />
              </IconButton>
              <IconButton onClick={tryToDelete}>
                <DeleteIcon color="primary" />
              </IconButton>
            </Box>

            <Divider />
            <EventInfo details={details} time={time} />
          </>
        ) : (
          <EventForm
            eventType={eventType}
            eventTitle={eventTitle}
            setTime={setTime}
            time={time}
            month={month}
            year={year}
            day={day}
            handleChange={handleChange}
          />
        )}
      </CTModal>
    </div>
  );
}
