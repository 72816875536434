import Box from "@mui/material/Box";

import { Divider, TextField } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

export default function EventForm(props) {
  const { eventTitle, handleChange, eventType, time, setTime } = props;

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "1rem",
          justifyContent: "flex-end",
        }}
      ></Box>

      <Divider />

      <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
        <TextField
          required
          id="eventTitle"
          label="Name"
          value={eventTitle}
          onChange={handleChange}
          name="eventTitle"
        />

        {eventType !== "cron" && (
          <TimePicker
            ampm={false}
            label="Event time"
            value={time ? time : ""}
            onChange={(newValue) => {
              setTime(newValue);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        )}
      </Box>
    </Box>
  );
}
