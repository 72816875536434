import { Box, CircularProgress, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import SendToApi from "../../../api/sendtoapi";
import CTResponsiveHeatMap from "../../../components/nivo/heatmap";
import { DataGrid } from "@mui/x-data-grid";


function getQFR(params) {
  const q = params.value * 1;
  return parseInt(q);
}

export default function Clicks(props) {
  const [segmentData, setSegmentData] = useState(false);
  const [combinationData, setCombinationData] = useState(false);

  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "antecedents",
      headerName: "Antecedents",
      flex: 1,
      minWidth: 170,
      editable: false,
    },
    {
      field: "consequents",
      headerName: "Consequents",
      flex: 1,
      minWidth: 170,
      editable: false,
    },
    {
      field: "confidence",
      headerName: "Confidence",
      flex: 1,
      minWidth: 170,
      editable: false,
    },
    {
      field: "lift",
      headerName: "Lift",
      flex: 1,
      minWidth: 170,
      editable: false,
    },
    {
      field: "qualify_for_rule",
      headerName: "Qualify For Rule",
      flex: 1,
      minWidth: 170,
      editable: false,
      valueGetter: getQFR,
    },
  ];
  useEffect(() => {
    (async () => {
      var values = {};
      const response = await SendToApi(
        "predict/getinsightclicksegments",
        values
      );
      if (response.status !== 200) {
        //console.log("error");
      } else {
        //console.log(response.data);

        setSegmentData(response.data);
      }
    })();

    return () => {};
  }, []);

  function randomId() {
    return Math.random();
  }

  useEffect(() => {
    (async () => {
      var values = {};
      const response = await SendToApi(
        "predict/getinsightclickcombinations",
        values
      );
      if (response.status !== 200) {
        //console.log("error");
      } else {
        //console.log(response.data);
        const result = response.data.map((o) => {
          o = { ...o, id: randomId() };
          return o;
        });
        setCombinationData(result);
      }
    })();

    return () => {};
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        gap: "1rem",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          flexGrow: "1",
        }}
      >
        <Typography variant="h5" color="primary">
          Segments
        </Typography>
        <Box
          className="uplift-report"
          sx={{
            display: "flex",
            gap: "1rem",
            flexDirection: "row",
            border: "thin lightgray solid",
            padding: "1rem",
            justifyContent: "center",
          }}
        >
          {segmentData && (
            <CTResponsiveHeatMap
              data={segmentData}
              height="600px"
              width="1000px"
              legend=" "
            />
          )}
          {!segmentData && <CircularProgress />}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          flexGrow: "1",
        }}
      >
        <Typography variant="h5" color="primary">
          Combinations
        </Typography>
        <Box
          className="uplift-report"
          sx={{
            display: "flex",
            gap: "1rem",
            flexDirection: "row",
            border: "thin lightgray solid",
            padding: "1rem",
            justifyContent: "center",
          }}
        >
          {combinationData && (
            <DataGrid
              columnVisibilityModel={{
                // Hide id colum
                id: false,
              }}
              autoHeight
              rows={combinationData}
              columns={columns}
              disableSelectionOnClick
            />
          )}
          {!combinationData && <CircularProgress />}
        </Box>
      </Box>
    </Box>
  );
}
