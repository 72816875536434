import { Box, Divider, Typography } from "@mui/material";

export default function TribesReport(props) {
  const { data } = props;
  console.log(data);
  const name = JSON.parse(data.update).job_name;
  const description = JSON.parse(data.update).description;
  return (
    <Box
      sx={{
        display: "flex",
        gap: "1rem",
        flexDirection: "column",
        width: "450px",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
        <Typography variant="h4" color="primary">
          {name}
        </Typography>
        <Typography variant="h7" color="primary">
          {description}
        </Typography>
      </Box>
      <Divider />
    </Box>
  );
}
