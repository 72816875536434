//https://stackoverflow.com/questions/67553218/how-to-change-style-in-material-ui-table-pagination
//https://mui.com/components/data-grid/components/#heading-interacting-with-the-grid - useGridApi stuff
import {
  Button,
  Chip,
  Divider,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useContext, useEffect, useRef, useState } from "react";
import SendToApi, { FileUploadToAPI } from "../../api/sendtoapi";

import FileUpload from "../../components/fileupload/fileupload";
import RealStateContext from "../../wss/realState/realStateContext";
import clsx from "clsx";

import FileToApi from "../../api/sendtoapi";
import StatusStepper from "../../components/stepper/statusStepper";
import FilePresentIcon from "@mui/icons-material/FilePresent";

import { DataGrid, GridPagination } from "@mui/x-data-grid";
import { Navigate } from "react-router-dom";
import { SentimentSatisfiedOutlined } from "@mui/icons-material";

const chunk_size = 1024 * 1024;

export default function Scores() {
  const [pageSize, setPageSize] = useState(3);
  const [selectedFiles, setSelectedFiles] = useState({});
  const [selectionModel, setSelectionModel] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [jobId, setJobId] = useState(0);
  const [rows, setRows] = useState();
  const [parentJobId, setParentJobId] = useState(false);
  const rt = useContext(RealStateContext);

  const [submitting, setSubmitting] = useState(false);
  const [launchStatus, setLaunchStatus] = useState(0);

  const [stateLocal, setStateLocal] = useState({
    job_name: "",
    description: "",
    //    response_variable: "",
    t1: "",
  });

  const jobIdRef = useRef();
  jobIdRef.current = jobId;

  function handleChange(e) {
    setStateLocal((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  }

  async function onLaunch() {
    setSubmitting(true);
    // get job id
    const newjobId = await onRegister();

    setJobId(newjobId);

    // upload files
    FileUploadToAPI(
      filestatus,
      selectedFiles["t1"].file,
      "t1",
      newjobId,
      "jobfile"
    );
  }

  function filestatus(status, type) {
    switch (status.data.state) {
      case "uploaded":
        console.log("file uploaded");
        console.log(status.data.id);
        setSelectedFiles((prev) => ({
          ...prev,
          [type]: { ...prev[type], id: status.data.id },
        }));
        switch (type) {
          case "t1":
            setLaunchStatus(2);
            Launch(status.data.id);
            break;
          default:
            break;
        }
        break;
      case "uploading":
        {
          const percent = Math.round(
            (status.data.ref / selectedFiles[type].number_of_chunks) * 100
          );
          setSelectedFiles((prev) => ({
            ...prev,
            [type]: { ...prev[type], progress: percent },
          }));
        }
        break;
      default:
    }
  }

  async function Launch(fileId) {
    setSubmitting(true);

    var values = {};
    values.fileuploads = {};
    values.fileuploads.treatment_filename = fileId;

    values.jobid = jobIdRef.current;
    //console.log("in launch sending the following values");
    //console.log(values);
    var response = await SendToApi("scripts/launch", values);
    if (response.status === 200) {
      setLaunchStatus(3);
    } else {
    }

    setSubmitting(false);
    return false;
  }

  async function onRegister() {
    // TODO get a job id from the api
    var values = {};
    values.parent_job_id = parentJobId;
    values.job_name = stateLocal.job_name;
    values.description = stateLocal.description;
    //  values.response_variable = stateLocal.response_variable;
    values.type = "score";
    var response = await SendToApi("jobs/register", values);

    if (response.status === 200) {
      setJobId(response.data);
      console.log(response.data);
      var initialState = { state: "launched" };
      rt.setRealTime({
        ...rt.realTime,
        [response.data]: initialState,
      });
      setLaunchStatus(1);
      return response.data;
    }
    return false;
  }

  useEffect(() => {
    const fetchCompleteData = async () => {
      const values = {};
      values.filterName = "status->>'state',type";
      values.filterValue = "completed,uplift";

      const response = await SendToApi("jobs/getjobs", values);

      if (response.status === 200) {
        //console.log(response.data.rows);
        setRows(response.data.rows);
      }
    };
    fetchCompleteData();
  }, []);

  const columns = [
    {
      field: "job_name",
      headerName: "Name",
      width: 170,
      editable: false,
    },
    {
      field: "date",
      headerName: "Date completed",
      width: 370,
      editable: false,
      valueGetter: getDate,
      valueSetter: setDate,
    },
  ];
  function getDate(params) {
    const d = new Date(params.row._updated_at);
    return d.toUTCString().slice(0, -4); // lose the GMT
  }

  function setDate(params) {
    const [_updated_at] = params.value.toString();
    return { ...params.row, _updated_at };
  }

  function handleFileSelect(selectedFile, type, size) {
    //console.log(`type ${type}`);
    //console.log(`size ${size}`);
    const number_of_chunks = Math.ceil(size / chunk_size);
    //console.log(`number of chunks ${number_of_chunks}`);
    setSelectedFiles((prev) => ({
      ...prev,
      [type]: {
        file: selectedFile,
        size: size,
        number_of_chunks: number_of_chunks,
        progress: 0,
      },
    }));
  }
  function CustomFooter() {
    return (
      <Box
        sx={{
          padding: "1rem",
        }}
      >
        {selectedRow.id && (
          <Chip
            label={selectedRow.job_name ? selectedRow.job_name : selectedRow.id}
            icon={<FilePresentIcon />}
          />
        )}
        <GridPagination //rowsPerPageOptions={[3, 5, 10, 15]}
        />
      </Box>
    );
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        padding: "1rem",
        width: "90%",
      }}
    >
      {" "}
      {launchStatus === 3 && <Navigate replace to="/uplift/dashboard" />}
      <Typography color="primary" gutterBottom variant="h6">
        Launch new score
      </Typography>
      <Divider sx={{ marginBottom: "1rem" }} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        {rows && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              paddingBottom: "1em",
              gap: "1rem",
            }}
          >
            <Typography color="primary" variant="h7">
              Please select an uplift model
            </Typography>

            <DataGrid
              autoHeight
              rows={rows}
              columns={columns}
              checkboxSelection
              selectionModel={selectionModel}
              // disableSelectionOnClick
              pagination
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[3, 5, 10, 15]}
              disableMultipleSelection={true}
              onSelectionModelChange={(selection) => {
                var selectedID;
                if (selection.length > 1) {
                  const selectionSet = new Set(selectionModel);
                  const result = selection.filter((s) => !selectionSet.has(s));
                  setSelectionModel(result);
                  selectedID = new Set(result);
                } else {
                  setSelectionModel(selection);
                  selectedID = new Set(selection);
                }
                //console.log(selectedID);

                const selectedRowData = rows.filter((row) =>
                  selectedID.has(row.id.toString())
                );

                //console.log(selectedRowData);
                if (typeof selectedRowData[0] !== "undefined") {
                  setSelectedRow(selectedRowData[0]);
                  setParentJobId(selectedRowData[0].id);
                }
              }}
              components={{
                Footer: CustomFooter,
              }}
            />
          </Box>
        )}

        <Divider sx={{ marginBottom: "1rem" }} />

        <TextField
          id="job_name"
          label="Model Name"
          variant="outlined"
          onChange={handleChange}
          inputProps={{
            style: {
              padding: "18.5px 14px",
              border: "0",
            },
          }}
        />

        <FileUpload
          show={Object.keys(selectedRow).length !== 0}
          title="Select Treatment A file"
          options="storejobfile"
          jobId={jobId}
          type="t1"
          onSelect={handleFileSelect}
        />

        <Divider sx={{ marginBottom: "1rem" }} />

        <Button
          disabled={!selectedFiles["t1"] || !parentJobId}
          className={clsx(
            submitting && "waiting-border"
            //submitting && classes.submitting
          )}
          size="medium"
          variant="contained"
          color="primary"
          onClick={onLaunch}
        >
          Launch
        </Button>
        {selectedFiles["t1"] && (
          <StatusStepper
            steps={[
              { label: "Register" },
              { label: "Upload A", progress: selectedFiles["t1"].progress },
              { label: "Launch" },
            ]}
            step={launchStatus}
          />
        )}
      </Box>
    </Box>
  );
}
