import { Box, TextField } from "@mui/material";
import { useField } from "formik";

export default function FormTextField(props) {
  const { name } = props;
  const [field, meta] = useField(name);

  const configTextField = {
    variant: "outlined",
    ...props,
    ...field,
  };

  if (meta && meta.touched && meta.error) {
    configTextField.error = true;
    configTextField.helperText = meta.error;
  }

  return <TextField {...configTextField} />;
}
