import * as React from "react";

import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";

export function IconListElement(props) {
  const { icon, title, value, percent } = props;
  const Icon = icon;

  return (
    <>
      <ListItem disablePadding>
        <ListItemButton>
          <ListItemIcon>
            <Icon color="primary" />
          </ListItemIcon>
          <ListItemText
            primary={title}
            secondary={
              <Typography variant="h5" color="primary">
                {value}{percent && '%'}
              </Typography>

            }
          />
        </ListItemButton>
      </ListItem>
    </>
  );
}

export default function IconList(props) {
  const { children } = props;

  return (
    <Box
      sx={{
        display: "flex",
        gap: "1rem",
        flexDirection: "row",
        border: "thin lightgray solid",
        padding: "1rem",
        flexGrow: 1,
      }}
    >
      <List>
        {React.Children.map(children, (child, i) => {
          //console.log(child);
          return <>{child}</>;
        })}
      </List>
    </Box>
  );
}
