import {
  Button,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import FileUpload from "../../components/fileupload/fileupload";
import SendToApi from "../../api/sendtoapi";
import clsx from "clsx";
import { Box } from "@mui/system";

import RealStateContext from "../../wss/realState/realStateContext";
import { FileUploadToAPI } from "../../api/sendtoapi";
import StatusStepper from "../../components/stepper/statusStepper";
import { Navigate } from "react-router-dom";
import GlobalStateContext from "../../globalstate/globalStateContext";
const chunk_size = 1024 * 1024;

export default function NewJobManual() {
  // State
  const [selectedFiles, setSelectedFiles] = useState({});
  const [selectedFileLabels, setSelectedFileLabels] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [launchStatus, setLaunchStatus] = useState(0);
  const [launchComplete, setLaunchComplete] = useState(false);
  const [stateLocal, setStateLocal] = useState({
    t1: "",
    t2: "",
    c1: "",
    job_name: "",
    description: "",
    //response_variable: "",
  });

  // Context
  const rt = useContext(RealStateContext);
  const global = useContext(GlobalStateContext);

  const jobIdRef = useRef();

  const rowLabels = ["Outcome", "Feature", "Id", "Ignore"];

  function filestatus(status, type) {
    switch (status.data.state) {
      case "uploaded":
        switch (type) {
          case "t1":
            setLaunchStatus(2);
            break;
          case "t2":
            setLaunchStatus(3);
            // launch as soon as selectedFiles is updated in use effect
            break;
          default:
            break;
        }
        break;
      case "uploading":
        {
          const percent = status.data.percent_done;
          //        //console.log(`uploading ${percent} done`);
          var state = selectedFiles;
          state[type]["progress"] = percent;
          setSelectedFiles(state);
        }
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    //console.log(selectedFileLabels);

    var _labels = JSON.parse(JSON.stringify(selectedFileLabels)); // make deep copy so does not alter state ... and object assign had no effect

    for (const key in _labels["t1"]) {
      //console.log(`the ${key}: ${_labels["t1"][key]}`);
      if (_labels["t1"][key] !== "") {
        _labels["t1"][key] = rowLabels[_labels["t1"][key]];
      }
    }
  }, [selectedFileLabels]);

  async function onLaunch() {
    setSubmitting(true);
    console.log(selectedFiles);
    // get job id

    // get the job id

    const newjobId = await onRegister();

    jobIdRef.current = newjobId;

    const t1Response = await FileUploadToAPI(
      filestatus,
      selectedFiles["t1"].file,
      "t1",
      newjobId,
      "jobfile"
    );

    console.log("******************************** t1 response");
    console.log(t1Response);

    const t2Response = await FileUploadToAPI(
      filestatus,
      selectedFiles["t2"].file,
      "t2",
      jobIdRef.current,
      "jobfile"
    );
    //console.log("******************************** t2 response");
    //console.log(t2Response);

    var values = {};

    values.fileuploads = {};
    values.fileuploads.treatment_filename = t1Response.id;
    values.fileuploads.control_filename = t2Response.id;

    values.jobid = jobIdRef.current;

    //console.log(values);
    var response = await SendToApi("scripts/launch", values);
    if (response.status === 200) {
      setLaunchStatus(4);
      setLaunchComplete(true);
    } else {
    }

    setSubmitting(false);
    return false;
  }
  async function onLaunchTest() {
    setSubmitting(true);
    // get job id

    // get the job id

    const newjobId = await onRegister();

    jobIdRef.current = newjobId;

    const t1Response = await FileUploadToAPI(
      filestatus,
      selectedFiles["t1"].file,
      "t1",
      newjobId,
      "jobfile"
    );

    console.log("******************************** t1 response");
    console.log(t1Response);

    const t2Response = await FileUploadToAPI(
      filestatus,
      selectedFiles["t2"].file,
      "t2",
      jobIdRef.current,
      "jobfile"
    );
    //console.log("******************************** t2 response");
    //console.log(t2Response);

    var values = {};

    values.fileuploads = {};
    values.fileuploads.treatment_filename = t1Response.id;
    values.fileuploads.control_filename = t2Response.id;

    values.jobid = jobIdRef.current;

    //console.log(values);
    var response = await SendToApi("scripts/launch", values);
    if (response.status === 200) {
      setLaunchStatus(4);
      setLaunchComplete(true);
    } else {
    }

    setSubmitting(false);
    return false;
  }
  async function onRegister() {
    // TODO get a job id from the api
    //console.log("register");
    var values = {};
    values.job_name = stateLocal.job_name;
    values.description = stateLocal.description;
    var _labels = JSON.parse(JSON.stringify(selectedFileLabels)); // make deep copy so does not alter state ... and object assign had no effect
    //console.log(_labels);
    for (const key in _labels["t1"]) {
      //console.log(`the ${key}: ${_labels["t1"][key]}`);
      if (_labels["t1"][key] !== "") {
        _labels["t1"][key] = rowLabels[_labels["t1"][key]];
      }
    }
    values.data_info = JSON.stringify(_labels);
    values.type = "uplift";
    values.job_name = stateLocal.job_name;
    values.description = stateLocal.description;

    var response = await SendToApi("jobs/register", values);
    if (response.status === 200) {
      var initialState = { state: "launched" };
      rt.setRealTime({
        ...rt.realTime,
        [response.data]: initialState,
      });

      setLaunchStatus(launchStatus + 1);
      return response.data;
    }
    return false;
  }
  // Functions
  function handleChange(e) {
    //console.log(e.target.id);
    setStateLocal((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  }

  function handleSelect(selectedFile, type, size) {
    //console.log(`type ${type}`);
    //console.log(`size ${size}`);
    const number_of_chunks = Math.ceil(size / chunk_size);
    //console.log(`number of chunks ${number_of_chunks}`);
    setSelectedFiles((prev) => ({
      ...prev,
      [type]: {
        file: selectedFile,
        size: size,
        number_of_chunks: number_of_chunks,
        progress: 0,
      },
    }));
  }

  function handleLabels(type, labels) {
    //console.log("handleLabels");
    //console.log(type);
    //console.log(labels);
    setSelectedFileLabels((prev) => ({ ...prev, [type]: labels }));
  }

  // return
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        padding: "1rem",
        width: "90%",
      }}
    >
      {launchComplete && <Navigate replace to="/uplift/dashboard" />}
      <Typography color="primary" gutterBottom variant="h6">
        Launch new manual model
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        {!submitting && (
          <>
            <Divider sx={{ marginBottom: "1rem" }} />

            <FileUpload
              title="Select Treatment A file"
              show={true}
              jobId={jobIdRef.current}
              type="t1"
              onSelect={handleSelect}
              rowLabels={rowLabels}
              onLabels={handleLabels}
            />

            <Divider sx={{ marginBottom: "1rem" }} />

            <FileUpload
              title="Select Treatment B file"
              show={true}
              jobId={jobIdRef.current}
              type="t2"
              onSelect={handleSelect}
            />
          </>
        )}
        <Divider />

        <TextField
          value={stateLocal.job_name}
          id="job_name"
          label="Model Name"
          variant="outlined"
          onChange={handleChange}
          inputProps={{
            style: {
              padding: "18.5px 14px",
              border: "0",
            },
          }}
        />

        <TextField
          value={stateLocal.description}
          id="description"
          label="Description"
          variant="outlined"
          multiline
          onChange={handleChange}
          rows={4}
          inputProps={{
            style: {
              padding: "18.5px 14px",
              border: "0",
            },
          }}
        />

        <Button
          disabled={!selectedFiles["t1"] || !selectedFiles["t2"]}
          className={clsx(
            submitting && "waiting-border"
            //submitting && classes.submitting
          )}
          size="medium"
          variant="contained"
          color="primary"
          onClick={onLaunch}
        >
          Launch
        </Button>

        {global.userInfo.username === "pete@wilces.co.uk" && (
          <Button
            size="medium"
            variant="contained"
            color="primary"
            onClick={onLaunchTest}
          >
            Launch Test
          </Button>
        )}
        {selectedFiles["t1"] && selectedFiles["t2"] && (
          <StatusStepper
            steps={[
              { label: "Register" },
              { label: "Upload A", progress: selectedFiles["t1"].progress },
              { label: "Upload B", progress: selectedFiles["t2"].progress },
              { label: "Launch" },
            ]}
            step={launchStatus}
          />
        )}
      </Box>
    </Box>
  );
}
