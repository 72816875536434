import {
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";

import SendToApi from "./../../api/sendtoapi";
import { useContext, useState } from "react";
import { Box } from "@mui/system";

import layout from "./layout.json";
import CTForm from "../../components/forms/ctform";
import DaysPicker from "../../components/dayspicker/dayspicker";

import Switch from "@mui/material/Switch";
import { Navigate, useNavigate } from "react-router-dom";
import globalStateContext from "../../globalstate/globalStateContext";

export default function ActivateTribes() {
  const navigate = useNavigate();
  const [childValues, setChildValues] = useState({});
  const [formDisabled, setFormDisabled] = useState(true);
  const [checked, setChecked] = useState(false);
  const [cronChecked, setCronChecked] = useState(false);
  const [timings, setTimings] = useState("5");
  const [formValues, setFormValues] = useState({});
  const [launchStatus, setLaunchStatus] = useState(false);

  const global = useContext(globalStateContext);
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleCronChange = (event) => {
    setCronChecked(event.target.checked);
  };

  async function Register(_values) {
    var values = {};
    values.type = "tribesdemo";
    values.data_info = JSON.stringify(_values);
    console.log(values);
    var response = await SendToApi("jobs/register", values);
    console.log(response);
    if (response.status === 200) {
      console.log(response.data);
      return response.data;
    }

    return false;
  }

  async function handleSubmit(_values) {
    //console.log(_values);
    var values = {};
    values.data_info = JSON.stringify(_values);
    //console.log(values);
    var response = await SendToApi("tribes/activate", values);
    if (response.status === 200) {
      navigate("/tribes/schedule");
    } else {
    }
  }
  async function handleDemo(_values) {
    console.log(_values);
    // get a job id
    var values = {};
    var response;
    if (!cronChecked) {
      const jobId = await Register(_values);
      console.log(jobId);
      values = {};
      values.jobid = jobId;
      response = await SendToApi("scripts/launch", values);
      if (response.status === 200) {
        setLaunchStatus(true);
      } else {
      }
    } else {
      values = {};
      values["timings"] = timings;
      values.data_info = JSON.stringify(_values);
      response = await SendToApi("cron/addtribesdemo", values);
      if (response.status === 200) {
        setLaunchStatus(true);
      } else {
      }
    }
  }

  const handleRadioChange = (e) => {
    setTimings(e.target.value);
  };
  async function handleManualLaunch() {
    var values = {};
    values.type = "tribes";
    values.data_info = JSON.stringify(formValues);
    console.log(values);
    var response = await SendToApi("jobs/register", values);
    console.log(response);
    if (response.status === 200) {
      console.log(response.data);
      values = {};
      values.jobid = response.data;
      response = await SendToApi("scripts/launch", values);
      console.log(response);
      if (response.status === 200) {
        setLaunchStatus(true);
        console.log("happy tribes");
      }

      return response.data;
    }

    return false;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        padding: "1rem",
        width: "90%",
      }}
    >
      {launchStatus && <Navigate replace to="/tribes/dashboard" />}
      <Typography color="primary" gutterBottom variant="h6">
        Activate Tribes
      </Typography>
      <Divider sx={{ marginBottom: "1rem" }} />
      <Box
        sx={{
          display: "flex",
          gap: "4rem",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "4rem",
            flexDirection: "row",
          }}
        >
          <CTForm
            submitText="Activate"
            handleSubmit={(values) => {
              handleSubmit(values);
            }}
            layout={layout.options}
            disabled={formDisabled}
            childValues={childValues}
            handleFormValues={(values) => {
              setFormValues(values);
            }}
          >
            <FormControlLabel
              value="top"
              control={
                <Switch
                  color="primary"
                  checked={checked}
                  onChange={handleChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="Twice Weekly"
              labelPlacement="top"
            />

            <DaysPicker
              keyName="days"
              max={checked ? 2 : 1}
              handleChildChange={(key, values, selected) => {
                setChildValues({ ...childValues, [key]: values });
                if (selected === (checked ? 2 : 1)) {
                  setFormDisabled(false);
                } else {
                  setFormDisabled(true);
                }
              }}
            />
            {global.userIs("ninja") && (
              <>
                <Button onClick={handleManualLaunch} variant="outlined">
                  Manual
                </Button>
              </>
            )}
          </CTForm>
          {global.userIs("ninja") && (
            <CTForm
              submitText="Demo"
              handleSubmit={handleDemo}
              layout={layout.options}
            >
              <FormControlLabel
                value="top"
                control={
                  <Switch
                    color="primary"
                    checked={cronChecked}
                    onChange={handleCronChange}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label="Repeat"
                labelPlacement="top"
              />
              <FormControl>
                <FormLabel id="demo-controlled-radio-buttons-group">
                  Repeat
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={timings}
                  onChange={handleRadioChange}
                >
                  <FormControlLabel
                    value="1"
                    disabled={!cronChecked}
                    control={<Radio />}
                    label="Every min"
                  />
                  <FormControlLabel
                    value="5"
                    disabled={!cronChecked}
                    control={<Radio />}
                    label="Every 5 mins"
                  />
                  <FormControlLabel
                    value="30"
                    disabled={!cronChecked}
                    control={<Radio />}
                    label="Every 30 Mins"
                  />
                </RadioGroup>
              </FormControl>
            </CTForm>
          )}
        </Box>
      </Box>
    </Box>
  );
}
