import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import SettingsIcon from "@mui/icons-material/Settings";
import DoneIcon from "@mui/icons-material/Done";

import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { Alert, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect } from "react";

export default function SnitzyStepper(props) {
  const { job } = props;

  const steps = job.status.steps;
  const alive = job.alive;

  useEffect(() => {
    //console.log(alive);
  }, [alive]);

  function ColorlibStepIcon(props) {
    //console.log(props);
    const { active, completed, error } = props;
    const icons = {
      0: <DoneIcon />,
      1: <SettingsIcon />,
      2: <SettingsIcon />,
      3: <SettingsIcon />,
      4: <SettingsIcon />,
      5: <SettingsIcon />,
    };

    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active, error }}
        className={props.active && !props.error && "icon-spin"}
      >
        {!props.completed && icons[String(props.icon)]}
        {props.completed && icons[String(0)]}
      </ColorlibStepIconRoot>
    );
  }

  const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 42,
    height: 42,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",

    ...(ownerState.active && {
      backgroundColor: "rgb(131,72,172)",
    }),

    ...(ownerState.error && {
      backgroundColor:
        job.status.state === "elegant-fail"
          ? "rgb(255,127,0)"
          : "rgb(252,5,74)",
    }),

    ...(ownerState.completed && {
      backgroundImage:
        "linear-gradient( 136deg, rgb(93,248,160) 0%, rgb(80,201,120) 50%, rgb(12,49,3) 100%)",
      boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    }),
  }));

  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          "linear-gradient( 95deg,rgb(93,248,160) 0%,rgb(46,2,77) 100%)",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          "linear-gradient( 95deg,rgb(12,49,3) 0%,rgb(93,248,160) 100%)",
      },
    },

    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor:
        theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
      borderRadius: 1,
    },
  }));
  //  console.log(steps.length);
  return (
    <Stack sx={{ width: "100%" }} spacing={4}>
      <Stepper
        alternativeLabel
        activeStep={
          job.status.state === "completed"
            ? Number(steps.length)
            : Number(job.status.step)
        }
        connector={<ColorlibConnector />}
      >
        {steps.map((label, i) => (
          <Step key={label}>
            <StepLabel
              error={!alive}
              StepIconComponent={ColorlibStepIcon}
              StepIconProps={{ job }}
            >
              <Box>
                <Typography>{label}</Typography>
              </Box>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      {job.status.state === "elegant-fail" && (
        <Alert severity="warning">{job.status.message}</Alert>
      )}
    </Stack>
  );
}
// reference https://colordesigner.io/gradient-generator
