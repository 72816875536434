import Box from "@mui/material/Box";
import { useContext } from "react";
import GlobalStateContext from "../../globalstate/globalStateContext";

export default function CTDLogo() {
  const global = useContext(GlobalStateContext);

  return (
    <Box
      sx={{
        padding: "10px",
      }}
    >
      {global.userInfo.logoUrl && (
        <img
          src={global.userInfo.logoUrl}
          style={{ width: "200px", height: "100px", objectFit: "contain" }}
          alt="logo"
        />
      )}
    </Box>
  );
}
