import { useContext, useEffect, useRef, useState } from "react";
import SendToApi, { write_log } from "../../api/sendtoapi";
import GlobalStateContext from "../../globalstate/globalStateContext";
import RealStateContext from "./realStateContext";


//const wss = new WebSocket();

const RealStateProvider = (props) => {
  const [wss, setWss] = useState(false); // wss channel
  const [wssId, setWssId] = useState(false); // wss channel
  const [wssConnected, setWssConnected] = useState(false); // wss channel
  const [realTime, setRealTime] = useState({}); // store of all realtime jobs - saved in ref for wss message
  const global = useContext(GlobalStateContext);

  const stateRef = useRef();
  const notificationsRef = useRef();
  const numberOfNotificationsRef = useRef();

  const [notifications, setNotifications] = useState([]);
  const [numberOfNotifications, setNumberOfNotifications] = useState(0);
  const [liveTasks, setLiveTasks] = useState(0);

  useEffect(() => {
    console.log("******* liveTasks ******");
    console.log(liveTasks);
  }, [liveTasks]);

  useEffect(() => {
    console.log(global.userInfo.live_tasks);
    if (!global.userInfo.live_tasks) return;
    setLiveTasks(global.userInfo.live_tasks);
  }, [global.userInfo.live_tasks]);

  // set up the websocket connection
  useEffect(() => {
    if (global.userInfo.loggedIn) {
      setWss(new WebSocket(process.env.REACT_APP_WSS_URL));
    }

    (async () => {
      var values = {};
      const response = await SendToApi("notifications/get", values);
      if (response.status === 200) {
        if (response.data.length > 0) {
          response.data.forEach((event) => {
            const obj = JSON.parse(event.details);
            event.details = obj;
          });
          setNotifications(response.data);
          setNumberOfNotifications(response.data.length);
        }
      }
    })();
  }, [global.userInfo.loggedIn]);

  function checkNotifications() {
    var deleteMe = [];
    const newNotifications = notifications.filter((item) => {
      if (item.details.status !== "read") {
        return true;
      } else {
        deleteMe.push(item);
        return false;
      }
    });

    console.log(deleteMe);
    console.log(newNotifications);

    setNumberOfNotifications(newNotifications.length);

    deleteMe.forEach((item) => {
      console.log(item);
      (async () => {
        var values = {};
        values.id = item.id;
        values.details = item.details;
        const response = await SendToApi("notifications/edit", values);

        if (response.status === 200) {
        }
      })();
    });

    setNotifications(newNotifications);
  }
  function addNotification(details) {
    setNotifications([...notificationsRef.current, details]);
    setNumberOfNotifications(numberOfNotificationsRef.current + 1);
  }

  function updateNotifications(index, obj, value) {
    console.log(index);
    console.log(value);

    var _notifications = [...notifications];
    _notifications[index]["details"][obj] = value;
    setNotifications(_notifications);
    console.log(_notifications);
  }
  // store the realtime jobs in a ref for using in message from wss

  stateRef.current = realTime;
  notificationsRef.current = notifications;
  numberOfNotificationsRef.current = numberOfNotifications;
  // send messages

  function sendMessage(msg, id = false) {
    if (!id) {
      id = wssId;
    }

    var _msg = msg;
    _msg.source = "fe";
    _msg.id = id;
    _msg = JSON.stringify(_msg);
    wss.send(_msg);
  }

  function removeWssJobChannel(job) {
    var msg = {};
    msg.job_id = job.id;
    msg.job_token = job._token;
    msg.action = "remove";
    sendMessage(msg);
  }

  function addWssJobChannels(jobs) {
    // create a temp job array

    var _jobs = {};

    jobs.forEach((job, i) => {
      // sort wss channels
      var msg = {};
      msg.job_id = job.id;
      msg.job_token = job._token;
      msg.pid = job.pid;
      msg.action = "join";

      // create initial realtime array in prep of the response - this shows the job in the initial dashboard status
      //const status = JSON.parse(job.status);

      // check that the job has a token and was assigned a PID

      if (job._token && job.pid) {
        // send a message to join the msg to th wss
        sendMessage(msg);

        var _obj = {};
        _obj.job_id = job.id;
        _obj._token = job._token;
        _obj.status = job.status;
        _obj.pid = job.pid;
        _obj.type = job.type;
        _obj.job_name = job.job_name;
        _obj.update = JSON.parse(job.update);
        _obj.alive = true; // assume its true to start with
        _jobs[job.id] = _obj;
      } else {
      }
    });
    setRealTime(_jobs); /// this is one time add when screen is refreshed - jobs added in models are added to database and picked up
  }

  // report connection state
  useEffect(() => {
    if (!wss) {
      return;
    }

    wss.onopen = () => {
      global.setState((prevState) => ({
        ...prevState,
        wssConnected: true,
      }));
      setWssConnected(true);
    };
    wss.onclose = () => {
      global.setState((prevState) => ({
        ...prevState,
        wssConnected: false,
      }));
      setWssConnected(false);
    };

    return () => {
      wss.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wss]);

  // ****************
  // messages received
  // ****************
  useEffect(() => {
    if (!wss) return;

    // have to use REF in here
    wss.onmessage = (msg) => {
      try {
        var decodedMsg = JSON.parse(msg.data);
      } catch (e) {
        console.log("error with decoding message");
        console.log(msg.data);
        return;
      }
      console.log(decodedMsg.action);
      console.log(decodedMsg);
      var _obj = stateRef.current[decodedMsg.job_id];

      switch (decodedMsg.action) {
        case "hello":
          global.setState((prevState) => ({
            ...prevState,
            wssVersion: decodedMsg.version,
          }));
          setWssId(decodedMsg.id);
          var sendMsg = {};
          sendMsg.username = global.userInfo.username;
          sendMsg.accountId = global.userInfo.accountId;
          sendMsg.token = global.userInfo.token;
          sendMsg.action = "hello";
          sendMessage(sendMsg, decodedMsg.id);
          break;

        case "update":
          console.log(`update for ${decodedMsg.job_id}`);
          console.log(decodedMsg.status);
          write_log(decodedMsg.status);

          // only update what comes from wss - dont over write job info we set up to start !
          console.log(_obj);

          // we need to preserve the status object as thats where the steps are - they are not sent on fail or completion
          Object.keys(decodedMsg).forEach((key) => {
            if (typeof _obj[key] === "object") {
              _obj[key] = { ..._obj[key], ...decodedMsg[key] };
            } else {
              _obj[key] = decodedMsg[key];
            }
          });

          console.log(_obj);

          setRealTime((prevState) => ({
            ...prevState,
            [decodedMsg.job_id]: _obj,
          }));

          // notifications
          if (typeof decodedMsg.status.notification !== "undefined") {
            var notificationObj = {};
            notificationObj.id = numberOfNotifications + 1;
            notificationObj.details = {};
            notificationObj.details.title = "job status";
            notificationObj.details.state = "unread";
            notificationObj.details.message = decodedMsg.status.notification;
            write_log(`adding notification ${decodedMsg.status.notification}`);
            addNotification(notificationObj);
          }

          break;

        case "cantjoin":
          _obj.state = "refused";
          setRealTime((prevState) => ({
            ...prevState,
            [decodedMsg.job_id]: _obj,
          }));
          break;
        case "removed":
          // add true to the joinedChannel for this job
          console.log(`${decodedMsg.id} has been removed`);
          console.log(stateRef.current);
          //const _rt = RemoveObjectWithId(stateRef.current, decodedMsg.id);
          //console.log(_rt);
          //setRealTime(_rt);
          break;

        case "canjoin":
          // add true to the joinedChannel for this job
          console.log(`${decodedMsg.job_id} can join`);
          break;
        case "pidstopped":
          // add true to the joinedChannel for this job
          console.log(`${decodedMsg.job_id} pid has stopped`);
          //get the latest state from the api to avoid race conditions - job may have completed but WSS has reported pid stopped before completed message
          (async () => {
            var values = {};
            values["job_id"] = decodedMsg.job_id;
            values["token"] = _obj._token;
            console.log(decodedMsg);
            const response = await SendToApi(
              "jobs/hasjobfinished",
              values,
              false
            ); // send job token not user
            if (response.status === 200) {
              console.log(response);
            }
          })();

          console.log(`${_obj.status.state}`);
          if (
            _obj.status.state !== "elegant-fail" &&
            _obj.status.state !== "completed"
          ) {
            _obj.status.state = "pidstopped";
            _obj.alive = false;
          }

          console.log(_obj);
          setRealTime((prevState) => ({ ...prevState, [decodedMsg.id]: _obj }));
          break;

        case "pidheartbeat":
          if (decodedMsg.pidDetails.length === 0) {
            //console.log(`${decodedMsg.id} is dead !!!!`);

            _obj.alive = false;
            setRealTime((prevState) => ({
              ...prevState,
              [decodedMsg.id]: _obj,
            }));
          } else {
            //console.log(`${decodedMsg.id} is alive !!!!`);
            _obj.alive = decodedMsg.pidDetails;
            setRealTime((prevState) => ({
              ...prevState,
              [decodedMsg.id]: _obj,
            }));
          }
          break;
        case "accountupdate":
          console.log(decodedMsg.update.live_tasks);
          setLiveTasks(JSON.parse(decodedMsg.update.live_tasks));
          break;
        default:
          console.log(decodedMsg.action);
          break;
      }
    };
  }, [wss]);

  return (
    <RealStateContext.Provider
      value={{
        realTime: realTime,
        setRealTime: setRealTime,
        addWssJobChannels: addWssJobChannels,
        removeWssJobChannel: removeWssJobChannel,
        wssConnected: wssConnected,
        notifications: notifications,
        numberOfNotifications: numberOfNotifications,
        updateNotifications: updateNotifications,
        checkNotifications: checkNotifications,
        addNotification: addNotification,
        liveTasks: liveTasks,
      }}
    >
      {props.children}
    </RealStateContext.Provider>
  );
};

export default RealStateProvider;
