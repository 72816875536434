import SendToApi from "../../api/sendtoapi";

export async function FetchAllValues(table) {
  const split = table.split(":");
  if (split.length !== 3) return;

  var values = {};
  values["database"] = split[0];
  values["table"] = split[1];
  values["field"] = split[2];

  console.log(values);
  const response = await SendToApi("tables/getselectoptions", values);

  console.log(response);
  if (response.status !== 200) {
    return false;
  }

  return response.data;
}
