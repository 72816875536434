import { useCallback, useEffect, useState, useRef } from "react";
import { DataGrid } from "@mui/x-data-grid";
import SendToApi from "../../api/sendtoapi";
import { Delete, Cancel } from "@mui/icons-material/";

import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";

import { useConfirmation } from "../../providers/ConfirmationService";

import {
  Box,
  Chip,
  FormControlLabel,
  IconButton,
  Switch,
  Typography,
} from "@mui/material";
import { RemoveObjectWithId } from "../../utils/utils";


export default function ApiDataGrid(props) {
  const [rows, setRows] = useState();
  const [selectedRow, setSelectedRow] = useState(false);
  const [updated, setUpdated] = useState(false);
  const rowsRef = useRef(); // used to store the heartbeat
  const [editMode, setEditMode] = useState(false);

  async function DeleteRow() {
    var values = {};
    values["deleteid"] = selectedRow.id;
    const response = await SendToApi(props.deleteURL, values);
    console.log(`ACTION: ${response}`);
    var _rows = rows;
    _rows = RemoveObjectWithId(_rows, selectedRow.id);
    setRows(_rows);
    setSelectedRow(false);
  }

  rowsRef.current = rows;

  useEffect(() => {
    let cancel = false;

    const fetchData = async () => {
      var fields = "";
      var values = props.initialValues;
      props.columns.forEach((column) => {
        fields += column.table + "." + column.field + ",";
        if (column.on) {
          values["on"] = column.on;
        }
        values["join_table"] = column.table;
      });
      fields = fields.slice(0, -1);
      values["fields"] = fields;

      const response = await SendToApi(props.fetchURL, values, true);
      if (cancel) return;
      if (response.status === 200) {
        // add to realtime and status
        setRows(response.data.rows);
      }
    };
    fetchData();
    return () => {
      cancel = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function CustomToolbar() {
    const confirm = useConfirmation();

    const tryToDelete = () => {
      confirm({
        variant: "danger",
        catchOnCancel: true,
        title: `Are you sure you want to remove ${props.entity} ${selectedRow.id}?`,
        description: `This will permanently remove all database entries for them`,
      })
        .then(DeleteRow)
        .catch(() => {}); // cancel
    };

    return (
      <Box
        sx={{
          display: "flex",
          direction: "row",
          gap: "1rem",
          justifyContent: "flex-end",
        }}
      >
        {selectedRow && (
          <>
            <FormControlLabel
              control={
                <Switch
                  checked={editMode}
                  onChange={() => setEditMode(!editMode)}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="Edit Mode"
            />
            <IconButton
              aria-label="delete"
              color="primary"
              onClick={tryToDelete}
            >
              <Delete />
            </IconButton>
          </>
        )}
      </Box>
    );
  }
  function CustomFooter() {
    if (!selectedRow) return;
    const key = Object.keys(selectedRow)[0];
    const label = selectedRow[key];
    return (
      <Box
        sx={{
          padding: "1rem",
          display: "flex",
          gap: "1rem",
          alignItems: "center",
        }}
      >
        {selectedRow.id && (
          <>
            <Chip color="primary" label={label} icon={<ManageAccountsIcon />} />
            {updated && <SystemUpdateAltIcon color="success" />}
            <IconButton onClick={() => setSelectedRow(false)}>
              <Cancel color="primary" />
            </IconButton>
          </>
        )}
      </Box>
    );
  }

  // remove the updated icon
  useEffect(() => {
    if (!updated) return;

    const timer = setTimeout(() => {
      setUpdated(false);
    }, 3000);
    return () => {
      clearTimeout(timer);
    };
  }, [updated]);

  const handleCellEditCommit = useCallback(async (params) => {
    var _rows = rowsRef.current;

    const searchColumn = props.columns.find(
      (column) => column.field === params.field
    );

    const searchRow = _rows.find((row) => row.id === params.id);
    // update the row
    searchRow[params.field] = params.value;
    setRows(_rows);

    if (params.id !== "0") {
      var values = {};
      var data = {};
      data[params.field] = params.value;
      if (searchColumn.join) {
        data["id"] = searchRow.join_id; //join_id;
      } else {
        data["id"] = params.id; //id;
      }

      values["rowid"] = params.id;
      values["data"] = data;
      values["table"] = searchColumn.table;

      const response = await SendToApi(props.saveURL, values);

      if (response.status === 200) {
        setUpdated(true);
      }
    } else {
      console.log("its a save job not edit use saveURL");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {rows ? (
        <DataGrid
          autoHeight
          rows={rows}
          columns={props.columns}
          // checkboxSelection
          disableMultipleSelection={true}
          components={{
            Toolbar: CustomToolbar,
            Footer: CustomFooter,
          }}
          isCellEditable={(params) => (editMode ? true : false)}
          onCellEditCommit={handleCellEditCommit}
          onSelectionModelChange={(ids) => {
            const selectedIDs = new Set(ids);
            const selectedRowData = rows.filter((row) =>
              selectedIDs.has(row.id.toString())
            );
            setUpdated(false);
            setSelectedRow(selectedRowData[0]);
          }}
        />
      ) : (
        <Typography>Sorting data ...</Typography> // todo make this so that the three dots expand and collapse
      )}
    </>
  );
}
/*
 */
